import {Slider, styled} from "@mui/material";

const StyledSlider = styled(Slider, {
    shouldForwardProp: (prop) => prop !== "tone" && prop !== 'rail' && prop !== 'shadow',
})<{ tone?: string, rail?: string, shadow?: string }>(({rail, tone, shadow}) => ({
    position: 'static',
    pointerEvents: 'auto',
    color: tone, //theme.palette.highlight.main,
    '& .MuiSlider-rail': {
        color: rail, //theme.components.Node.color,
        opacity: 1
    },
    '& .MuiSlider-thumb': {
        height: 18,
        width: 18,
        '&:hover': {
            boxShadow: '0 0 0 8px ' + shadow //theme.palette.highlight.ghost
        },
        '&:focus, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: '0 0 0 12px ' + shadow //theme.palette.highlight.ghost
        },
    }
}));

export default StyledSlider;