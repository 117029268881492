import {createStyle, defaultStyle, defaultStyleDark, mergeStyles} from "./style";
import React, {ReactNode} from "react";
import FancyStyleProvider from "./style/FancyStyleProvider";
import FancyDialogs from "./workspace/dialogs/FancyDialogs";

export interface FancyIDEProps {
    darkMode?: boolean,
    children?: ReactNode
}

export default function FancyIDE(props: FancyIDEProps) {
    const theme = props.darkMode ? defaultStyleDark : defaultStyle;

    return (
        <FancyStyleProvider style={theme}>
            <FancyDialogs/>
            {props.children}
        </FancyStyleProvider>
    )
}