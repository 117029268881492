import {InputActions, InputState, KeyActionType, KeyDownAction, KeyUpAction} from "./types";

const initialInputState: InputState = {
    keyPressed: {}
};

export const input = (
    state: InputState = initialInputState,
    action: InputActions
): InputState => {
    switch (action.type) {
        case KeyActionType.KEYDOWN:
            const kdap = (action as KeyDownAction).payload
            return { ...state, keyPressed: {...state.keyPressed, [kdap]: true}};
        case KeyActionType.KEYUP:
            const kuap = (action as KeyUpAction).payload
            const keyPressedState = {...state.keyPressed};
            delete keyPressedState[kuap];
            return { ...state, keyPressed: keyPressedState};
        default:
            return state;
    }
};