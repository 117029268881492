import {Action, PayloadAction} from "@reduxjs/toolkit";
import {ReactNode} from "react";

export interface InputState {
    keyPressed: {[key: string]: boolean}
}

export enum KeyActionType {
    KEYDOWN = "KEYDOWN",
    KEYUP = "KEYUP"
}

export type KeyDownAction = PayloadAction<string, KeyActionType.KEYDOWN>
export type KeyUpAction = PayloadAction<string, KeyActionType.KEYUP>
export type InputActions = KeyDownAction | KeyUpAction;