import {ExecutableNode} from "../../store/nodes";
import React from "react";
import {useTypedSelector} from "../../store";
import {ExecutableNodeController} from "./FancyExecutableNode";
import {getNodeTypeFromPath} from "../../common/machine/MachineHandler";
import {_scale} from "./index";

const FancyExecutableNodeController = ({node}: {
    node: ExecutableNode
}) => {
    let nodeType = getNodeTypeFromPath(node.type);
    if (!nodeType) return null;

    return <div
        style={{
            position: 'absolute',
            left: node.shape.x * _scale + 8 * _scale,
            top: (node.shape.y + node.shape.height / 2) * _scale,
            width: node.shape.width * _scale,
            height: 0,
            pointerEvents: 'none'
        }}
    >
        <div style={{
            transform: 'translateY(-50%)',
            width: 'calc(100% - ' + 16 * _scale + 'px)',
            height: 'auto'
        }}>
            <ExecutableNodeController type={nodeType} id={node.id}/>
        </div>
    </div>
}

const noShapeUpdate = (prevNodeProps: { node: ExecutableNode }, nextNodeProps: { node: ExecutableNode }) =>
    prevNodeProps.node.shape.x === nextNodeProps.node.shape.x &&
    prevNodeProps.node.shape.y === nextNodeProps.node.shape.y &&
    prevNodeProps.node.shape.width === nextNodeProps.node.shape.width &&
    prevNodeProps.node.shape.height === nextNodeProps.node.shape.height

const MemoFancyExecutableNodeController = React.memo(FancyExecutableNodeController, noShapeUpdate);

const ConnectedExecutableNodeController = ({id, forceUpdate}: { id: string, forceUpdate?: boolean }) => {
    let node = useTypedSelector(state => state.nodes.nodes[id]) as ExecutableNode;

    return forceUpdate ? <FancyExecutableNodeController node={node}/> : <MemoFancyExecutableNodeController node={node}/>
}

export default ConnectedExecutableNodeController;