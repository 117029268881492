import React, {DetailedHTMLProps, HTMLAttributes, ReactNode, useContext, useRef, useState} from "react";
import {DraggableCore, DraggableEventHandler} from "react-draggable";
import {useStopScroll} from "../../hooks/window";
import {constrain} from "../../common/math";
import store, {useTypedSelector} from "../../store";
import {setTranslate, setZoomTranslate} from "../../store/workspace";

export type ZoomTranslateState = { x: number, y: number, zoom: number }

export const ZoomTranslateContext = React.createContext<ZoomTranslateState>({
    x: 0,
    y: 0,
    zoom: 1
});

const ZoomTranslate = ({children, onZoomChange, ...props}: {
    children: ReactNode,
    minZoom?: number,
    maxZoom?: number,
    defaultZoom?: number,
    onZoomChange?: (zoom: number) => any,
    disable?: boolean,
    paneProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    //staticChildren?: ReactNode
}) => {
    //const [pos, setPos] = useState<ZoomTranslateState>({x: 0, y: 0, zoom: props.defaultZoom ?? 0.75});
    const dragRef = useRef<HTMLDivElement>(null)
    const pos = useZoomTranslate()

    const minZoom = props.minZoom ?? 0.25;
    const maxZoom = props.maxZoom ?? 2;

    const scrollRef = useStopScroll();

    const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
        const delta = e.deltaY * -0.001;
        const newScale = constrain(pos.zoom * (1 + delta), minZoom, maxZoom);

        const ratio = 1 - newScale / pos.zoom;

        store.dispatch(setZoomTranslate(
            pos.x + (e.clientX - pos.x) * ratio,
            pos.y + (e.clientY - pos.y) * ratio,
            newScale
        ));

        onZoomChange && onZoomChange(newScale);

        //e.preventDefault();
    };

    const handleDrag: DraggableEventHandler = (e, data) => {
        if (props.disable) return;
        store.dispatch(setTranslate(pos.x + data.deltaX, pos.y + data.deltaY));
        //setPos(pos => ({...pos, x: pos.x + data.deltaX, y: pos.y + data.deltaY}));
    }

    const handlePreventContextMenu = (e: React.MouseEvent<HTMLDivElement>) => e.preventDefault()

    return <div
        {...props.paneProps}
        ref={scrollRef}
        style={{
            ...props.paneProps?.style,
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden'
        }}
        onWheelCapture={handleWheel}
        onContextMenu={handlePreventContextMenu}
    >
        <DraggableCore nodeRef={dragRef} onDrag={handleDrag}>
            <div style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                zIndex: 0
            }}/>
        </DraggableCore>
        <div
            style={{
                position: 'absolute',
                left: 0,
                top: 0,
                transformOrigin: "0 0",
                transform: `translate(${pos.x}px, ${pos.y}px) scale(${pos.zoom}`,
                zIndex: 100
            }}>
            {children}
            {/*<ZoomTranslateContext.Provider value={pos}>
                {children}
            </ZoomTranslateContext.Provider>*/}
        </div>
        {/*<ZoomTranslateContext.Provider value={pos}>
            {staticChildren}
        </ZoomTranslateContext.Provider>*/}
    </div>
}

export default ZoomTranslate;

export const useZoomTranslate: () => ZoomTranslateState = () => {
    return useTypedSelector(state => state.transform); //useContext<ZoomTranslateState>(ZoomTranslateContext);
}

export const useZoom: () => number = () => {
    return useTypedSelector(state => state.transform.zoom);//useContext<ZoomTranslateState>(ZoomTranslateContext).zoom;
}