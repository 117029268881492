import {InputBaseProps} from "@mui/material/InputBase/InputBase";
import {InputBase} from "@mui/material";
import React from "react";

const StyledInput = ({background, textColor, ...props}: { background: string, textColor: string } & InputBaseProps) => <div
    style={{
        width: '100%',
        height: 28,
        pointerEvents: 'auto'
    }}
>
    <div style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: -6.5,
        right: -6.5,
        //borderRadius: 5,
        background
    }}/>
    <div style={{position: 'absolute', top: -3, left: -4, width: '100%'}}>
        <InputBase
            {...props}
            inputProps={{
                onMouseDown: (e) => e.stopPropagation()
            }}
            fullWidth
            style={{
                fontFamily: 'Roboto Mono',
                fontSize: 18,
                fontWeight: 'bold',
                lineHeight: '16px',
                color: textColor,
                //background: theme.components.Input.background
            }}/>
    </div>
</div>

export default StyledInput;