import store, {useTypedSelector} from "../store";
import React, {useEffect, useState} from "react";
import {
    addController,
    addNode,
    clearNodeSearchConnections,
    connect,
    ExecutableNode,
    isExecutableNode,
    moveConnectionLineToNodeSearch,
    Node,
    setMachineState,
    StateNode,
    updateState,
    Warnings
} from "../store/nodes";
import {defaultNodeStyle, defaultNodeStyleDark, FancyNodeStyleSheet} from "./style";
import FancyNodeStyleProvider from "./style/FancyNodeStyleProvider";
import ZoomTranslate from "./workspace/ZoomTranslate";
import {getControllerSettings} from "./labels";
import {Data, DefinitionState, LinkData, NodeData} from "../common/machine/Machine";
import RectangleSelector from "./workspace/RectangleSelector";
import FancyIDE from "./FancyIDE";
import {AnimatePresence} from "framer-motion";
import {ConnectedSketchpad} from "./sketch/FancySketchpad";
import {ExecutableNodeType, nodeInstanceDimensions} from "../common/types/nodeTypes";
import NodeSearch from "./workspace/search/NodeSearch";
import {v4 as uuidv4} from 'uuid';
import {getZoomTranslate} from "../store/workspace";
import {Type, Types} from "../common/types/types";
import MachineHandler from "../common/machine/MachineHandler";
import FullscreenSvg from "./workspace/FullscreenSvg";
import FancyEdges from "./nodes/FancyEdges";
import FancyNodes from "./nodes/FancyNodes";
import FancyLabels from "./nodes/FancyLabels";
import FancyControllers from "./nodes/FancyControllers";
import FancyNodeHighlights from "./nodes/FancyNodeHighlights";
import ConnectedConnectionLine from "./nodes/ConnectedConnectionLine";
import FancyNodeShadows from "./nodes/FancyNodeShadows";
import {_scale} from "./nodes";
import FancyNodeInfoLabels from "./info/FancyNodeInfoLabels";
import {WorkspaceMenu} from "./workspace/WorkspaceMenu";
import {WsMachineConnector} from "../common/machine/ws/WsMachineConnector";
import ConnectMe from "./workspace/ConnectMe";

const getMachineURLParamter = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.has('machine') ? urlParams.get('machine') : undefined;
}

export const FancyNodesVPE = ({defaultDarkMode, test}: { defaultDarkMode?: boolean, test?: '1' | '2' | 'performance' }) => {
    const [darkMode, setDarkMode] = useState<boolean>(!!defaultDarkMode);
    const handleToggleDarkMode = () => setDarkMode(d => !d);

    const [url, setURL] = useState<string | null>(getMachineURLParamter() || null);
    const [showConnectMe, setShowConnectMe] = useState<boolean>(!getMachineURLParamter());

    useEffect(() => {
/*
        if (test === 'performance') {
            let cx = 20, cy = 20;
            let scx = 40, scy = 12;

            for (let i = 0; i < cx; i++)
                for (let j = 0; j < cy; j++)
                    store.dispatch(
                        addNode({
                            id: "asd" + i + "." + j,
                            shape: {
                                x: 20 + i * (36 + scx),
                                y: 20 + j * (36 + scy),
                                width: 36,
                                height: 36,
                                resizable: true
                            },
                            input: [{}, {}],
                            output: [{}]
                        })
                    );

            /!*for (let i = 0; i < cx - 5; i++)
                for (let j = 0; j < cy; j++)
                    for (let ii = 1; ii <= 8; ii++)
                        for (let jj = -5; jj <= 5; jj++)
                            if (i + ii < cx && j + jj < cy && j + jj > 0 && Math.random() > .95) store.dispatch(
                                connect({
                                    id: "asd" + i + "." + j,
                                    index: 0
                                }, {
                                    id: "asd" + (i + ii) + "." + (j + jj),
                                    index: 0
                                })
                            );*!/

        } else if (test === '1') {
            store.dispatch(
                addNode({
                    id: "a",
                    shape: {
                        x: 200,
                        y: 150,
                        width: 48,
                        height: 12 * 4,
                        resizable: true,
                        minWidth: 48,
                        minHeight: 12 * 4
                    },
                    children: [],
                    input: [{}, {}, {}],
                    output: [
                        {data: {value: "1", type: "number"}},
                        {data: {value: "2", type: "number"}},
                        {data: {value: "3", type: "number"}}
                    ]
                })
            );

            store.dispatch(
                addNode({
                    id: "b",
                    type: {name: "division"}, //nodeTypes.executables.division, //<DivideIcon color={'#000000'}/>,
                    shape: {
                        x: 450,
                        y: 100,
                        width: 36,
                        height: 36
                    },
                    input: [{}, {}],
                    output: [{}]
                })
            );

            store.dispatch(
                addNode({
                    id: "c",
                    type: {name: "multiplication"}, //nodeTypes.executables.multiplication, //<MultiplyIcon color={'#000000'}/>,
                    shape: {
                        x: 350,
                        y: 220,
                        width: 36,
                        height: 36
                    },
                    input: [{}, {}],
                    output: [{}]
                })
            );

            store.dispatch(
                addNode({
                    id: "d",
                    type: {name: "addition"}, //nodeTypes.executables.addition, //<PlusIcon color={'#000000'}/>,
                    shape: {
                        x: 200,
                        y: 250,
                        width: 36,
                        height: 36
                    },
                    input: [{}, {}],
                    output: [{}]
                })
            );

            store.dispatch(
                addNode({
                    id: "e",
                    shape: {
                        x: 200,
                        y: 300,
                        width: 36,
                        height: 36
                    },
                    input: [{}, {}],
                    output: [{}]
                })
            );

            /!*store.dispatch(
                addNode({
                    id: "g",
                    shape: {
                        x: 400,
                        y: 200,
                        radius: 10
                    }
                })
            );*!/

            store.dispatch(
                connect({
                    id: "a",
                    index: 0
                }, {
                    id: "b",
                    index: 0
                })
            );

            store.dispatch(
                connect({
                    id: "a",
                    index: 2
                }, {
                    id: "c",
                    index: 1
                })
            );

            store.dispatch(
                connect({
                    id: "c",
                    index: 0
                }, {
                    id: "b",
                    index: 1
                })
            );

        } else if (test === '2') {
            store.dispatch(
                addNode({
                    id: "a",
                    //label: {icon: "multiply"},
                    type: {name: "addition"}, //nodeTypes.executables.addition,
                    shape: {
                        x: 200,
                        y: 200,
                        width: 36,
                        height: 36
                    },
                    input: [{}, {}],
                    output: [{}]
                })
            );
        }
*/

        if (url === null) {
            return;
        }

        let initNodes: { [id: string]: NodeData } = {};
        let nodeState = store.getState().nodes.nodes;
        Object.keys(nodeState).forEach(key => {
            let node = nodeState[key];
            if (isExecutableNode(node)) {
                let exn = node as ExecutableNode;
                initNodes[key] = {
                    input: exn.input ? exn.input.map(() => null) : [],
                    output: exn.output ? exn.output.map(o => o.data || null) : [],
                    type: exn.type
                }
            } else {
                let stn = node as StateNode;
                initNodes[key] = null;
            }
        });

        let initLinks: { [id: string]: LinkData[] } = {};
        let edgeState = store.getState().nodes.edges;
        Object.keys(edgeState).forEach(key => {
            let edge = edgeState[key];
            if (!initLinks[edge.from.id]) initLinks[edge.from.id] = [];
            initLinks[edge.from.id].push({from: edge.from.index, to: edge.to.index ? [edge.to.id, edge.to.index] : edge.to.id});
        });

        MachineHandler.current = new MachineHandler(
            new WsMachineConnector(
                url,
                (state: DefinitionState<{data?: Data, warnings?: Warnings}, {info?: any}>) => {
                    store.dispatch(updateState(state));
                    console.log("UPDATE"); console.log(state);
                    return true;
                }
            )
        )

        MachineHandler.current.onInitialized = () => {
            store.dispatch(setMachineState({initialized: true}));
        }

        MachineHandler.current.init(initNodes, initLinks);
    }, [url])

    const getTheme = () => darkMode ? defaultNodeStyleDark : defaultNodeStyle;

    const [theme, setTheme] = useState<FancyNodeStyleSheet>(getTheme());

    type TyperPorts = {
        input?: (Type | Types)[]
        output?: (Type | Types)[]
    }
    const [typer, setTyper] = useState<{position: number[]} & TyperPorts | null>(null);
    const handleTyperClose = () => {
        setTyper(null);
        store.dispatch(clearNodeSearchConnections());
    }

    const handleInstantiateNode = (type: ExecutableNodeType, key: string, pckg: string) => {
        setTyper(t => {
            if (t && t.position) {
                let zoomTranslate = getZoomTranslate(store.getState().transform);
                let {width, height} = nodeInstanceDimensions(type);
                let tsc = [
                    (t.position[0] - zoomTranslate.x) / zoomTranslate.zoom / _scale - width / 2,
                    (t.position[1] - zoomTranslate.y) / zoomTranslate.zoom / _scale - height / 2
                ];

                const controllerSettingsType = typeof type.label !== "string"
                    && type.label
                    && "controller" in type.label
                    && getControllerSettings(type.label?.controller) || undefined;

                let id = uuidv4();

                store.dispatch(
                    addNode({
                        id,
                        type: {name: key, package: pckg},
                        shape: {
                            ...type.instanceDimensions,
                            x: tsc[0],
                            y: tsc[1],
                            width,
                            height
                        },
                        input: type.input.map(() => ({})),
                        output: type.output.map(() => ({})),
                        state: 'initializing'
                    })
                );

                if (controllerSettingsType) store.dispatch(
                    addController({
                        id,
                        state: controllerSettingsType.defaultState
                    })
                );
            }
            store.dispatch(clearNodeSearchConnections());
            return null;
        });
    }

    useEffect(() => {
        setTheme(getTheme());
    }, [darkMode])

    const handleMouseUp = (e: React.MouseEvent) => {
        if (store.getState().nodes.mode === 'connect') {
            let connection: TyperPorts = {}
            if (store.getState().nodes.connectionLine?.direction === 'forward') {
                connection.input = [[]]
            } else {
                connection.output = [[]]
            }
            setTyper({position: [e.clientX, e.clientY], ...connection});
            //store.dispatch(setConnectionLine(undefined))
            store.dispatch(moveConnectionLineToNodeSearch());
        }
    }

    const handleDoubleClick = (e: React.MouseEvent) => {
        console.log(e);
        setTyper({position: [e.clientX, e.clientY]});
        console.log([e.clientX, e.clientY]);
    }

    const mode = useTypedSelector(state => state.nodes.mode)
    const noParentFilter = (n: Node) => n.parent === undefined;

    return <FancyNodeStyleProvider style={theme}>
        <ZoomTranslate
            paneProps={{
                onMouseUp: handleMouseUp,
                onDoubleClick: handleDoubleClick,
                style: {
                    background: theme.background,
                    cursor: mode === 'connect' ? 'crosshair' : undefined
                }
            }}
        >
            <FancyNodeShadows filter={noParentFilter}/>
            <FullscreenSvg>
                <FancyEdges skipHighlighted filter={e => !e.level}/>
            </FullscreenSvg>
            <FancyNodeHighlights filter={noParentFilter}/>
            <ConnectedConnectionLine/>
            <FancyNodes filter={noParentFilter}/>
            <FancyLabels filter={noParentFilter}/>
            <FancyControllers filter={noParentFilter}/>
            <FullscreenSvg>
                <FancyEdges skipHighlighted filter={e => !!e.level}/>
            </FullscreenSvg>
            <FullscreenSvg>
                <FancyEdges skipUnhighlighted/>
            </FullscreenSvg>
            <FancyNodeInfoLabels category={"timesExecuted"}/>
        </ZoomTranslate>
        <ConnectedSketchpad/>
        <RectangleSelector/>
        <FancyIDE darkMode={darkMode}>
            <AnimatePresence>{typer && <NodeSearch
                position={typer.position}
                filter={typer.input || typer.output ? {input: typer.input, output: typer.output} : undefined}
                onClose={handleTyperClose}
                onSelect={handleInstantiateNode}
            />}</AnimatePresence>
            {showConnectMe && <ConnectMe onConnected={setURL}/>}
            <WorkspaceMenu handleToggleDarkMode={handleToggleDarkMode} darkMode={darkMode}/>
        </FancyIDE>
    </FancyNodeStyleProvider>
}

