import React, {DetailedHTMLProps, HTMLAttributes, useRef, useState} from "react";
import {DraggableCore, DraggableEventHandler} from "react-draggable";
import {useStopScroll} from "../../hooks/window";
import {constrain} from "../../common/math";
import {useKeyPressed} from "../../store";
import {useStyle} from "../../hooks";

export type RectangleSelectorState = { x1: number, y1: number, x2: number, y2: number }

const RectangleSelector = (props: {
    paneProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    activatorKey?: string
}) => {
    const keyPressed = useKeyPressed(props.activatorKey || "Shift");
    const style = useStyle();

    const [rect, setRect] = useState<RectangleSelectorState | null>(null);
    const dragRef = useRef<HTMLDivElement>(null)

    const scrollRef = useStopScroll();

    const handleDrag: DraggableEventHandler = (e, data) => {
        setRect(rect => ({...(rect ?? {x1: data.x, y1: data.y}), x2: data.x, y2: data.y}));
    }

    const handleStart: DraggableEventHandler = (e, data) => {
        setRect({x1: data.x, x2: data.x, y1: data.y, y2: data.y});
    }

    const handleStop: DraggableEventHandler = (e, data) => {
        setRect(null);
    }

    const handlePreventContextMenu = (e: React.MouseEvent<HTMLDivElement>) => e.preventDefault();
    const handlePreventWheel = (e: React.WheelEvent<HTMLDivElement>) => e.preventDefault();

    return keyPressed ? <div
        {...props.paneProps}
        ref={scrollRef}
        style={{
            ...props.paneProps?.style,
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden'
        }}
        onWheelCapture={handlePreventWheel}
        onContextMenu={handlePreventContextMenu}
    >
        <DraggableCore nodeRef={dragRef} onStart={handleStart} onDrag={handleDrag} onStop={handleStop}>
            <div style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%'
            }}/>
        </DraggableCore>
        {rect && <div style={{
            position: 'absolute',
            left: Math.min(rect.x1, rect.x2),
            top: Math.min(rect.y1, rect.y2),
            width: Math.max(rect.x1, rect.x2) - Math.min(rect.x1, rect.x2) - 2,
            height: Math.max(rect.y1, rect.y2) - Math.min(rect.y1, rect.y2) - 2,
            border: '2px solid ' + style.palette.highlight.ghost
        }}/>}
    </div> : null;
}

export default RectangleSelector;