import {isExecutableNode, Node} from "../../store/nodes";
import {useTypedSelector} from "../../store";
import FancyExecutableNode, {SnappingExecutableNode} from "./FancyExecutableNode";
import FancyStateNode from "./FancyStateNode";
import React from "react";

const FancyNodes = ({filter, forceUpdate}: { filter?: (n: Node) => boolean, forceUpdate?: boolean }) => {
    let nodes = useTypedSelector(state => {
        if (!filter) return state.nodes.nodes;
        else return Object.fromEntries<Node>(
            Object.entries(state.nodes.nodes).filter(
                ([, n]) => filter(n)
            )
        )
    });
    let hovered = useTypedSelector(state => state.nodes.hovered);

    return <>
        {Object.keys(nodes).map(key => {
            let node = nodes[key];
            if (node.id === hovered) return null;
            if ('width' in node.shape) {
                return <FancyExecutableNode id={key} key={key} forceUpdate={forceUpdate}/>
            } else {
                return <FancyStateNode id={key} key={key}/>;
            }
        })}
        {hovered && nodes[hovered] && (isExecutableNode(nodes[hovered]) ?
                <SnappingExecutableNode shadow id={hovered} key={hovered}/>
                :
                <FancyStateNode shadow id={hovered} key={hovered}/>
        )}
    </>
}

export default FancyNodes;