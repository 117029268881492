import {Slider, SliderProps, styled} from "@mui/material";
import {useStyle} from "../../hooks";
import {PortPosition, Unique, updateController} from "../../store/nodes";
import React, {ReactNode, useState} from "react";
import {PortController, PortControllerState, useControllerValue} from "./index";
import store, {useTypedSelector} from "../../store";
import StyledSlider from "./input/StyledSlider";

export type OutputNumberController = PortController<"out", number>

export interface FancySliderProps {
    min?: number
    max?: number
    value?: number
    step?: number
}

export const OutputControlSlider = (props: OutputNumberController) => {
    const style = useStyle();
    const controller = useTypedSelector(state => state.nodes.controllers[props.id]);

    const [value, setValue] = useControllerValue<number>(props.id, 0);

    const handleChange = (e: any, x: number | number[]) => {
        let v = x as number;
        setValue(v);
        props.onChange(v);
    }

    return <StyledSlider
        value={value}
        onChange={handleChange}
        tone={style.palette.highlight.main}
        rail={style.components.Slider.railColor}
        shadow={style.palette.highlight.ghost}
        min={controller.min}
        max={controller.max}
        step={controller.step}
    />
}