import {
    BlockData,
    ConnectionData,
    LinkData,
    LinksState,
    MachineConfiguration,
    MachineDefinitionState, MachineUpdate,
    NodeData,
    NodeDataUpdate,
    NodesState, StackData
} from "./Machine";

export abstract class MachineConnector {
    public onInitialized: ((config: MachineConfiguration) => any) | null = null;
    protected readonly _onUpdate: (state: MachineDefinitionState) => any;

    public constructor(
        onUpdate: (state: MachineDefinitionState) => boolean
    ) {
        this._onUpdate = onUpdate;
    }

    public add(id: string, node: NodeData) {
        this.update({
            add: {
                nodes: {
                    [id]: node
                }
            }
        });
    }

    public remove(id: string) {
        this.update({
            remove: {
                nodes: [id]
            }
        });
    }

    public link(id: string, link: LinkData) {
        this.update({
            add: {
                links: {
                    [id]: [link]
                }
            }
        });
    }

    public unlink(id: string, link: LinkData) {
        this.update({
            remove: {
                links: {
                    [id]: [link]
                }
            }
        });
    }

    public connect(id: string, connection: ConnectionData) {
        return this.link(id, connection);
    }

    public disconnect(id: string, connection: ConnectionData) {
        return this.unlink(id, connection);
    }

    public stack(id: string, connection: StackData) {
        return this.link(id, connection);
    }

    public unstack(id: string, connection: StackData) {
        return this.unlink(id, connection);
    }

    public group(id: string, connection: BlockData) {
        return this.link(id, connection);
    }

    public ungroup(id: string, connection: BlockData) {
        return this.unlink(id, connection);
    }

    public updateState(id: string, update: NodeDataUpdate) {
        this.update({
            update: {
                nodes: {[id]: update}
            }
        });
    }

    public abstract init(nodes?: NodesState, links?: LinksState): void;
    public abstract update(update: MachineUpdate): void;
}