import {
    compose,
    ExecutableNode,
    hoverNode,
    Node,
    setConnectionLine,
    setMode,
    unhoverNode,
    updateNode
} from "../../store/nodes";
import React, {CSSProperties, FunctionComponent, useEffect, useRef, useState} from "react";
import store, {useTypedSelector} from "../../store";
import {DraggableCore, DraggableEventHandler} from "react-draggable";
import FancyPort, {IconPort} from "./FancyPort";
import {constrain, constrainDelta, contains, getOverlap, intersects, mean} from "../../common/math";
import {useZoom} from "../workspace/ZoomTranslate";
import {useNodeStyle, useShadowStyle, useStyle} from "../../hooks";
import {SolidNodeStyle} from "../style";
import {openDialog} from "../../store/workspace";
import {commands} from "../../common/session/CommandHandler";
import {getIcon, getController, Controller, WaitingIconAnimated} from "../labels";
import {ExecutableNodeType, nodeTypeIconDimensions} from "../../common/types/nodeTypes";
import MachineHandler, {getNodeTypeFromPath} from "../../common/machine/MachineHandler";
import FancyNodes from "./FancyNodes";
import FancyNodeHighlights from "./FancyNodeHighlights";
import FancyNodeShadows from "./FancyNodeShadows";
import {_scale, scaled} from "./index";
import {PortController} from "../controllers";

type SnapResult = { t: number, prev?: ExecutableNode, next?: ExecutableNode, tx?: number, ty?: number } | null;

function snap(me: ExecutableNode, nodes: ExecutableNode[], vTol: number, hTol: number = 0): SnapResult {
    let nearest: SnapResult = null;

    const getIntervalX = (n: ExecutableNode) => [n.shape.x, n.shape.x + n.shape.width];

    nodes.forEach(n => {
        if (n.id !== me.id) {
            let t: number;
            let best = nearest === null ? vTol : nearest.t;

            if ((!n.next || n.next === me.id)
                && (t = Math.abs(n.shape.y + n.shape.height - me.shape.y)) < vTol
                && (intersects(getIntervalX(n), getIntervalX(me), hTol))) {

                if (t < best) {
                    if (Math.abs(n.shape.x - me.shape.x) < vTol) nearest = {
                        t: t,
                        prev: n,
                        tx: n.shape.x,
                        ty: n.shape.y + n.shape.height
                    }; else if (Math.abs((n.shape.x + n.shape.width) - (me.shape.x + me.shape.width)) < vTol) nearest = {
                        t: t,
                        prev: n,
                        tx: n.shape.x + n.shape.width - me.shape.width,
                        ty: n.shape.y + n.shape.height
                    }; else nearest = {
                        t: t,
                        prev: n,
                        ty: n.shape.y + n.shape.height
                    };
                }

            } else if ((!n.prev || n.prev === me.id)
                && (t = Math.abs(me.shape.y + me.shape.height - n.shape.y)) < vTol
                && (intersects(getIntervalX(n), getIntervalX(me), hTol))) {

                if (t < best) {
                    if (Math.abs(n.shape.x - me.shape.x) < vTol) nearest = {
                        t: t,
                        next: n,
                        tx: n.shape.x,
                        ty: n.shape.y - me.shape.height
                    }; else if (Math.abs((n.shape.x + n.shape.width) - (me.shape.x + me.shape.width)) < vTol) nearest = {
                        t: t,
                        next: n,
                        tx: n.shape.x + n.shape.width - me.shape.width,
                        ty: n.shape.y - me.shape.height
                    }; else nearest = {
                        t: t,
                        next: n,
                        ty: n.shape.y - me.shape.height
                    };
                }
            }
        }
    })

    if (nearest != null) return nearest;
    else return null;
}

type DropResult = ExecutableNode | null;

function drop(me: ExecutableNode, nodes: { [id: string]: ExecutableNode }): DropResult {
    let result: DropResult = null;

    const getIntervalX = (n: ExecutableNode) => [n.shape.x, n.shape.x + n.shape.width];
    const getIntervalY = (n: ExecutableNode) => [n.shape.y, n.shape.y + n.shape.height];
    const isRelated = (n: ExecutableNode, w: ExecutableNode): boolean => {
        let parent = n.parent;
        while (parent !== w.id) {
            if (parent) parent = nodes[parent].parent
            else break;
        }
        return parent !== undefined;
    }

    Object.values(nodes).forEach(n => {
        if (n.id !== me.id) {
            if (contains(getIntervalX(n), getIntervalX(me)) && contains(getIntervalY(n), getIntervalY(me))) {
                if (!(result && isRelated(result, n))) {
                    result = n;
                }
            }
        }
    });

    return result;
}

interface RelativePosition {
    left: number
    top: number
    right: number
    bottom: number
}

export interface NodeAppearance {
    type?: 'flat' | 'solid' | 'ghost' | string
}

export const ActionNodeBackground = ({style, marginTop, marginBottom, colors}: {
    style: CSSProperties & { left: number, top: number, width: number, height: number },
    marginTop?: number,
    marginBottom?: number,
    colors?: string[]
}) => {
    const nodeStyle = useNodeStyle();

    if (!colors) colors = (nodeStyle.custom?.solid as SolidNodeStyle).gradient ?? ['#0d0', '#3e3', '#6f6', '#9f9', '#dfd'];
    let cBase = colors[2]//colors[2] //'#6f6'
    let cTopLeft = colors[4] //'#cfc'
    let cTop = colors[3] //'#9f9'
    let cTopRight = colors[2] //'#6f6'
    let cBottomRight = colors[0] //'#0f0'
    let cBottom = colors[1] //'#3f3'
    let cBottomLeft = colors[2] //cTopRight

    let fullCircleL = {
        background: `conic-gradient(from 0.875turn at 100% 50%, ${cTopLeft}, ${cTopRight}, ${cBottomRight}, ${cBottomLeft}, ${cTopLeft})`
    }
    let fullCircleR = {
        background: `conic-gradient(from 0.875turn at 0% 50%, ${cTopLeft}, ${cTopRight}, ${cBottomRight}, ${cBottomLeft}, ${cTopLeft})`
    }

    let mt = marginTop ?? 0;
    let mb = marginBottom ?? 0;
    let height = style.height - mt - mb;

    return <>
        <div style={{...style, top: style.top + mt, height: height, overflow: 'hidden', opacity: nodeStyle.custom?.solid.opacity ?? 0.88}}>
            <div style={{
                ...fullCircleL,
                position: 'absolute',
                left: 0,
                top: 0,
                width: height / 2,
                height: height
            }}/>
            <div style={{
                ...fullCircleR,
                position: 'absolute',
                right: 0,
                top: 0,
                width: height / 2,
                height: height
            }}/>
            <div style={{
                background: cTop,
                position: 'absolute',
                left: height / 2,
                top: 0,
                width: style.width - height,
                height: height / 2
            }}/>
            <div style={{
                background: cBottom,
                position: 'absolute',
                left: height / 2,
                bottom: 0,
                width: style.width - height,
                height: height / 2
            }}/>
            <div style={{
                background: cBase, //cBase,
                position: 'absolute',
                left: 5 * _scale,
                right: 5 * _scale,
                top: 5 * _scale - mt,
                bottom: 5 * _scale - mb,
                borderRadius: 5 * _scale
            }}/>
        </div>
    </>
}

export const ExecutableNodeLabel = ({node}: { node: ExecutableNode }) => {
    const label = node.label
    const nodeType = getNodeTypeFromPath(node.type);
    const style = useNodeStyle();

    if (React.isValidElement(label) || typeof label === "string") {
        return <>label</>
    } else if (nodeType && nodeType.label) {
        let typeLabel = nodeType.label;

        if (React.isValidElement(typeLabel) || typeof typeLabel === "string") {
            return <>{typeLabel}</>
        } else if ("controller" in typeLabel) {
            return <ExecutableNodeController type={nodeType} id={node.id}/>
        } else if ("icon" in typeLabel) {
            return <ExecutableNodeTypeIcon type={nodeType}
                                           color={node.state === 'disabled' ? style.disabled?.textColor : style.textColor}/>
        }
    }

    return null;
}

export const hasController = (node: ExecutableNode): boolean => {
    let label = node.label
    let nodeType = getNodeTypeFromPath(node.type);

    if (React.isValidElement(label) || typeof label === "string") {
        return false;
    } else if (nodeType && nodeType.label) {
        let typeLabel = nodeType.label;

        if (React.isValidElement(typeLabel) || typeof typeLabel === "string") {
            return false;
        } else return "controller" in typeLabel;
    }

    return false;
}

export const ExecutableNodeTypeIcon = ({type, color}: { type: ExecutableNodeType; color?: string }) => {
    let label = type && type.label;
    if (!label) return null;

    if (React.isValidElement(label) || typeof label === "string") {
        return <>{label}</>

    } else if ("icon" in label) {
        let name = label.icon;
        let pack = label.package;
        let iconType = getIcon(name, pack || "");
        if (typeof iconType === "string") return <>{iconType}</>;
        else {
            return React.createElement(iconType as FunctionComponent<{ color?: string }>, {color});
        }

    } else return null;
}

export const ExecutableNodeController = ({type, id}: { type: ExecutableNodeType, id: string }) => {
    const label = type && type.label;
    const controllerValue = useTypedSelector(state => state.nodes.controllers[id]?.value);
    let controllerType: Controller<any> | undefined = undefined;
    if (label && typeof label !== "string" && "controller" in label && label.controller) {
        controllerType = getController(label.controller, label.package || "");
    }

    useEffect(() => {
        if (controllerValue !== undefined) {
            handleChange(controllerValue);
        }
    }, [controllerValue])

    if (!label) return null;

    const handleChange = (x: any, i: number = 0) => {
        if (!controllerType) return;

        const connector = MachineHandler.current.connector;
        connector.updateState(id, {
            output: {
                [i]: {
                    data: {
                        value: "" + x,
                        type: controllerType?.props?.port?.type
                    }
                }
            }
        })
        //connector.updatePorts([{id, data: "" + x, port: {direction: "out", index: 0}}])
    }

    if (React.isValidElement(label) || typeof label === "string") {
        return <>{label}</>

    } else if (controllerType) {
        return React.createElement(controllerType.component, {
            id,
            onChange: handleChange,
            key: id + "cc",
            ...controllerType.props
        });
    }

    return null;
}

const FancyExecutableNode = ({node, state, shadow, snapTo, dropIn, style}: {
    node: ExecutableNode
    state?: string
    shadow?: boolean
    snapTo?: ExecutableNode[]
    dropIn?: { [id: string]: ExecutableNode }
    style?: CSSProperties
}) => {
    const [relMousePos, setRelMousePos] = useState<RelativePosition>({left: 0, top: 0, right: 0, bottom: 0});

    const [dragging, setDragging] = useState<boolean | 'lt' | 'rt' | 'lb' | 'rb' | 'stack'>(false);
    const dragRef = useRef<HTMLDivElement>(null);

    let appearance = getNodeTypeFromPath(node.type)?.appearance || {type: 'flat'};

    const zoom = useZoom();

    const [cursor, setCursor] = useState<'grab' | 'grabbing'>('grab');
    let hovered = useTypedSelector(state => state.nodes.hovered === node.id);
    let hoveredPort = useTypedSelector(state => hovered ? state.nodes.hoveredPort : undefined);

    let prev: ExecutableNode | undefined = useTypedSelector(state => {
        let id = node.prev;
        if (id) return state.nodes.nodes[id] as ExecutableNode; else return undefined;
    });

    let next: ExecutableNode | undefined = useTypedSelector(state => {
        let id = node.next;
        if (id) return state.nodes.nodes[id] as ExecutableNode; else return undefined;
    });

    let nextOverlap = next && getOverlap(
        [node.shape.x, node.shape.x + node.shape.width],
        [next.shape.x, next.shape.x + next.shape.width]
    );

    const screen = (x: number) => x * _scale * zoom;
    const world = (x: number) => x / _scale / zoom;
    const resizeArea = 4;

    const getInputPos = (index: number) => ({x: node.shape.x, y: node.shape.y + 12 * (index + 1)})
    const getOutputPos = (index: number) => ({x: node.shape.x + node.shape.width, y: node.shape.y + 12 * (index + 1)})

    const handleStart: DraggableEventHandler = (e, data) => {
        if (hoveredPort) {
            let pos = hoveredPort.direction === 'out' ? getOutputPos(hoveredPort.index) : getInputPos(hoveredPort.index);

            store.dispatch(hoverNode(undefined));
            store.dispatch(setConnectionLine({
                from: scaled(pos),
                to: null,
                start: {
                    id: node.id,
                    index: hoveredPort.index
                },
                target: null,
                direction: hoveredPort.direction === 'out' ? 'forward' : 'backward'
            }));
        } else {
            handleNodeStart(e, data);
        }
    }

    const handleNodeStart: DraggableEventHandler = (e, data) => {
        let wnx = world(data.x) - node.shape.x;
        let wny = world(data.y) - node.shape.y;

        let resize: boolean | 'lt' | 'rt' | 'lb' | 'rb' | 'stack' = true;

        if (e.ctrlKey) resize = 'stack';
        else if (wnx < resizeArea && wny < resizeArea) resize = 'lt';
        else if (wnx > node.shape.width - resizeArea && wny < resizeArea) resize = 'rt';
        else if (wnx < resizeArea && wny > node.shape.height - resizeArea) resize = 'lb';
        else if (wnx > node.shape.width - resizeArea && wny > node.shape.height - resizeArea) resize = 'rb';

        setDragging(resize);

        if (resize) {
            store.dispatch(setMode(resize === true ? 'drag' : 'resize'));
        }

        setRelMousePos({
            left: data.x - screen(node.shape.x),
            top: data.y - screen(node.shape.y),
            right: screen(node.shape.width + node.shape.x) - data.x,
            bottom: screen(node.shape.height + node.shape.y) - data.y
        });

        setCursor('grabbing');

        commands.memoNodes();
    }

    const handleDrag: DraggableEventHandler = (e, data) => {
        //if ([true, 'lt', 'rt', 'lb', 'rb'].includes(dragging)) {
        if (dragging) handleNodeDrag(e, data);
        //}
    }

    const handleNodeDrag: DraggableEventHandler = (e, data) => {
        let newX = world(data.x - relMousePos.left);
        let newY = world(data.y - relMousePos.top);
        let newW: number | undefined = undefined;
        let newH: number | undefined = undefined;

        if (dragging === 'rb') {
            let x = node.shape.x;
            let y = node.shape.y;

            if (node.shape.resizable === true || node.shape.resizable === 'horizontally')
                newW = world(data.x) - x + world(relMousePos.right);
            if (node.shape.resizable === true || node.shape.resizable === 'vertically')
                newH = world(data.y) - y + world(relMousePos.bottom);

            if (newW !== undefined)
                newW = constrain(newW, node.shape.minWidth, node.shape.maxWidth);
            if (newH !== undefined)
                newH = constrain(newH, node.shape.minHeight, node.shape.maxHeight);

            newX = x;
            newY = y;

        } else if (dragging === 'rt') {
            let x = node.shape.x;
            let y = node.shape.y;
            let h = node.shape.height;

            if (node.shape.resizable === true || node.shape.resizable === 'horizontally')
                newW = world(data.x) - x + world(relMousePos.right);
            if (node.shape.resizable === true || node.shape.resizable === 'vertically')
                newH = h - (world(data.y) - y) + world(relMousePos.top);

            let constrainHResult;
            if (newW !== undefined)
                newW = constrain(newW, node.shape.minWidth, node.shape.maxWidth);
            if (newH !== undefined) {
                constrainHResult = constrainDelta(newH, node.shape.minHeight, node.shape.maxHeight);
                newH = constrainHResult.x;
            }

            newX = x;
            newY = world(data.y) - world(relMousePos.top)
                + (constrainHResult?.deltaMax ?? 0)
                - (constrainHResult?.deltaMin ?? 0);

        } else if (dragging === 'lt') {
            let x = node.shape.x;
            let y = node.shape.y;
            let w = node.shape.width;
            let h = node.shape.height;

            if (node.shape.resizable === true || node.shape.resizable === 'horizontally')
                newW = w - (world(data.x) - x) + world(relMousePos.left);
            if (node.shape.resizable === true || node.shape.resizable === 'vertically')
                newH = h - (world(data.y) - y) + world(relMousePos.top);

            let constrainWResult, constrainHResult;
            if (newW !== undefined) {
                constrainWResult = constrainDelta(newW, node.shape.minWidth, node.shape.maxWidth);
                newW = constrainWResult.x;
            }
            if (newH !== undefined) {
                constrainHResult = constrainDelta(newH, node.shape.minHeight, node.shape.maxHeight);
                newH = constrainHResult.x;
            }

            newX = world(data.x) - world(relMousePos.left)
                + (constrainWResult?.deltaMax ?? 0)
                - (constrainWResult?.deltaMin ?? 0);
            newY = world(data.y) - world(relMousePos.top)
                + (constrainHResult?.deltaMax ?? 0)
                - (constrainHResult?.deltaMin ?? 0);

        } else if (dragging === 'lb') {
            let x = node.shape.x;
            let y = node.shape.y;
            let w = node.shape.width;

            if (node.shape.resizable === true || node.shape.resizable === 'horizontally')
                newW = w - (world(data.x) - x) + world(relMousePos.left);
            if (node.shape.resizable === true || node.shape.resizable === 'vertically')
                newH = world(data.y) - y + world(relMousePos.bottom);

            let constrainWResult;
            if (newW !== undefined) {
                constrainWResult = constrainDelta(newW, node.shape.minWidth, node.shape.maxWidth);
                newW = constrainWResult.x;
            }
            if (newH !== undefined)
                newH = constrain(newH, node.shape.minHeight, node.shape.maxHeight);

            newX = world(data.x) - world(relMousePos.left)
                + (constrainWResult?.deltaMax ?? 0)
                - (constrainWResult?.deltaMin ?? 0);
            newY = y;
        }

        if (snapTo && dragging === true) {
            let snapping = snap({
                id: node.id,
                shape: {
                    x: newX,
                    y: newY,
                    width: node.shape.width,
                    height: node.shape.height
                }
            }, snapTo, 6, -6);

            if (snapping) {
                newX = snapping.tx ?? newX;
                newY = snapping.ty ?? newY;

                if (snapping.next) {
                    node.next = snapping.next.id;
                    store.dispatch(updateNode({
                        id: node.next,
                        prev: node.id
                    }));
                }

                if (snapping.prev) {
                    node.prev = snapping.prev.id;
                    store.dispatch(updateNode({
                        id: node.prev,
                        next: node.id
                    }));
                }

            } else {
                if (node.next) {
                    store.dispatch(updateNode({
                        id: node.next,
                        prev: null
                    }));
                    node.next = null;
                }

                if (node.prev) {
                    store.dispatch(updateNode({
                        id: node.prev,
                        next: null
                    }));
                    node.prev = null;
                }
            }
        }

        let newShape: { x: number, y: number, width?: number, height?: number } = {x: newX, y: newY};

        if (newW !== undefined) newShape.width = newW;
        if (newH !== undefined) newShape.height = newH;

        store.dispatch(updateNode({
            id: node.id,
            shape: newShape
        }, {
            updateChildren: dragging === true,
            moveStack: dragging === 'stack'
        }));

        if (newW ?? newH !== undefined) {
            let deltaH = newH ? newH - node.shape.height : 0;

            if (newH !== undefined && next && (dragging === true || dragging === 'lb' || dragging === 'rb')) {
                store.dispatch(updateNode({
                    id: next.id,
                    shape: {
                        y: next.shape.y + deltaH
                    }
                }, {updateStack: 'forward'}));
            }

            if (newH !== undefined && prev && (dragging === true || dragging === 'lt' || dragging === 'rt')) {
                store.dispatch(updateNode({
                    id: prev.id,
                    shape: {
                        y: newY - prev.shape.height
                    }
                }, {updateStack: 'backward'}));
            }
        }
    }

    const handleStop: DraggableEventHandler = (e, data) => {
        let dropResult: DropResult = dropIn ? drop(node, dropIn) : null;
        if (dropResult) store.dispatch(compose(dropResult.id, node.id));
        setDragging(false);
        setCursor('grab');
        store.dispatch(setMode('default'));
        commands.pushMemoNodes();
    }

    const handleMouseEnter = () => store.dispatch(hoverNode(node));
    const handleMouseLeave = () => !dragging && store.dispatch(unhoverNode(node));

    const rbStyle = {
        right: -2,
        bottom: -2,
        cursor: 'nwse-resize'
    }

    const lbStyle = {
        left: -2,
        bottom: -2,
        cursor: 'nesw-resize'
    }

    const rtStyle = {
        right: -2,
        top: -2,
        cursor: 'nesw-resize'
    }

    const ltStyle = {
        left: -2,
        top: -2,
        cursor: 'nwse-resize'
    }

    const Draggers = () => <>{[rbStyle, lbStyle, rtStyle, ltStyle].map((style, i) => <div
        key={node.id + "DRAGGER" + i}
        style={{
            ...style,
            position: 'absolute',
            width: 4 * _scale,
            height: 4 * _scale,
            background: 'transparent'
        }}
    />)}</>

    function domRectToArray(rect: DOMRect): number[] {
        return [
            rect.left,
            rect.top,
            rect.width,
            rect.height
        ]
    }

    let controller = hasController(node);

    let theme = useStyle();
    let disabled = state === 'disabled' || state === 'initializing';
    let nodeStyle = (disabled && theme.components.Node.disabled) ? {...theme.components.Node, ...theme.components.Node.disabled} : theme.components.Node;
    let shadowStyle = theme.shadow;

    let border = nodeStyle.strokeWidth;
    let borderRadius = nodeStyle.cornerRadius;
    let borderRadius_ = nodeStyle.cornerRadiusStack;
    let thresh = 4;

    let borderTLRadius = prev && node.shape.x + thresh > prev.shape.x ? borderRadius_ : borderRadius;
    let borderTRRadius = prev && node.shape.x + node.shape.width - thresh < prev.shape.x + prev.shape.width ? borderRadius_ : borderRadius;
    let borderBLRadius = next && node.shape.x + thresh > next.shape.x ? borderRadius_ : borderRadius;
    let borderBRRadius = next && node.shape.x + node.shape.width - thresh < next.shape.x + next.shape.width ? borderRadius_ : borderRadius;

    let color = (appearance && appearance.type === 'solid') ? (nodeStyle.custom?.solid?.color ?? '#00cc00') : (node.state === 'disabled' ? nodeStyle.disabled?.textColor : nodeStyle.textColor);
    let waitingAnimationColor = (appearance && appearance.type === 'solid') ? (nodeStyle.custom?.solid?.color ?? '#00cc00') : nodeStyle.color;

    return <><DraggableCore nodeRef={dragRef} onStart={handleStart} onDrag={handleDrag} onStop={handleStop}>
        <div
            ref={dragRef}
            style={{
                position: 'absolute',
                left: node.shape.x * _scale,
                top: node.shape.y * _scale,
                width: node.shape.width * _scale,
                height: node.shape.height * _scale,
                cursor: cursor
            }}
            onContextMenu={e => {
                store.dispatch(
                    openDialog({
                        type: 'node info',
                        nodeId: node.id,
                        rect: domRectToArray(e.currentTarget.getBoundingClientRect())
                    })
                )
            }}
        >
            {(!appearance || appearance.type === 'flat') && <div
                style={{
                    position: 'absolute',
                    left: -border / 2,
                    top: -border / 2,
                    width: node.shape.width * _scale - border,
                    height: node.shape.height * _scale - border,
                    background: nodeStyle.background,
                    //backdropFilter: 'blur(2px)',
                    borderTopLeftRadius: borderTLRadius,
                    borderTopRightRadius: borderTRRadius,
                    borderBottomLeftRadius: borderBLRadius,
                    borderBottomRightRadius: borderBRRadius,
                    border: border + 'px solid ' + nodeStyle.color, //#e0e8f0',
                    boxShadow: shadow ?
                        shadowStyle.offsetX + 'px ' +
                        shadowStyle.offsetY + 'px ' +
                        shadowStyle.blurRadius + 'px ' +
                        shadowStyle.spreadRadius + 'px ' +
                        shadowStyle.color
                        : undefined,
                    ...style
                }}

                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Draggers/>
            </div>}
            {appearance && appearance.type === 'solid' && <>
                <div style={{
                    position: 'absolute',
                    left: -border / 2,
                    top: prev ? 0 : -border / 2,
                    width: node.shape.width * _scale + border,
                    height: node.shape.height * _scale + (prev ? 0 : border / 2) + (next ? 0 : border / 2),
                    background: 'transparent',
                    borderTopLeftRadius: borderTLRadius,
                    borderTopRightRadius: borderTRRadius,
                    borderBottomLeftRadius: borderBLRadius,
                    borderBottomRightRadius: borderBRRadius,
                    //border: border / 2 + 'px solid #0f0', //#e0e8f0',
                    boxShadow: 'inset 0 0 ' + 8 * _scale + 'px 0 #080' + (shadow ? ', ' + 3 * _scale + 'px ' + 3 * _scale + 'px ' + 12 * _scale + 'px 0px #00408050' : "")
                }}/>
                <ActionNodeBackground style={{
                    position: 'absolute',
                    left: -border / 2,
                    top: -border / 2,
                    width: node.shape.width * _scale + border,
                    height: node.shape.height * _scale + border,
                    borderTopLeftRadius: borderTLRadius,
                    borderTopRightRadius: borderTRRadius,
                    borderBottomLeftRadius: borderBLRadius,
                    borderBottomRightRadius: borderBRRadius,
                }} marginTop={prev ? border / 2 : 0} marginBottom={next ? border / 2 : 0}/>
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: node.shape.width * _scale,
                        height: node.shape.height * _scale,
                        background: 'transparent',
                        borderTopLeftRadius: borderTLRadius,
                        borderTopRightRadius: borderTRRadius,
                        borderBottomLeftRadius: borderBLRadius,
                        borderBottomRightRadius: borderBRRadius
                    }}

                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
            </>}
            <div
                style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    width: 0,
                    height: 0,
                    cursor,
                    color,//'#b8c6d4',
                    pointerEvents: 'none'
                }}>
                <div style={{
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                    width: (node.shape.width - 14) * _scale,
                    height: (Math.max(node.shape.width, node.shape.height) - 14) * _scale,
                    lineHeight: (Math.max(node.shape.width, node.shape.height) - 14.5) * _scale + 'px',
                    fontFamily: 'Domine',
                    fontSize: 11.5 * _scale + 'px',
                    fontWeight: 600
                }}>
                    {!controller && (state === 'initializing' ? <WaitingIconAnimated color={waitingAnimationColor}/> : <ExecutableNodeLabel node={node}/>)}
                </div>
            </div>
            {node.input?.map((port, i) => <FancyPort
                direction={'in'}
                index={i}
                nodeId={node.id}
                key={node.id + "in" + i}
                doNotHover={dragging !== false}
                highlight={hoveredPort === undefined ? hovered : (hoveredPort.direction === 'in' && hoveredPort.index === i)}
            />)}
            {node.output?.map((port, i) => <FancyPort
                direction={'out'}
                index={i}
                nodeId={node.id}
                key={node.id + "out" + i}
                doNotHover={dragging !== false}
                highlight={hoveredPort === undefined ? hovered : (hoveredPort.direction === 'out' && hoveredPort.index === i)}
            />)}
            {next && <div style={{
                position: 'absolute',
                width: 0,
                height: 0,
                left: nextOverlap ? (mean(nextOverlap) - node.shape.x) * _scale : '50%',
                marginLeft: -6,
                bottom: 2,
                borderLeft: '6px solid transparent',
                borderRight: '6px solid transparent',
                borderTop: '6px solid ' + ((appearance && appearance.type === 'solid') ? (nodeStyle.custom?.solid?.accentColor ?? '#00cc00') : nodeStyle.accentColor), // + nodeStyle.accentColor,
                pointerEvents: 'none'
            }}/>}
        </div>
    </DraggableCore>
        {/*controller && <div
                style={{
                    position: 'absolute',
                    left: node.shape.x * _scale + 6.5 * _scale,
                    top: (node.shape.y + node.shape.height / 2) * _scale,
                    width: node.shape.width * _scale,
                    height: 0
                }}
            >
                <div style={{
                    transform: 'translateY(-50%)',
                    width: 'calc(100% - ' + 13 * _scale + 'px)',
                    height: 'auto'
                }}>
                    <ExecutableNodeLabel node={node}/>
                </div>
            </div>*/}
        {node.children && <>
            <FancyNodeShadows filter={n => n.parent === node.id}/>
            <FancyNodeHighlights filter={n => n.parent === node.id}/>
            <FancyNodes filter={n => n.parent === node.id} forceUpdate/>
        </>}
    </>
}

const noShapeUpdate = (prevNodeProps: { node: ExecutableNode, state?: string }, nextNodeProps: { node: ExecutableNode, state?: string }) =>
    prevNodeProps.node.shape.x === nextNodeProps.node.shape.x &&
    prevNodeProps.node.shape.y === nextNodeProps.node.shape.y &&
    prevNodeProps.node.shape.width === nextNodeProps.node.shape.width &&
    prevNodeProps.node.shape.height === nextNodeProps.node.shape.height &&
    prevNodeProps.node.prev === nextNodeProps.node.prev &&
    prevNodeProps.node.next === nextNodeProps.node.next &&
    prevNodeProps.state === nextNodeProps.state

const MemoFancyExecutableNode = React.memo(FancyExecutableNode, noShapeUpdate);

const ConnectedExecutableNode = ({id, shadow, forceUpdate}: {
    id: string,
    shadow?: boolean,
    forceUpdate?: boolean
}) => {
    let node = useTypedSelector(state => state.nodes.nodes[id]) as ExecutableNode;
    let state = useTypedSelector(state => state.nodes.nodes[id].state);

    return forceUpdate ? <FancyExecutableNode node={node} shadow={shadow} state={state}/> :
        <MemoFancyExecutableNode node={node} shadow={shadow} state={state}/>
}

export default ConnectedExecutableNode;

export const hasRectangleShape = (node: Node) => {
    return "width" in node.shape && "height" in node.shape;
}

export const hasCircleShape = (node: Node) => {
    return "radius" in node.shape;
}

export const SnappingExecutableNode = ({id, shadow}: { id: string, shadow?: boolean }) => {
    const nodes = useTypedSelector(state => state.nodes.nodes);
    const nodeStyle = useNodeStyle();

    return <FancyExecutableNode
        node={nodes[id] as ExecutableNode}
        shadow={shadow}
        snapTo={
            Object.values(nodes).filter(
                n => hasRectangleShape(n)
            ).map(
                n => n as ExecutableNode
            )
        }
        dropIn={Object.fromEntries<ExecutableNode>(
            Object.entries(nodes).filter(
                ([, n]) => n.children !== undefined
            ).map(
                ([id, n]) => [id, n] as [string, ExecutableNode]
            )
        )}
        style={nodeStyle['&:hover'] ? {
            background: nodeStyle['&:hover'].background,
        } : undefined}
    />
}

export const ExecutableNodeTypePreview = ({type, scale, style, highlightPorts}: {
    type?: ExecutableNodeType
    scale?: number,
    style?: CSSProperties,
    highlightPorts?: { in?: boolean[], out?: boolean[] }
}) => {
    let nodeStyle = useNodeStyle();
    let shadowStyle = useShadowStyle();

    if (!type) return null;

    let border = nodeStyle.strokeWidth * 1.25;
    let borderRadius = nodeStyle.cornerRadius;
    let thresh = 4;

    scale = scale || 36;

    let node = {shape: nodeTypeIconDimensions(type)}

    let sc = scale / Math.max(node.shape.width * _scale, node.shape.height * _scale); //2 * scale / (node.shape.width * _scale + node.shape.height * _scale)

    let appearance = type.appearance || {type: 'flat'};

    return <div style={{
        transform: 'scale(' + sc + ')',
        ...style
    }}>
        <div
            style={{
                position: 'absolute',
                left: -node.shape.width * _scale / 2, //node.shape.x * _scale,
                top: -node.shape.height * _scale / 2, //node.shape.y * _scale,
                width: node.shape.width * _scale,
                height: node.shape.height * _scale
            }}
        >
            {(!appearance || appearance.type === 'flat') && <div
                style={{
                    position: 'absolute',
                    left: -border / 2,
                    top: -border / 2,
                    width: node.shape.width * _scale - border,
                    height: node.shape.height * _scale - border,
                    background: nodeStyle.background,
                    //backdropFilter: 'blur(2px)',
                    borderRadius: borderRadius,
                    border: border + 'px solid ' + nodeStyle.color, //#e0e8f0',
                    boxShadow: shadowStyle.offsetX + 'px ' +
                        shadowStyle.offsetY + 'px ' +
                        shadowStyle.blurRadius + 'px ' +
                        shadowStyle.spreadRadius + 'px ' +
                        shadowStyle.color
                }}
            />}
            {appearance && appearance.type === 'solid' && <>
                <div style={{
                    position: 'absolute',
                    left: -border / 2,
                    top: -border / 2,
                    width: node.shape.width * _scale + border,
                    height: node.shape.height * _scale + border,
                    background: 'transparent',
                    borderRadius: borderRadius,
                    //border: border / 2 + 'px solid #0f0', //#e0e8f0',
                    boxShadow: 'inset 0 0 ' + 4 * _scale + 'px 0 #080' + ', ' + 3 * _scale + 'px ' + 3 * _scale + 'px ' + 12 * _scale + 'px 0px ' + shadowStyle.color
                }}/>
                <ActionNodeBackground style={{
                    position: 'absolute',
                    left: -border / 2,
                    top: -border / 2,
                    width: node.shape.width * _scale + border,
                    height: node.shape.height * _scale + border,
                    borderRadius: borderRadius
                }}/>
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: node.shape.width * _scale,
                        height: node.shape.height * _scale,
                        background: 'transparent',
                        borderRadius: borderRadius
                    }}
                />
            </>}
            <div
                style={{
                    position: 'absolute',
                    inset: 0,
                    textAlign: 'center',
                    lineHeight: (Math.max(node.shape.width, node.shape.height) - 13.5) * _scale + 'px',
                    fontFamily: 'Domine',
                    fontSize: 12 * _scale + 'px',
                    fontWeight: 600,
                    color: (appearance && appearance.type === 'solid') ? (nodeStyle.custom?.solid?.color ?? '#00cc00') : '#000000',//'#b8c6d4',
                    pointerEvents: 'none',
                    paddingLeft: 7 * _scale,
                    paddingRight: 7 * _scale
                }}>
                <ExecutableNodeTypeIcon type={type} color={nodeStyle.textColor}/>
            </div>
            {type.input?.map((port, i) => <IconPort
                direction={'in'}
                index={i}
                highlight={highlightPorts?.in && highlightPorts.in[i]}
                key={type.name + "icon in" + i}
            />)}
            {type.output?.map((port, i) => <IconPort
                direction={'out'}
                index={i}
                highlight={highlightPorts?.out && highlightPorts.out[i]}
                key={type.name + "icon out" + i}
            />)}
        </div>
    </div>
}

export const ConnectedExecutableNodePreview = ({id}: { id: string }) => {
    let node = useTypedSelector(state => state.nodes.nodes[id]) as ExecutableNode;
    let nodeType = getNodeTypeFromPath(node.type) ?? undefined;

    return node.type ? <ExecutableNodeTypePreview type={nodeType}/> : null
}