import {ExecutableNode, Node} from "../../store/nodes";
import {_scale} from "../nodes";
import React from "react";
import {useTypedSelector} from "../../store";
import {useZoom} from "../workspace/ZoomTranslate";
import {constrain} from "../../common/math";

const FancyExecutableNodeInfoLabel = ({node, category}: {
    node: ExecutableNode,
    category: string
}) => {
    let size = 13 * _scale;
    let padding = 2 * _scale;
    let rad = size / 2;

    const nodeInfo = useTypedSelector(state => state.nodes.nodeInfo[node.id] && state.nodes.nodeInfo[node.id][category]);
    if (nodeInfo === undefined || nodeInfo === null) return null;

    return <div style={{
        position: 'absolute',
        left: (node.shape.x + node.shape.width) * _scale,
        top: node.shape.y * _scale,
        width: 0,
        height: 0,
        pointerEvents: 'none'
    }}>
        <div style={{
            position: 'absolute',
            top: -size / 2,
            left: -size / 2,
            minWidth: size - padding * 2,
            height: size,
            background: '#00b090',
            borderRadius: rad + 'px ' + rad + 'px ' + rad + 'px 0',
            lineHeight: size + 'px',
            paddingLeft: padding,
            paddingRight: padding,
            color: '#fff',
            fontFamily: 'Roboto Mono',
            fontWeight: 700
        }}>{nodeInfo}</div>
    </div>
}

const noShapeUpdate = (prevNodeProps: { node: ExecutableNode }, nextNodeProps: { node: ExecutableNode }) =>
    prevNodeProps.node.shape.x === nextNodeProps.node.shape.x &&
    prevNodeProps.node.shape.y === nextNodeProps.node.shape.y &&
    prevNodeProps.node.shape.width === nextNodeProps.node.shape.width &&
    prevNodeProps.node.shape.height === nextNodeProps.node.shape.height

const MemoFancyExecutableNodeInfoLabel = React.memo(FancyExecutableNodeInfoLabel, noShapeUpdate);

const ConnectedFancyExecutableNodeInfoLabel = ({id, category}: { id: string, category: string }) => {
    let node = useTypedSelector(state => state.nodes.nodes[id]) as ExecutableNode;

    return <MemoFancyExecutableNodeInfoLabel node={node} category={category}/>
}

const FancyNodeInfoLabels = ({category}: { category: string }) => {
    let nodes = useTypedSelector(state => state.nodes.nodes);
    let zoom = useZoom();

    let thresh = 0.5;

    if (zoom < thresh) return null;

    return <div style={{opacity: constrain((zoom - thresh) * 8, 0, 1)}}>
        {Object.keys(nodes).map(id => {
            let node = nodes[id];
            if ('width' in node.shape) {
                return <ConnectedFancyExecutableNodeInfoLabel id={id} category={category}/>
            } else return null;
        })}
    </div>
}

export default FancyNodeInfoLabels;