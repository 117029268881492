export function contains(a: number[], b: number[], tol: number = 0) {
    return b[0] >= a[0] - tol && b[1] <= a[1] + tol;
}

export function intersects(a: number[], b: number[], tol: number) {
    return b[0] <= a[1] + tol && b[1] >= a[0] - tol;
}

export function getOverlap(i1: number[], i2: number[]): number[] {
    let f2, t1

    f2 = i1[0] < i2[0] ? i2[0] : i1[0]
    t1 = i1[1] < i2[1] ? i1[1] : i2[1]

    return [f2, t1]
}

export function mean(i: number[]): number {
    return (i[0] + i[1]) / 2
}

export function interpolate(x: number, bx: number, tx: number, bt: number, tt: number): number {
    return (x - bx) / (tx - bx) * (tt - bt) + bt
}

export function interpolateConstrained(x: number, bx: number, tx: number, bt: number, tt: number): number {
    if (x >= tx) return tt
    else if (x <= bx) return bt
    else return interpolate(x, bx, tx, bt, tt)
}

export function dist(x1: number, y1: number, x2: number, y2: number): number {
    const xx = x2 - x1;
    const yy = y2 - y1;
    return Math.sqrt(xx * xx + yy * yy);
}

export function length(x: number, y: number): number {
    return Math.sqrt(x * x + y * y);
}

export function getTotalLength(vecs: {x: number, y: number}[], close: boolean = false) {
    let total = 0;
    vecs.forEach((v, i) => {
        if (i < vecs.length - 1 || close) {
            let j = i + 1;
            if (j > vecs.length - 1) j = 0;
            let vi = vecs[i], vj = vecs[j];
            total += dist(vi.x, vi.y, vj.x, vj.y);
        }
    });
    return total;
}

export const toRadians = (x: number) => x * Math.PI / 180.0;
export const toDegrees = (x: number) => x / Math.PI * 180.0;

export function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number): {x: number, y: number} {
    let angleInRadians = toRadians(angleInDegrees - 90);

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}

export function getPointAt(vecs: {x: number, y: number}[], t: number): {x: number, y: number} {
    if (t === 0) return vecs[0];
    if (t >= getTotalLength(vecs, true)) return vecs[vecs.length - 1];

    let total = 0;
    for (let i = 0; i < vecs.length; i++) {
        let j = i + 1;
        if (j > vecs.length - 1) j = 0;
        let vi = vecs[i], vj = vecs[j];
        let dd = dist(vi.x, vi.y, vj.x, vj.y);
        if (t >= total && t <= total + dd) {
            let dt = t - total;
            let ft = dt / dd;
            return {
                x: vi.x + ft * (vj.x - vi.x),
                y: vi.y + ft * (vj.y - vi.y)
            }
        }
        total += dd;
    }

    return vecs[0];
}

export function getAngle(tx: number, ty: number) {
    let angle: number = Math.atan2(ty, tx);

    if(angle < 0){
        angle += Math.PI * 2;
    }

    return angle;
}

export const constrain = (x: number, min?: number, max?: number) => (max && x > max) ? max : ((min && x < min) ? min : x);

export const constrainDelta = (x: number, min?: number, max?: number): {
    x: number
    deltaMax?: number
    deltaMin?: number
} => (max && x > max) ? {x: max, deltaMax: max - x} : ((min && x < min) ? {x: min, deltaMax: x - min} : {x});