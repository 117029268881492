import {PortPosition, Unique, updateController} from "../../store/nodes";
import store, {useTypedSelector} from "../../store";

export type FancyType = 'float64' | 'string' | 'boolean'
export interface PortController<T extends "in" | "out", U> extends Unique {
    port: PortPosition & {direction: T, type: FancyType}
    onChange: (x: U, i?: number) => any
}

export interface StateController {
    init: () => any
    set: (x: string | undefined) => any
}

export interface PortControllerState<P> extends Unique {
    controllerState?: P
}

export interface PortControllerSettings<P> extends PortControllerState<P> {
    onChange: (changes: Partial<P>) => any
}

export function useControllerValue<T>(id: string, defaultValue: T): [T, (value: T) => any] {
    const controller = useTypedSelector(state => state.nodes.controllers[id]);

    const value: T = controller?.value ?? defaultValue;
    const setValue = (v: T) => {
        store.dispatch(updateController({id, state: {value: v}}));
    }

    return [value, setValue];
}