import {InputBase, Slider, SliderProps, styled} from "@mui/material";
import {useStyle} from "../../hooks";
import {PortPosition, Unique, updateController} from "../../store/nodes";
import React, {ReactNode, useState} from "react";
import {PortController, useControllerValue} from "./index";
import {InputBaseProps} from "@mui/material/InputBase/InputBase";
import store, {useTypedSelector} from "../../store";
import StyledInput from "./input/StyledInput";

export type OutputStringController = PortController<"out", string>

export interface FancyTextFieldProps {
    value?: string
}

export const OutputControlTextField = (props: OutputStringController) => {
    const style = useStyle();
    //const controller = useTypedSelector(state => state.nodes.controllers[props.port.id]);

    const [value, setValue] = useControllerValue<string>(props.id, "");

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let t = event.target.value;
        setValue(t);
        props.onChange(t);
    }

    return <StyledInput
        value={value}
        onChange={handleChange}
        background={style.components.Input.background}
        textColor={style.components.Input.color}
    />
}