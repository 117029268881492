import {KeyActionType, KeyDownAction, KeyUpAction} from "./types";

export const keyDown = (key: string): KeyDownAction => ({
    type: KeyActionType.KEYDOWN,
    payload: key
});

export const keyUp = (key: string): KeyUpAction => ({
    type: KeyActionType.KEYUP,
    payload: key
});