const DEBUG = false

export const _DEV = process.env.NODE_ENV === 'development';
export const _DEBUG = DEBUG && _DEV

export const loremIpsum = (paras: number) => {
    let result = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci turpis, tempus vel consectetur in, cursus et ipsum. Duis pretium, tellus ac placerat tempus, dolor nisi eleifend dolor, at efficitur erat dolor non eros. Nunc ut luctus magna. Aliquam elementum cursus vehicula. Pellentesque sit amet bibendum enim, vel sodales nisl. Curabitur vel quam sed dolor pellentesque interdum at nec nisl. Donec pretium nisl eget aliquam consectetur. Vestibulum non diam pharetra, vestibulum ante eu, venenatis enim. Nullam consequat sit amet sapien et tempus. Aenean luctus at ligula in iaculis."
    if (paras < 2) return result
    result += "\n" + "\n" +
        "Suspendisse potenti. Aenean semper metus nunc. In consectetur turpis ex, et tincidunt metus pulvinar nec. Praesent commodo bibendum ornare. Nunc pretium, nisl quis feugiat porta, tortor erat molestie arcu, ac sodales ante magna et urna. Aenean mollis nibh eu velit blandit lobortis. Nunc gravida dignissim metus, a molestie sapien rhoncus a. Cras pharetra, justo a fermentum finibus, orci neque ultricies ligula, in congue metus arcu eget nisl. Proin et varius diam. Vivamus ut finibus turpis, quis convallis nunc. Cras ut est luctus, consequat nulla quis, elementum eros. Aenean eget arcu in ante lacinia sollicitudin. Duis aliquet scelerisque ipsum ac egestas. Praesent in sodales urna."
    return result
}

export const loremIpsumShort = () => {
    return "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci turpis, tempus vel consectetur in, cursus et ipsum. Duis pretium, tellus ac placerat tempus, dolor nisi eleifend dolor, at efficitur erat dolor non eros."
}