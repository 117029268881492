import {Types} from "./types";
import {CartesianDimensions, ConstrainedCartesianDimensions, NodeLabel, Rectangle, Resizable} from "../../store/nodes";
import * as controllers from "./controllers.json";
import {NodeAppearance} from "../../components/nodes/FancyExecutableNode";
import {ComponentClass, FunctionComponent} from "react";

export interface Identity {
    id: string
}

export interface PortType {
    name?: string
    type?: Types
}

export interface ExecutableNodeType {
    name?: string
    description?: string
    label?: NodeLabel
    //dimensions?: CartesianDimensions
    instanceDimensions?: ConstrainedCartesianDimensions
    appearance?: NodeAppearance
    input: PortType[]
    output: PortType[]
}

function defaultDimensions(type: ExecutableNodeType) {
    let pc = Math.max(type.input.length, type.output.length, 1);
    let height = pc * 12 + 12; //TODO: style sheet?
    let width = (Math.min(6, Math.max(2, pc)) + 1) * 12;
    return {width, height}
}

export function nodeTypeIconDimensions(type: ExecutableNodeType): CartesianDimensions {
    /*
    if (typeof type.label !== "string")
        if (type.label?.dimensions)
            return type.label?.dimensions;

    return nodeInstanceDimensions(type)

     */

    return defaultDimensions(type)
}

/*export function nodeTypeDimensions(type: ExecutableNodeType): CartesianDimensions {
    return type.dimensions || defaultDimensions(type)
}*/

export function nodeInstanceDimensions(type: ExecutableNodeType): CartesianDimensions {
    return type.instanceDimensions || defaultDimensions(type)
}

export type NodeType = ExecutableNodeType;

export interface TypeInstance<T> {
    type: T
}

export interface NodeTypePath {
    name: string
    package?: string
}

export type ExecutableNodeTypeInstance = TypeInstance<NodeTypePath>

export type NodeTypeDictionary = {[key: string]: NodeType}
export type NodeTypePackages = {[key: string]: NodeTypeDictionary}

export type NodeTypeConfigPackages = {[key: string]: {[key: string]: any}}

export function iteratePackages<T>(dictionary: {[key: string]: {[key: string]: T}}, fn: (key: string, pckg: string, object: T) => any) {
    for (const pckg in dictionary) {
        for (const key in dictionary[pckg]) {
            fn(key, pckg, dictionary[pckg][key]);
        }
    }
}

export function iteratePackagesIndexed<T>(dictionary: {[key: string]: {[key: string]: T}}, fn: (key: string, pckg: string, object: T, index: number, array: T[]) => any) {
    let array: [string, string, T][] = [];

    for (const pckg in dictionary) {
        for (const key in dictionary[pckg]) {
            array.push([key, pckg, dictionary[pckg][key]])
        }
    }

    let resultArray = array.map(i => i[2]);
    for (let i = 0; i < array.length; i++) {
        fn(array[i][0], array[i][1], array[i][2], i, resultArray);
    }
}