import {DebugDot} from "../../debug";
import {motion} from "framer-motion";
import React, {CSSProperties, forwardRef, ReactElement, ReactNode} from "react";
import {ButtonTypes, WindowControlButtonTypes} from "./FancyDialog";
import {Property} from "csstype";
import ReactDOM from "react-dom";
import {useStyle} from "../../../hooks";

export interface SectionProps {
    position?: 'top' | 'bottom' | 'fullHeight'
}

const measureElement = (text: string, style: CSSProperties) => {
    const container = document.createElement("div");
    const containerStyle = {
        display: "inline-block",
        position: "absolute",
        visibility: "hidden",
        zIndex: -1,
    };
    Object.assign(container.style, {...containerStyle, ...style});
    document.body.appendChild(container);

    ReactDOM.render(<TextWrapper text={text}/>, container);

    const height = container.clientHeight;
    const width = container.clientWidth;
    console.log("measured " + width);

    ReactDOM.unmountComponentAtNode(container);
    container.parentNode && container.parentNode.removeChild(container);
    return {width, height};
};

const TextWrapper = ({text} : {text: string}) => <div style={{display: "inline-block", width: 'auto', height: 'auto'}}>{text}</div>

const TextMeasurer = forwardRef<HTMLDivElement>((props: {children?: ReactNode, style?: CSSProperties}, ref) => {
    return <div style={{display: "inline-block", width: 'auto', height: 'auto', ...props.style}} ref={ref}>{props.children}</div>
})

function FancyButtonBar(props: SectionProps & {
    buttons: ButtonTypes | WindowControlButtonTypes
    buttonProps?: {[key: string]: {disabled?: boolean}}
    margin?: number
    buttonHeight?: number
    buttonWidth?: number | 'auto'
    spacing?: number
    borderRadius?: number
    callback?: (result: string) => void,
    simplify?: boolean,
    fontFamily: string,
    fontSize?: number,
    fontWeight?: number,
    color?: Property.Color,
    background?: Property.Background<string | number>
}) {
    const aw = props.buttonWidth === 'auto';
    const margin = props.margin || 16
    const bHeight = props.buttonHeight || 32
    const bWidth = !props.buttonWidth || props.buttonWidth === 'auto' ? 80 : props.buttonWidth;
    const bWidths = !aw ? props.buttons.map(() => bWidth) : props.buttons.map(b => measureElement(b, {
        fontFamily: props.fontFamily,
        fontSize: props.fontSize,
        fontWeight: props.fontWeight
    }).width);
    const bTotalWidth = bWidths.reduce((accumulator, current) => {
        return accumulator + current;
    }, 0);
    const bRad = bHeight / 2

    const spacing = props.spacing || 4
    const br = props.borderRadius || 10
    const bWidthAll = bTotalWidth + spacing * (props.buttons.length - 1)
    const overlap = 1
    const overlap2 = 2 * overlap
    const pos = props.position

    const to = pos === 'top' || pos === 'fullHeight' ? 0 : overlap
    const bo = pos === 'bottom' || pos === 'fullHeight' ? 0 : overlap

    const fontFamily = props.fontFamily
    const fontSize = props.fontSize || 14
    const fontWeight = props.fontWeight || 700
    const color = props.color
    const base = props.background
    
    const style = useStyle();

    return (<div style={{
        position: 'relative',
        height: bHeight + margin * 2 + (pos === undefined ? (pos === 'top' || pos === 'bottom' ? 1 * overlap : 0) : 2 * overlap),
        //borderRadius: '0 0 ' + cr + 'px ' + cr + 'px',
        overflow: 'hidden',
        marginTop: -to,
        marginBottom: -bo
    }}>
        <div style={{position: 'absolute', inset: 0}}>
            {props.simplify ? <div style={{
                position: 'absolute',
                inset: 0,
                background: base,
            }}><DebugDot/></div> : <>
                <div style={{
                    position: 'absolute',
                    left: 0,
                    bottom: margin + bHeight,
                    width: '100%',
                    height: margin + overlap + to,
                    background: base,
                }}/>
                <div style={{
                    position: 'absolute',
                    left: 0,
                    bottom: margin - overlap,
                    width: 'calc(100% - ' + (bWidthAll + margin) + 'px)',
                    height: bHeight + overlap * 2,
                    background: base,
                }}/>
                <div style={{
                    position: 'absolute',
                    right: 0,
                    bottom: margin - overlap,
                    width: margin,
                    height: bHeight + overlap * 2,
                    background: base,
                }}/>
                <div style={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    height: margin + bo,
                    background: base,
                }}/>
            </>}
            {props.buttons.map((s, i, a) => {
                const spacer = i > 0
                const io = overlap
                //const oh = 1.67, ot = 3.33
                const oh = 2.5, ot = 5

                let sumW = 0;
                for (let ii = 0; ii < i; ii++) sumW += bWidths[ii];
                let myW = bWidths[i];

                let disabled = props.buttonProps  && props.buttonProps[s] && props.buttonProps[s].disabled;

                return (
                    <div key={'button' + i + s} style={{
                        position: 'absolute',
                        right: margin + (bWidth + spacing) * (props.buttons.length - 1 - i), //margin + sumW + spacing * (props.buttons.length - 1 - i), //margin + (bWidth + spacing) * (props.buttons.length - 1 - i),
                        bottom: margin,
                        width: bWidth, //myW, //bWidth,
                        height: bHeight,
                        cursor: disabled ? undefined : 'pointer',
                        lineHeight: 0
                    }} onClick={(e) => {
                        if (props.callback) props.callback(s)
                    }} onMouseDown={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}>
                        {!props.simplify && <>
                            {spacer && spacing > 0 && <div style={{
                                position: 'absolute',
                                left: -spacing,
                                top: -overlap,
                                height: bHeight + overlap2,
                                width: spacing,
                                background: base
                            }}/>}
                            {/*<svg key='fancywindow-button-mask'
                                 style={{margin: -1}}
                                 viewBox={
                                     -overlap + ' ' +
                                     -overlap + ' ' +
                                     (bWidth + overlap2) + ' ' +
                                     (bHeight + overlap2)
                                 }
                                 fill={base as string}>
                                <defs>
                                    <mask id={uid}>
                                        <rect x={-overlap}
                                              y={-overlap}
                                              width={bWidth + overlap2}
                                              height={bHeight + overlap2}
                                              fill='white'/>
                                        <rect x={io} y={io}
                                              width={bWidth - io * 2}
                                              height={bHeight - io * 2}
                                              rx={br - io}
                                              fill='black'/>
                                    </mask>
                                </defs>
                                <rect x={-overlap}
                                      y={-overlap}
                                      width={bWidth + overlap2}
                                      height={bHeight + overlap2}
                                      mask={'url(#' + uid + ')'}/>
                            </svg>*/}
                            <div style={{
                                position: 'absolute',
                                overflow: 'hidden',
                                left: -overlap,
                                top: -overlap,
                                width: myW + overlap2,
                                height: bHeight + overlap2
                            }}>
                                <div style={{
                                    position: 'absolute',
                                    left: overlap - bRad,
                                    top: overlap - bRad,
                                    width: myW - 2,
                                    height: bHeight - 2,
                                    borderRadius: bRad * 2,
                                    //color: color,
                                    borderStyle: 'solid',
                                    borderWidth: bRad + 1,
                                    borderColor: base as string
                                }}/>
                            </div>
                            <div style={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                width: '200%',
                                height: '200%',
                                transform: 'scale(0.5) translate(-50%, -50%)',
                                pointerEvents: disabled ? 'none' : undefined
                            }}><motion.div
                                key='fancywindow-button'
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    bottom: 0,
                                    right: 0,
                                    background: base,
                                    borderRadius: 2 * br + 'px'
                                }}
                                whileHover={{
                                    left: 2 * (oh + io),
                                    top: 2 * (oh + io),
                                    bottom: 2 * (oh + io),
                                    right: 2 * (oh + io),
                                    opacity: .667,
                                    borderRadius: 2 * (br * (bHeight - oh * 2) / bHeight) + 'px'
                                }}
                                whileTap={{
                                    left: 2 * (oh + io),
                                    top: 2 * (oh + io),
                                    bottom: 2 * (oh + io),
                                    right: 2 * (oh + io),
                                    opacity: .333,
                                    borderRadius: 2 * (br * (bHeight - oh * 2) / bHeight) + 'px'
                                }}
                                transition={{ease: 'linear', duration: .25}}/></div>
                            <DebugDot/></>}
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            lineHeight: bHeight + 'px',
                            textAlign: 'center',
                            pointerEvents: 'none',
                            fontFamily,
                            fontSize,
                            fontWeight,
                            color: disabled ? style.palette.default.light : color
                        }}>
                            {(() => {
                                if (s === 'x') {
                                    return <svg
                                        style={{
                                            position: 'absolute',
                                            inset: (bWidth - 16) * .5,
                                            width: 16,
                                            height: 16
                                        }}
                                        key={'bb_x'}
                                        viewBox={'0 0 16 16'}
                                        stroke={color}
                                        strokeWidth={2}>
                                        <line x1='4' y1='4' x2='12' y2='12'/>
                                        <line x1='4' y1='12' x2='12' y2='4'/>
                                    </svg>;
                                } else if (s === '-') {
                                    return <svg
                                        style={{
                                            position: 'absolute',
                                            inset: (bWidth - 16) * .5,
                                            width: 16,
                                            height: 16
                                        }}
                                        key={'bb_min'}
                                        viewBox={'0 0 16 16'}
                                        stroke={color}
                                        strokeWidth={2}>
                                        <line x1='3' y1='8' x2='13' y2='8'/>
                                    </svg>;
                                } else {
                                    return s;
                                }
                            })()}
                        </div>
                    </div>)
            })}
        </div>
    </div>)
}

export default FancyButtonBar;
export const MemoButtonBar = React.memo(FancyButtonBar, () => true)