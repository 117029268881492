import {NodeTypeConfigPackages, NodeTypePackages} from "../types/nodeTypes";
import {Failable, Warnings} from "../../store/nodes";
import {objectMap} from "../utils";

export interface NodeState {
    state?: string
}

export type Data = {value: string, type: string} | null
export type NodeTypePath = {name: string, package?: string}
export type StateNodeData<D = Data, S = {}> = D & S | null;
export type ExecutableNodeData<D = Data, S = {}> = S & {
    input: D[]
    output: D[]
    type?: NodeTypePath
}

export type NodeData<D = Data, S = {}> = StateNodeData<D, S> | ExecutableNodeData<D, S>;

export type DataUpdate = {data?: Data, warnings?: Warnings}
export type StateNodeDataUpdate = DataUpdate;
export interface ExecutableNodeDataUpdate extends NodeState, Failable {
    input?: {[index: number]: DataUpdate}
    output?: {[index: number]: DataUpdate}
}

export type NodeDataUpdate = StateNodeDataUpdate | ExecutableNodeDataUpdate;

export interface ConnectionData {
    from?: number
    to: string | [string, number]
}

export interface StackData {
    next: string
}

export interface BlockData {
    children: string[]
}

export type LinkData = ConnectionData | StackData | BlockData;

export function isConnectionData(arg: LinkData): arg is ConnectionData {
    return "to" in arg && arg.to !== undefined;
}

export function isStackData(arg: LinkData): arg is StackData {
    return "next" in arg && arg.next !== undefined;
}

export function isBlockData(arg: LinkData): arg is BlockData {
    return "children" in arg && arg.children !== undefined;
}

export function iterateNodes(
    nodes: {[id: string]: NodeData},
    exnfn: (node: ExecutableNodeData, key: string) => any,
    stnfn: (node: StateNodeData, key: string) => any
) {
    Object.keys(nodes).forEach(key => {
        let node = nodes[key];
        if (node === null || "value" in node) {
            stnfn(node, key);
        } else {
            exnfn(node, key);
        }
    });
}

export function iterateNodesCustom<D, S>(
    nodes: {[id: string]: NodeData<D, S>},
    exnfn: (node: ExecutableNodeData<D, S>, key: string) => any,
    stnfn: (node: StateNodeData<D, S>, key: string) => any,
    isStateNode: (node: NodeData<D, S>) => node is StateNodeData<D, S>
) {
    Object.keys(nodes).forEach(key => {
        let node = nodes[key];
        if (isStateNode(node)) {
            stnfn(node, key);
        } else {
            exnfn(node, key);
        }
    });
}

export function iterateNodeUpdates(
    nodes: {[id: string]: NodeDataUpdate},
    exnfn: (node: ExecutableNodeDataUpdate, key: string) => any,
    stnfn: (node: StateNodeDataUpdate, key: string) => any
) {
    Object.keys(nodes).forEach(key => {
        let node = nodes[key];
        if (node === null || "value" in node) {
            stnfn(node, key);
        } else {
            exnfn(node, key);
        }
    });
}

export function mapNodes<D, S>(
    nodes: {[id: string]: NodeData},
    exnfn: (node: ExecutableNodeData, key: string) => ExecutableNodeData<D, S>,
    stnfn: (node: StateNodeData, key: string) => StateNodeData<D, S>
): {[id: string]: NodeData<D, S>} {
    return objectMap(nodes, (node, key) => {
        if (node === null || "value" in node) {
            return stnfn(node, key);
        } else {
            return exnfn(node, key);
        }
    });
}

export function mapNode<D, S>(
    node: NodeData,
    exnfn: (node: ExecutableNodeData) => ExecutableNodeData<D, S>,
    stnfn: (node: StateNodeData) => StateNodeData<D, S>
): NodeData<D, S> {
    if (node === null || "value" in node) {
        return stnfn(node);
    } else {
        return exnfn(node);
    }
}

export function mapData<D>(
    node: NodeData,
    fn: (data: Data) => D
): NodeData<D> {
    if (node === null || "value" in node) {
        return fn(node);
    } else {
        let input = node.input.map(fn)
        let output = node.output.map(fn)
        return {...node, input, output};
    }
}

export function mapData2<D>(
    node: NodeData,
    stnfn: (data: Data) => D,
    exnfn: (data: Data, direction: 'in' | 'out', index: number) => D
): NodeData<D> {
    if (node === null || "value" in node) {
        return stnfn(node);
    } else {
        let input = node.input.map((d, i) => exnfn(d, 'in', i))
        let output = node.output.map((d, i) => exnfn(d, 'out', i))
        return {...node, input, output};
    }
}

export interface MachineConfiguration {
    executables?: NodeTypePackages
    controllers?: NodeTypeConfigPackages
}

export type NodesState<D = Data, S = {}> = { [id: string]: NodeData<D, S> };
export type LinksState = {[id: string]: LinkData[]};
export interface DefinitionState<D = Data, S = {}> {
    nodes?: NodesState<D, S>
    links?: LinksState
}

export type NodesStateUpdate = { [id: string]: NodeDataUpdate };
export interface DefinitionUpdate {
    nodes?: NodesStateUpdate
    links?: LinksState
}

export interface MachineUpdate {
    add?: DefinitionState
    update?: DefinitionUpdate
    remove?: {
        nodes?: string[]
        links?: LinksState
    }
}

export type MachineDefinitionState = DefinitionState<{data?: Data, warnings?: Warnings}, {info?: any, state?: string}>;

abstract class Machine {
    public abstract init(
        nodes?: NodesState,
        links?: LinksState
    ): Promise<MachineConfiguration>;

    public abstract update(
        update: MachineUpdate
    ): Promise<MachineDefinitionState>
}

export default Machine;