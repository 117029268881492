import {
    AddControllerAction,
    AddNodeAction, ClearNodeSearchConnectionsAction, ComposeAction,
    ConnectAction, Connection,
    ConnectionLine,
    Connector, DecomposeAction,
    DisconnectAction,
    HoverNodeAction,
    HoverPortAction, MachineState,
    Mode, MoveConnectionLineToNodeSearchAction,
    Node,
    NodesActionType, NodesChanges, NodesUpdate,
    NodeUpdate,
    NodeUpdateOptions,
    PortPosition, RemoveControllerAction,
    RemoveNodeAction,
    SetConnectionLineAction, SetMachineStateAction,
    SetModeAction,
    UnhoverNodeAction,
    UnhoverPortAction,
    Unique,
    UpdateConnectionLineAction, UpdateControllerAction,
    UpdateNodeAction, UpdateNodesAction,
    UpdateStateAction, Warnings
} from "./types";
import {Data, DefinitionState, MachineDefinitionState, NodeData} from "../../common/machine/Machine";

export const addController = (controller: Unique & {state: any}): AddControllerAction => ({
    type: NodesActionType.ADDCONTROLLER,
    payload: controller
});

export const removeController = (controller: Unique): RemoveControllerAction => ({
    type: NodesActionType.REMOVECONTROLLER,
    payload: controller
});

export const updateController = (controller: Unique & {state: any}): UpdateControllerAction => ({
    type: NodesActionType.UPDATECONTROLLER,
    payload: controller
});

export const addNode = (node: Node): AddNodeAction => ({
    type: NodesActionType.ADDNODE,
    payload: node
});

export const removeNode = (node: Unique): RemoveNodeAction => ({
    type: NodesActionType.REMOVENODE,
    payload: node
});

export const updateNode = (update: NodeUpdate, options?: NodeUpdateOptions): UpdateNodeAction => ({
    type: NodesActionType.UPDATENODE,
    payload: {update, options}
});

export const updateNodes = (update: NodesUpdate): UpdateNodesAction => ({
    type: NodesActionType.UPDATENODES,
    payload: update
});

export const hoverNode = (node?: Unique): HoverNodeAction => ({
    type: NodesActionType.HOVERNODE,
    payload: node
});

export const unhoverNode = (node: Unique): UnhoverNodeAction => ({
    type: NodesActionType.UNHOVERNODE,
    payload: node
});

export const hoverPort = (port: Unique & PortPosition): HoverPortAction => ({
    type: NodesActionType.HOVERPORT,
    payload: port
});

export const unhoverPort = (port: Unique & PortPosition): UnhoverPortAction => ({
    type: NodesActionType.UNHOVERPORT,
    payload: port
});

export const connect = (from: Connector, to: Connector, level?: number): ConnectAction => ({
    type: NodesActionType.CONNECT,
    payload: {from, to, level}
});

export const disconnect = (from: Connector, to: Connector): DisconnectAction => ({
    type: NodesActionType.DISCONNECT,
    payload: {from, to}
});

export const compose = (parent: string, child: string): ComposeAction => ({
    type: NodesActionType.COMPOSE,
    payload: {parent, child}
});

export const decompose = (node: string): DecomposeAction => ({
    type: NodesActionType.DECOMPOSE,
    payload: node
});

export const setMode = (mode: Mode): SetModeAction => ({
    type: NodesActionType.SETMODE,
    payload: mode
});

export const setConnectionLine = (line: ConnectionLine | undefined): SetConnectionLineAction => ({
    type: NodesActionType.SETCONNECTIONLINE,
    payload: line
});

export const updateConnectionLine = (line: Partial<ConnectionLine>): UpdateConnectionLineAction => ({
    type: NodesActionType.UPDATECONNECTIONLINE,
    payload: line
});

export const moveConnectionLineToNodeSearch = (): MoveConnectionLineToNodeSearchAction => ({
    type: NodesActionType.MOVECONNECTIONLINETONODESEARCH
});

export const clearNodeSearchConnections = (): ClearNodeSearchConnectionsAction => ({
    type: NodesActionType.CLEARNODESEARCHCONNECTIONS
});

export const updateState = (state: MachineDefinitionState): UpdateStateAction => ({
    type: NodesActionType.UPDATESTATE,
    payload: state
});

export const setMachineState = (state: MachineState): SetMachineStateAction => ({
    type: NodesActionType.SETMACHINESTATE,
    payload: state
});