import {
    FancyColor,
    FancyNodeStyleSheet,
    FancyStyleSheet,
    interpolateLightAndGhost,
    mergeNodeStyles, mergeStyles,
    NodeStyleSheet
} from "./types";

let _scale = 2;

export const rfScale = (x: number) => {
    return x * _scale
}
export const rfCheckedScale = (x: number) => {
    return x ? x * _scale : undefined
}
export const blendIn = [.4, .5]
export const defaultNodeStyle: FancyNodeStyleSheet = {
    components: {
        Node: {
            width: rfScale(32),
            strokeWidth: rfScale(2),
            background: 'linear-gradient(135deg, rgba(255,255,255,1.0) 20%, rgba(240,242,246,0.88)', //background: 'transparent',
            //backdropFilter: 'blur(' + rfScale(2) + 'px)',
            color: '#d8e2ec',
            textColor: '#000',
            accentColor: '#aebcca',
            border: 'solid ' + rfScale(2) + 'px #d8e2ec',
            padding: 0,
            cornerRadius: rfScale(8),
            cornerRadiusStack: rfScale(3),
            showAugmentations: true,
            showLabels: true,
            '&:hover': {
                background: 'linear-gradient(135deg, rgba(255,255,255,0.82) 20%, rgba(240,242,246,0.68)'
            },
            disabled: {
                //color: '#e2e8ee80',
                textColor: '#aebcca'
            },
            custom: {
                solid: {
                    gradient: ['#0d0', '#3e3', '#6f6', '#9f9', '#dfd'],
                    opacity: .88,
                    color: '#0b0',
                    accentColor: '#0b0'
                }
            }
        },

        Block: {
            width: rfScale(32),
            strokeWidth: rfScale(2),
            background: 'linear-gradient(135deg, rgba(255,255,255,.25) 20%, rgba(240,242,248,.25) 100%)',
            backdropFilter: 'blur(' + rfScale(2) + 'px)',
            color: '#cdd2d740',
            textColor: '#000',
            accentColor: '#9ba5af',
            border: 'solid ' + rfScale(2) + 'px',
            padding: 0,
            cornerRadius: rfScale(10),
            cornerRadiusStack: rfScale(5),
            showAugmentations: true,
            showLabels: true
        },

        Edge: {
            strokeWidth: rfScale(2),
            color: '#000',
            disabled: {
                color: '#aebcca'
            }
        },

        Port: {
            size: rfScale(5.6),
            strokeWidth: rfScale(1.35),
            spacing: rfScale(12),
            margin: rfScale(12),
            align: 'top',
            color: '#000',
            colorValue: '#000',
            colorEmpty: '#fff',
            disabled: {
                colorValue: '#aebcca'
            }
        },

        Label: {},

        Slider: {
            railColor: '#b8c6d4'
        },

        Input: {
            color: '#000',
            background: 'rgba(227,230,234,0.25)'
        }
    },

    palette: {
        highlight: interpolateLightAndGhost('#007fff'), //#007fff
        warning: {
            0: interpolateLightAndGhost('#ffdd00'),
            1: interpolateLightAndGhost('#ff2200'),
            2: interpolateLightAndGhost('#ff0022')
        },
        default: interpolateLightAndGhost('#80a0c0')
    },

    typography: {
        fontFamily: 'Georgia',
        fontSize: rfScale(16),
        fontWeight: 700
    },

    highlightWidth: rfScale(8),

    connectorWidth: rfScale(2),
    connectorSize: rfScale(2),

    background: '#fff',

    shadow: {
        offsetX: rfScale(3),
        offsetY: rfScale(3),
        blurRadius: rfScale(12),
        spreadRadius: rfScale(0),
        color: '#00408080', //0, 55, 110, 0.38)',
        disabledColor: '#00408080',
        background: 'transparent'
    }
}

export const defaultNodeStyleDark = mergeNodeStyles({
    components: {
        Node: {
            background: 'linear-gradient(135deg, rgba(78,84,100,1.0) 20%, rgba(64,68,78,.8) 100%)', //'linear-gradient(135deg, rgba(58,64,80,1.0) 20%, rgba(44,48,58,.8) 100%)'
            //backgroundImage: 'url(presets/style/dark_gradient.png)',
            color: '#5e6678',
            accentColor: '#7a8498',
            '&:hover': {
                background: 'linear-gradient(135deg, rgba(78,84,100,0.82) 20%, rgba(64,68,78,0.68)'
            },
            custom: {
                solid: {
                    gradient: ['rgba(58,120,80)', 'rgba(48,150,65)', 'rgba(38,180,50)', 'rgba(28,210,35)', 'rgba(18,240,20)'], //['rgba(58,115,80)', 'rgba(48,145,65)', 'rgba(38,175,50)', 'rgba(28,215,35)', 'rgba(18,255,20)'],
                    opacity: .75,
                    color: '#0f0',
                    accentColor: '#00ff0088'
                }
            },
            disabled: {
                //color: '#e2e8ee',
                textColor: '#5e6678'
            },
        },

        Block: {
            color: '#4d546240',
            background: 'linear-gradient(315deg, rgba(44,48,58,.25) 20%, rgba(58,64,80,.25) 100%)'
        },

        Edge: {
            color: '#b4c8dc',
            disabled: {
                color: '#7a8498'
            }
        },

        Port: {
            color: '#b4c8dc',
            colorValue: '#b4c8dc',
            colorEmpty: '#000',
            disabled: {
                colorValue: '#7a8498'
            }
        },

        Slider: {
            railColor: '#b4c8dc'
        },

        Input: {
            color: '#b4c8dc',
            background: 'rgba(0, 0, 0, 0.125)'
        }
    },

    palette: {
        default: interpolateLightAndGhost('#b4c8dc')
    },

    background: '#21242e',

    shadow: {
        color: 'rgba(0, 0, 0, 0.667)',
        disabledColor: 'rgba(0, 0, 0, 0.667)',
        background: 'rgba(44, 48, 58, 0.75)'
    }
}, defaultNodeStyle);

//const base = dark ? 'rgb(55,63,77)' : '#ffffff' //'#ffffff'
//const baseOut = dark ? 'rgba(55,63,77,0.25)' : 'rgba(255,255,255,0.25)' //'#ffffff'
//const color = dark ? '#fff' : '#000'

export const defaultStyle: FancyStyleSheet = {
    window: {
        background: '#ffffff',
        transparentBackground: 'rgba(240, 242, 246, 0.96)',
        color: '#000',
        padding: 16,
        minWidth: 360,
        maxWidth: 800,
        defaultWidth: 480,
        fontFamily: 'Open Sans',
        fontSize: 13,
        fontWeight: 400,
        windowControlButtonSize: 20,
        windowControlBarPadding: 6,
        windowControlButtonSpacing: 2
    },
    shadow: {
        offsetX: 8,
        offsetY: 8,
        blurRadius: 72,
        spreadRadius: -12,
        color: '#00408080'
    },
    palette: {
        highlight: interpolateLightAndGhost('#007fff'),
        default: interpolateLightAndGhost('#80a0c0'),
        background: interpolateLightAndGhost('#004080', .32, .18, .06),
    },
    mascot: {
        eyes: {
            color: interpolateLightAndGhost('#007fff', .45, .25, .125),
            shadow: interpolateLightAndGhost('#607890', .24, .12, .06),
            iris: interpolateLightAndGhost('#90e0ff', .45, .35, .25),
            pupil: "#000000",
            reflection: "#ffffff80"
        },
        background: ["#00408020", "#00408060"]
    }
}

export const defaultStyleDark = mergeStyles({
    window: {
        background: 'rgb(55 ,63, 77)',
        transparentBackground: 'rgba(42, 50, 64, .92)',
        color: '#fff'
    },
    shadow: {
        color: 'rgba(18, 21, 26)'
    },
    palette: {
        background: interpolateLightAndGhost('#000408', .6, .4, .2),
    },
    mascot: {
        eyes: {
            color: interpolateLightAndGhost('#409fff', .75, .375, .19),
            shadow: interpolateLightAndGhost('#80b0ff', .24, .12, .06),
            iris: interpolateLightAndGhost('#60d0ff', .32, .24, .16),
            pupil: "#000000",
            reflection: "#ffffff50"
        },
        background: ["#80a0c0", "#80a0c080"]
    }
}, defaultStyle);