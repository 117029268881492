export enum DECREASE {
    LINEAR, BELL, SQUAREBELL, SQRTBELL, FADEOUTBELL
}

export type BlendMethod = 'add' | 'max'

export class Point {
    protected _x: number
    protected _y: number

    constructor(x: number, y: number) {
        this._x = x;
        this._y = y;
    }
}

export default class Attractor extends Point {
    public strength
    public fromRadius
    public toRadius

    public decrease: DECREASE = DECREASE.LINEAR;

    constructor(x: number, y: number, v: number, fromRadius = 10, toRadius = 60) {
        super(x, y);
        this.strength = v;
        this.fromRadius = fromRadius;
        this.toRadius = toRadius;
    }

    public getValue(x2: number, y2: number, scaleDist: number = 1) {
        let xx = this._x - x2;
        let yy = this._y - y2;
        let d = Math.sqrt(xx * xx + yy * yy) * scaleDist;
        d -= this.fromRadius;
        if (d < 0) return this.strength;

        if (this.decrease == DECREASE.BELL) {
            let dd = d / (this.toRadius - this.fromRadius) * 2.0;
            let vv = Math.pow(0.5, dd * dd);
            return this.strength * vv;

        } else if (this.decrease == DECREASE.SQUAREBELL) {
            let dd = d / (this.toRadius - this.fromRadius) * 2.0;
            let vv = Math.pow(0.5, dd * dd);
            return this.strength * (vv * vv);

        } else if (this.decrease == DECREASE.SQRTBELL) {
            let dd = d / (this.toRadius - this.fromRadius) * 2.0;
            let vv = Math.pow(0.5, dd * dd);
            return this.strength * Math.sqrt(vv);

        } else if (this.decrease == DECREASE.FADEOUTBELL) {
            let dd = d / (this.toRadius - this.fromRadius) * 2.0;
            let vv = Math.sin(Math.PI / (2 * dd + 2));//java.lang.Math.pow(0.5, dd * dd);
            vv *= vv;
            vv *= 1.05;
            vv -= 0.05;
            if (vv < 0) return 0; //vv = 0;
            return this.strength * vv;

        } else {
            if (d > this.toRadius - this.fromRadius) return .0;
            return this.strength * (1.0 - d / (this.toRadius - this.fromRadius));
        }
    }

    public static getValue(x: number, y: number, aa: Attractor[], scaleDist: number = 1, method: BlendMethod = 'add'): number {
        let v = 0;
        aa.forEach(a => {
            if (method == 'add')
                v += a.getValue(x, y, scaleDist);
            else {
                let vv = a.getValue(x, y, scaleDist);
                if (vv > v) v = vv;
            }
        });

        if (v < 0) return 0;
        if (v > 1) return 1.0;
        else return v;
    }
}