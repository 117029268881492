import {combineReducers, createStore} from "redux";
import {dialog, DialogState, transform} from "./workspace";
import {TypedUseSelectorHook, useSelector} from "react-redux";
import {nodes, NodesState} from "./nodes";
import {input, InputState, keyDown, keyUp} from "./input";
import {ZoomTranslateState} from "../components/workspace/ZoomTranslate";

const reducer = combineReducers({
    dialog, nodes, input, transform
});

export interface State {
    dialog: DialogState
    transform: ZoomTranslateState
    nodes: NodesState
    input: InputState
}

export const configureStore = (initialState?: State) =>
    createStore(
        reducer,
        initialState,
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    );

const store = configureStore();

const handleDown = (event: KeyboardEvent) => store.dispatch(keyDown(event.key));
const handleUp = (event: KeyboardEvent) => store.dispatch(keyUp(event.key));

window.addEventListener('keydown', handleDown);
window.addEventListener('keyup', handleUp);

export default store;

export const useTypedSelector: TypedUseSelectorHook<State> = useSelector

export const useKeyPressed = (key: string) => useTypedSelector(state => state.input.keyPressed[key]);