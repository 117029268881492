import { motion } from "framer-motion";
import React from "react";
import {_DEBUG} from "../../common/dev";

export function DebugDot() {
    const h = Math.floor(Math.random() * 360)
    return _DEBUG ? <Dot x={4} y={4} sz={4} color={'hsl(' + h + ', 100%, 50%)'}/> : null
}

export function Dot(props: {x: number, y: number, color?: string, sz?: number}) {
    const sz = props.sz || 6
    const szh = sz / 2
    const color = props.color || '#00ffff'

    return <div style={{
        position: 'absolute',
        left: props.x - szh,
        top: props.y - szh,
        width: sz,
        height: sz,
        background: color,
        borderRadius: '50%'
    }}/>
}

export function FadeDot(props: {x: number, y: number, sz?: number}) {
    const sz = props.sz || 6
    const szh = sz / 2

    return <motion.div style={{
        position: 'absolute',
        left: props.x - szh,
        top: props.y - szh,
        width: sz,
        height: sz,
        background: '#00ffff',
        borderRadius: '50%',
        opacity: 1.0
    }} animate={{
        opacity: .0
    }} transition={{
        ease: 'linear',
        duration: 1.0
    }}/>
}

export function Cross(props: {x: number, y: number, color?: string, sz?: number}) {
    const sz = props.sz || 8
    const szh = sz / 2
    const color = props.color || '#00ffff'

    return <div style={{
        position: 'absolute',
        left: props.x,
        top: props.y,
        width: 0,
        height: 0
    }}>
        <div style={{position: 'absolute', left: -szh, top: -1, height: 2, width: sz, background: color}}/>
        <div style={{position: 'absolute', left: -1, top: -szh, height: sz, width: 2, background: color}}/>
    </div>
}
