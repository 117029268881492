import React, {ComponentClass, FunctionComponent, ReactNode, SVGProps, useEffect, useState} from "react";
import {useStyle} from "../../hooks";
import KeyboardIcon from '@mui/icons-material/Keyboard';
import {SolidNodeStyle} from "../style";
import {SvgIcon, SvgIconProps} from "@mui/material";

const fancyIconStyle = {
    x: 0,
    y: 0,
    width: '100%',
    height: '100%',
    viewBox: '0 0 100 100'
}

const fancyIconStyleWide2 = {
    x: 0,
    y: 0,
    style: {transform: 'translateX(-16.67%)'}, //{transform: 'translateX(-14.29%)'},
    width: '150%', //'140%',
    height: '100%',
    viewBox: '-25 0 150 100'
}

const fancyIconStyleWide = {
    x: 0,
    y: 0,
    style: {transform: 'translateX(-8.3333%)'}, //{transform: 'translateX(-14.29%)'},
    width: '120%', //'140%',
    height: '100%',
    viewBox: '-10 0 120 100'
}

const strokeStyle = {
    strokeWidth: 5
}

const strokeStyleBold = {
    strokeWidth: 15
}

export const ExceptionIcon = (props: SVGProps<SVGSVGElement>) => <svg
    {...fancyIconStyle}
    {...props}>
    <g>
        <path fill={props.color} stroke="none" d="M51.4,59.9h-2.7c-0.3-3.9-1.2-8.6-2.8-14.2l-3.2-11.2c-1.9-6.7-2.9-11.5-2.9-14.4c0-2.6,0.9-4.8,2.8-6.4
		c1.9-1.6,4.4-2.4,7.4-2.4c3,0,5.4,0.8,7.4,2.4s2.9,3.7,2.9,6.2c0,2.6-1,7.5-3.1,14.6l-3.3,11.2C52.8,49.7,51.9,54.4,51.4,59.9z
		 M50.1,68.7c2.8,0,5.1,1,7.1,2.9c2,2,2.9,4.3,2.9,7.1c0,2.8-1,5.1-2.9,7.1c-2,2-4.3,2.9-7.1,2.9c-2.8,0-5.1-1-7.1-2.9
		s-2.9-4.3-2.9-7.1s1-5.1,2.9-7.1S47.3,68.7,50.1,68.7z"/>
    </g>
</svg>

export const InfoIcon = (props: SVGProps<SVGSVGElement>) => <div style={{
    width: props.width,
    height: props.height,
    fontSize: (props.height as number) * .92,
    textAlign: 'center',
    color: props.color,
    fontFamily: 'Georgia',
    fontWeight: 800,
    lineHeight: (props.height as number) + 'px'
}}>i</div>

export const ErrorIcon = (props: SVGProps<SVGSVGElement>) => <svg
    {...fancyIconStyle}
    {...props}>
    <g>
        <line fill="none" stroke={props.color} strokeWidth={12} strokeMiterlimit={10} x1={27.5} y1={27.5} x2={72.5}
              y2={72.5}/>
        <line fill="none" stroke={props.color} strokeWidth={12} strokeMiterlimit={10} x1={72.5} y1={27.5} x2={27.5}
              y2={72.5}/>
    </g>
</svg>

export const PlusIcon = (props: SVGProps<SVGSVGElement>) => <svg
    {...fancyIconStyle}
    {...props}>
    <line {...strokeStyle} stroke={props.color} x1={25} y1={50} x2={75} y2={50}/>
    <line {...strokeStyle} stroke={props.color} x1={50} y1={25} x2={50} y2={75}/>
</svg>

const PLusIconSmallLines = ({x, y, size, ...props}: {x: number, y: number, size: number} & SVGProps<SVGSVGElement>) => <>
    <line {...strokeStyle} stroke={props.stroke} x1={x + 50 - size} y1={y + 50} x2={x + 50 + size} y2={y + 50}/>
    <line {...strokeStyle} stroke={props.stroke} x1={x + 50} y1={y + 50 - size} x2={x + 50} y2={y + 50 + size}/>
</>

export const PlusSmallIcon = (props: SVGProps<SVGSVGElement>) => <svg
    {...fancyIconStyle}
    {...props}>
    <PLusIconSmallLines {...strokeStyle} stroke={props.color} x={0} y={0} size={20}/>
</svg>

export const IncrementIcon = (props: SVGProps<SVGSVGElement>) => <svg
    {...fancyIconStyle}
    {...props}>
    <PLusIconSmallLines {...strokeStyle} stroke={props.color} x={-22} y={0} size={16}/>
    <PLusIconSmallLines {...strokeStyle} stroke={props.color} x={22} y={0} size={16}/>
</svg>

export const MinusIcon = (props: SVGProps<SVGSVGElement>) => <svg
    {...fancyIconStyle}
    {...props}>
    <line {...strokeStyle} stroke={props.color} x1={25} y1={50} x2={75} y2={50}/>
</svg>

export const MinusSmallIcon = (props: SVGProps<SVGSVGElement>) => <svg
    {...fancyIconStyle}
    {...props}>
    <line {...strokeStyle} stroke={props.color} x1={30} y1={50} x2={70} y2={50}/>
</svg>

export const DecrementIcon = (props: SVGProps<SVGSVGElement>) => <svg
    {...fancyIconStyle}
    {...props}>
    <line {...strokeStyle} stroke={props.color} x1={34 - 22} y1={50} x2={66 - 22} y2={50}/>
    <line {...strokeStyle} stroke={props.color} x1={34 + 22} y1={50} x2={66 + 22} y2={50}/>
</svg>

export const MultiplyIcon = (props: SVGProps<SVGSVGElement>) => <svg
    {...fancyIconStyle}
    {...props}>
    <line {...strokeStyle} stroke={props.color} x1={32.5} y1={32.5} x2={67.5} y2={67.5}/>
    <line {...strokeStyle} stroke={props.color} x1={32.5} y1={67.5} x2={67.5} y2={32.5}/>
</svg>

export const DivideIcon = (props: SVGProps<SVGSVGElement>) => <svg
    {...fancyIconStyle}
    {...props}>
    <line {...strokeStyle} stroke={props.color} x1={25} y1={50} x2={75} y2={50}/>
    <circle fill={props.color} cx={50} cy={32} r={5}/>
    <circle fill={props.color} cx={50} cy={68} r={5}/>
</svg>

export const LogicalOrIcon = (props: SVGProps<SVGSVGElement>) => <svg
    {...fancyIconStyle}
    {...props}>
    <path {...strokeStyle} stroke={props.color} fill={'transparent'} d={"M33,28.5L50,68.5L67,28.5"}/>
</svg>

export const LogicalAndIcon = (props: SVGProps<SVGSVGElement>) => <svg
    {...fancyIconStyle}
    {...props}>
    <path {...strokeStyle} stroke={props.color} fill={'transparent'} d={"M33,71.5L50,31.5L67,71.5"}/>
</svg>

export const GreaterIcon = (props: SVGProps<SVGSVGElement>) => <svg
    {...fancyIconStyle}
    {...props}>
    <path {...strokeStyle} stroke={props.color} fill={'transparent'} d={"M28.5,33L68.5,50L28.5,67"}/>
</svg>

export const GreaterOrEqualIcon = (props: SVGProps<SVGSVGElement>) => <svg
    {...fancyIconStyle}
    {...props}>
    <path {...strokeStyle} stroke={props.color} fill={'transparent'} d={"M28.5,28L68.5,45L28.5,62"}/>
    <line {...strokeStyle} stroke={props.color} x1={28.5} y1={72} x2={71.5} y2={72}/>
</svg>

export const LessIcon = (props: SVGProps<SVGSVGElement>) => <svg
    {...fancyIconStyle}
    {...props}>
    <path {...strokeStyle} stroke={props.color} fill={'transparent'} d={"M71.5,33L31.5,50L71.5,67"}/>
</svg>

export const LessOrEqualIcon = (props: SVGProps<SVGSVGElement>) => <svg
    {...fancyIconStyle}
    {...props}>
    <path {...strokeStyle} stroke={props.color} fill={'transparent'} d={"M71.5,28L31.5,45L71.5,62"}/>
    <line {...strokeStyle} stroke={props.color} x1={28.5} y1={72} x2={71.5} y2={72}/>
</svg>

export const ToggleOffIcon = (props: SVGProps<SVGSVGElement>) => {
    let sz = 40 * .7 * .9;
    let f = 50 - sz;
    let t = 50 + sz;

    return <svg
        {...fancyIconStyle}
        {...props}>
        <line {...strokeStyleBold} stroke={props.color} x1={f} y1={f} x2={t} y2={t}/>
        <line {...strokeStyleBold} stroke={props.color} x1={f} y1={t} x2={t} y2={f}/>
    </svg>
}

export const ToggleOnIcon = (props: SVGProps<SVGSVGElement> & {scale?: number}) => {
    let sz = (props.scale ?? 1) * .9;

    let x = [50 - 35 * sz, 50 - 10 * sz, 50 + 35 * sz];
    let y = [50 - 3 * sz, 50 + 22 * sz, 50 - 23 * sz];

    return <svg
        {...fancyIconStyle}
        {...props}>
        <path
            {...strokeStyleBold}
            stroke={props.color}
            fill={'transparent'}
            d={`M ${x[0]},${y[0]} L ${x[1]},${y[1]} L ${x[2]},${y[2]}`}
        />
    </svg>
}

export const ToggleIcon = () => {
    const style = useStyle();
    return <ToggleOnIcon scale={.8} color={style.palette.default.light}/>
}

export const SliderIcon = (props: SVGProps<SVGSVGElement>) => {
    const style = useStyle();
    let railColor = style.components.Slider.railColor;
    let knobColor = style.palette.highlight.main;

    return <svg
        {...fancyIconStyleWide}
        {...props}>
        <line strokeWidth={10} stroke={railColor} strokeLinecap={"round"} x1={5} y1={50} x2={95} y2={50}/>
        <line strokeWidth={16} stroke={knobColor} strokeLinecap={"round"} x1={5} y1={50} x2={50} y2={50}/>
        <circle fill={'#00204040'} cx={50} cy={52} r={22}/>
        <circle fill={knobColor} cx={50} cy={50} r={20}/>
    </svg>
}

export const TextAreaIcon = (props: SVGProps<SVGSVGElement>) => {
    const style = useStyle();
    let background = style.components.Input.background;
    let fill = style.components.Input.color;
    let color = style.palette.default.main;

    return <svg
        {...fancyIconStyleWide}
        {...props}>
        <rect x={-10} y={15} width={120} height={70} fill={background}/>
        <text x={0} y={68} style={{
            fill,
            fontFamily: 'Roboto Mono',
            fontSize: 48,
            fontWeight: 'bold',
        }}>abc</text>
        <line {...strokeStyle} stroke={color} x1={92} y1={20} x2={92} y2={80}/>
    </svg>
}

export const PackageIcon = (props: SVGProps<SVGSVGElement>) => {
    return <svg
        {...fancyIconStyle}
        {...props}>
        <polygon fill="#D7AF87" points="50,50 50,100 91.665,75 91.665,25 "/>
        <polygon fill="#E2C8B0" points="50,50 50,100 8.334,75 8.334,25 "/>
        <polygon fill="#EDDDCE" points="8.334,25 50,50 91.665,25 50,0 "/>
        <polygon fill="#A0754D" points="64.5,62.834 50,100 91.665,75 78.75,55.584 "/>
        <polygon fill="#D7AF87" points="70.167,58.834 50,100 91.665,75 72.833,57.334 "/>
        <polygon fill="#A0754D" points="50,50 64.417,66.75 66,64.667 70.917,68.084 74.542,66.334 77.834,59.25 79.417,58 91.665,25 "/>
        <polygon fill="#D7AF87" points="50,50 71.167,66.333 73.667,64.999 91.665,25 "/>
        <polygon fill="#FFFFFF" points="27.07,32.599 50.224,42.283 62.784,30.063 39.631,20.379 "/>
    </svg>
}

export const WaitingIconAnimated = (props: SVGProps<SVGSVGElement>) => {
    const [x, setX] = useState<number>(0);

    const fps = 30;
    const duration = 800;
    const current = () => new Date().getTime();

    useEffect(() => {
        const interval = setInterval(() => {
            setX((current() % duration) / duration * Math.PI * 2);
        }, 1000 / fps);
        return () => clearInterval(interval);
    }, []);

    return <svg
        {...fancyIconStyle}
        {...props}>
        <circle fill={props.color} cx={25} cy={50} r={Math.max(0, Math.sin(x)) * 5 + 5}/>
        <circle fill={props.color} cx={50} cy={50} r={Math.max(0, Math.sin(x - Math.PI / 3)) * 5 + 5}/>
        <circle fill={props.color} cx={75} cy={50} r={Math.max(0, Math.sin(x - Math.PI / 3 * 2)) * 5 + 5}/>
    </svg>
}

export const KeyEventIcon = (props: SvgIconProps) => {
    const style = useStyle();
    let color = style.components.Node.custom?.solid?.color || '#00cc00';

    return <KeyboardIcon style={{color, width: '100%', height: '100%'}}/>
}

const MousePointerPath = () => <path d="M9.755,0l0,42.168l9.895,-6.818l6.504,14.65l9.601,-4.206l-7.073,-14.116l11.563,-2.377l-30.49,-29.301Z"/>

export const MouseEventIcon = (props: SvgIconProps) => {
    const style = useStyle();
    let color = style.components.Node.custom?.solid?.color || '#00cc00';

    return <SvgIcon {...props} style={{color, width: '100%', height: '100%', ...props.style}} viewBox={"-15 -15 80 80"}>
        <MousePointerPath />
    </SvgIcon>
}

export type IconDictionary = {[key: string]: FunctionComponent | ComponentClass | string}
export type IconPackages = {[key: string]: IconDictionary}

export const icons: IconPackages = {
    "": {
        "add": PlusIcon,
        "subtract": MinusIcon,
        "multiply": MultiplyIcon,
        "divide": DivideIcon,
        "unary plus": PlusSmallIcon,
        "unary minus": MinusSmallIcon,
        "increment": IncrementIcon,
        "decrement": DecrementIcon,
        "logical and": LogicalAndIcon,
        "logical or": LogicalOrIcon,
        "greater": GreaterIcon,
        "greater or equal": GreaterOrEqualIcon,
        "less": LessIcon,
        "less or equal": LessOrEqualIcon,
        "slider": SliderIcon,
        "input": TextAreaIcon,
        "keyboard": KeyEventIcon,
        "mouse pointer": MouseEventIcon,
        "package": PackageIcon,
        "toggle": ToggleIcon
    }
}

export const getIcon = (name: string, packageName: string = ""): FunctionComponent | ComponentClass | string => {
    return icons[packageName][name];
}