import {ExecutableNode, isExecutableNode, Node} from "../../store/nodes";
import {useTypedSelector} from "../../store";
import {hasController} from "./FancyExecutableNode";
import FancyExecutableNodeController from "./FancyExecutableNodeController";
import React from "react";

const FancyControllers = ({filter, forceUpdate}: {
    filter?: (n: ExecutableNode) => boolean,
    forceUpdate?: boolean
}) => {
    let nodes = useTypedSelector(state => {
        return Object.fromEntries<Node>(
            Object.entries(state.nodes.nodes).filter(
                ([, n]) => isExecutableNode(n) && (filter ? filter(n as ExecutableNode) : true) && hasController(n as ExecutableNode)
            )
        )
    });

    return <>
        {Object.keys(nodes).map(key => {
            let node = nodes[key];

            if ('width' in node.shape) {
                return <FancyExecutableNodeController id={key} key={key + "controller"} forceUpdate={forceUpdate}/>
            } else return null;
        })}
    </>
}

export default FancyControllers;