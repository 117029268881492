import React from "react";
import {FancyNodeStyleSheet, createNodeStyle, mergeNodeStyles} from "./types";
import {useTypedSelector} from "../../store";
import {CircularProgress} from "@mui/material";
import {useStyle} from "../../hooks";

export interface FancyStyleProviderProps {
    children: React.ReactNode
    style: FancyNodeStyleSheet
}

export const NodeStyleContext = React.createContext(createNodeStyle());

export const LoadingScreen = () => {
    const style = useStyle();

    return <div style={{position: 'absolute', inset: 0, background: style.background}}>
        <div style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            color: style.palette.default.light,
            opacity: .5
        }}>
            <CircularProgress color={'inherit'} size={64} thickness={3}/>
        </div>
    </div>
}

export default function FancyNodeStyleProvider(props: FancyStyleProviderProps) {
    const staticStyle = props.style

    return (
        <NodeStyleContext.Provider value={staticStyle}>
            {props.children}
        </NodeStyleContext.Provider>
    )
}