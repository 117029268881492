import {PortControllerSettings} from "../index";
import {FancySliderProps} from "../FancySlider";
import store, {useTypedSelector} from "../../../store";
import {useStyle} from "../../../hooks";
import React, {useState} from "react";
import {useResetClose} from "../../../hooks/window/dialogs";
import {setActiveDialogCallback} from "../../../store/workspace";
import {loremIpsumShort} from "../../../common/dev";
import {FancyTextFieldProps} from "../FancyTextField";
import LabeledComponent from "./LabeledComponent";
import StyledSettingsInput from "./StyledSettingsInput";

const FancyTextFieldSettings = (props: PortControllerSettings<FancyTextFieldProps>) => {
    const id = props.id;
    const controller = useTypedSelector(state => state.nodes.controllers[id]);
    const style = useStyle();

    const [value_, setValue_] = useState<string | undefined>(undefined);

    const [initial] = useResetClose<typeof controller>(controller, props.onChange);

    const handleValueCommit = () => {
        props.onChange({value: value_});
        setValue_(undefined);
        deleteCloseCallback();
    }

    const handleValueCloseCommit = (v: string) => {
        props.onChange({value: v});
        setValue_(undefined);
        deleteCloseCallback();
    }

    const setCloseCallback = (callback: (() => any) | undefined) => {
        store.dispatch(setActiveDialogCallback("Close", callback));
    }

    const deleteCloseCallback = () => store.dispatch(setActiveDialogCallback("Close", undefined));

    const handleValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue_(event.target.value);
        setCloseCallback(() => handleValueCloseCommit(event.target.value));
    }

    return <><div style={{marginBottom: 16}}>{loremIpsumShort()}</div><div style={{display: 'flex', flexWrap: 'wrap', columnGap: 12, rowGap: 6}}>
        <div style={{position: 'relative', width: 200, minWidth: '45%', height: 28, flexGrow: 1}}>
            <LabeledComponent indent={'25%'} label={"value:"}>
                <StyledSettingsInput
                    value={value_ ?? controller.value}
                    onChange={handleValueChange}
                    background={style.components.Input.background}
                    textColor={initial && (initial.value !== controller.value) ? style.palette.highlight.main : style.components.Input.color}
                    onBlur={handleValueCommit}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            handleValueCommit()
                        }
                    }}
                />
            </LabeledComponent>
        </div>
    </div>
    </>
}

export default FancyTextFieldSettings;