import {iteratePackages, NodeTypePackages, NodeTypePath} from "../types/nodeTypes";
import {LinksState, NodesState} from "./Machine";
import * as controllers from "../types/controllers.json";
import {MachineConnector} from "./MachineConnector";

class MachineHandler {
    private _executables: NodeTypePackages;
    private readonly _connector: MachineConnector;

    public onInitialized: () => any = () => {};

    public static current: MachineHandler;

    public constructor(connector: MachineConnector) {
        this._executables = {};
        this._connector = connector;
    }

    public init(
        nodes?: NodesState,
        links?: LinksState
    ) {
        this.connector.onInitialized = config => {
            console.log(config);
            if (config.executables) this._executables = config.executables;

            const controllerNodeTypes: NodeTypePackages = controllers as NodeTypePackages;

            const addToControllerNodeTypes = (key: string, pckg: string = "", config: any) => {
                if (!this._executables[pckg]) this._executables[pckg] = {};
                this._executables[pckg][key] = controllerNodeTypes[pckg][key];
                console.log("ADDING CONTROLLER");
                //TODO: add controller config
            }

            if (config.controllers) {
                console.log("HAS CONTROLLER");
                iteratePackages(
                    config.controllers,
                    addToControllerNodeTypes
                );
            } else {
                iteratePackages(
                    controllerNodeTypes,
                    (key, pckg) => addToControllerNodeTypes(key, pckg, {})
                );
            }

            this.onInitialized();
        }

        this.connector.init(nodes, links);
    }

    public get connector() {
        return this._connector;
    }

    public get executables() {
        return this._executables;
    }
}

export default MachineHandler;

export const getNodeTypes = () => MachineHandler.current.executables;
export const getNodeType = (key: string, pckg: string = "") => {
    return MachineHandler.current?.executables[pckg][key] ?? null;
}
export const getNodeTypeFromPath = (path?: NodeTypePath) => {
    if (!path) return null;
    return getNodeType(path.name, path.package ?? "")
}