import React from "react";
import {createStyle, FancyStyleSheet} from "./types";

export interface FancyStyleProviderProps {
    children: React.ReactNode;
    style: FancyStyleSheet;
}
export const StyleContext = React.createContext(createStyle());

export default function FancyStyleProvider(props: FancyStyleProviderProps) {
    const style = props.style

    return (
        <StyleContext.Provider value={style}>
            {props.children}
        </StyleContext.Provider>
    )
}