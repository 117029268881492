import store from "../../store";
import {Edge, ExecutableNode, isExecutableNode, Sequential} from "../../store/nodes";

export type ResultPath = string[] | null;

export function deepPathSearch(fromId: string, toId: string, current: string[], best: ResultPath): ResultPath {
    let {forwardAdjacency, nodes} = store.getState().nodes;
    let from = nodes[fromId];
    let to = nodes[toId];

    let result = best;

    if (forwardAdjacency[fromId])
    for (let outIndex of Object.keys(forwardAdjacency[fromId])) {
        if (forwardAdjacency[fromId][+outIndex])
        for (let toNode of Object.keys(forwardAdjacency[fromId][+outIndex])) {
            if (toNode === toId) {
                if (result === null) {
                    result = [...current];
                    result.push(fromId);
                    result.push(toNode);
                } else if (result.length > current.length + 1) {
                    result.length = 0;
                    for (let c of current) result.push(c);
                    result.push(fromId);
                    result.push(toNode);
                }
            } else {
                let next = [...current];
                next.push(fromId);
                result = deepPathSearch(toNode, toId, next, result);
            }
        }
    }

    if (isExecutableNode(from)) {
        let node = from as ExecutableNode;
        if (node.next) {
            let nextNode = node.next;
            if (nextNode == toId) {
                if (result === null) {
                    result = [...current];
                    result.push(fromId);
                    result.push(nextNode);
                } else if (result.length > current.length + 1) {
                    result.length = 0;
                    for (let c of current) result.push(c);
                    result.push(fromId);
                    result.push(nextNode);
                }
            } else {
                let next = [...current];
                next.push(fromId);
                result = deepPathSearch(nextNode, toId, next, result);
            }
        }
    }

    return result;
}

export function forEachForwardAdjacency(fromId: string, fn: (fromIndex: number, toId: string, toIndex: number) => any) {
    let forwardAdjacency = store.getState().nodes.forwardAdjacency;
    if (!forwardAdjacency[fromId]) return;
    Object.keys(forwardAdjacency[fromId]).forEach(fromIndex => {
        Object.keys(forwardAdjacency[fromId][+fromIndex]).forEach(toId => {
            forwardAdjacency[fromId][+fromIndex][toId].forEach(toIndex => {
                fn(+fromIndex, toId, toIndex);
            })
        })
    })
}

export function forEachBackwardAdjacency(fromId: string, fn: (fromIndex: number, toId: string, toIndex: number) => any) {
    let backwardAdjacency = store.getState().nodes.backwardAdjacency;
    if (!backwardAdjacency[fromId]) return;
    Object.keys(backwardAdjacency[fromId]).forEach(fromIndex => {
        Object.keys(backwardAdjacency[fromId][+fromIndex]).forEach(toId => {
            backwardAdjacency[fromId][+fromIndex][toId].forEach(toIndex => {
                fn(+fromIndex, toId, toIndex);
            })
        })
    })
}

export function getConnections(fromId: string, toId: string): Edge[] {
    let result: Edge[] = [];
    forEachForwardAdjacency(fromId, (outIndex, to, toIndex) => {
        if (toId === to) {
            result.push({
                from: {
                    id: fromId,
                    index: outIndex
                },
                to: {
                    id: toId,
                    index: toIndex
                }
            })
        }
    });
    return result;
}

export function collectStack(id: string, nodes: {[id: string]: Sequential<string>}): string[] {
    let result: string[] = [];
    let startNode = id;
    while (nodes[startNode].prev) {
        startNode = nodes[startNode].prev as string;
    }
    result.push(startNode);
    while (nodes[startNode].next) {
        startNode = nodes[startNode].next as string;
        result.push(startNode);
    }
    return result;
}

/*
export function findPath(fromId: string, toId: string): Edge[] | null {
    let startSet: string[] = [];
    let result = deepPathSearch(fromId, toId, startSet, null);
    if (result == null) return null;

    FancyNode first = null;
    FancyNode prev = null;
    Iterator<FancyNode> iterator = result.iterator();
    LinkedHashSet<FancyObject> loop = new LinkedHashSet<FancyObject>();

    while(iterator.hasNext()) {
        FancyNode node = iterator.next();
        if (first == null) first = node;
        else {
            boolean con = true;
            if (node instanceof AbstractNode && prev instanceof AbstractNode)
                if (((AbstractNode)node).getPre() == prev) con = false;

            if (con) {
                AbstractState[][] connections = getConnections(prev, node);
                if (connections != null)
                    if (connections.length > 0) {
                        loop.add(connections[0][0]);
                        loop.add(connections[0][1]);
                    }
            }
        }
        loop.add(node.getObject());
        prev = node;
    }

    return loop;
}
*/