import Machine, {
    LinksState,
    MachineConfiguration,
    MachineDefinitionState,
    MachineUpdate,
    NodeDataUpdate,
    NodesState
} from "../Machine";
import {MachineResponse} from "./types";
import {MachineConnector} from "../MachineConnector";

export class WsMachineConnector extends MachineConnector {
    private webSocket: WebSocket | null = null;
    private wsURL: string | null = null;

    public constructor(
        url: string,
        onUpdate: (state: MachineDefinitionState) => boolean
    ) {
        super(onUpdate);
        this.wsURL = url;
    }

    public set url(wsu: string) {
        this.wsURL = wsu;
    }

    public async init(
        nodes?: NodesState,
        links?: LinksState,
    ) {
        if (!this.wsURL) return;
        this.webSocket = await this.connectToServer(this.wsURL);

        this.webSocket.onmessage = wsm => {
            let message = JSON.parse(wsm.data) as MachineResponse;

            if (message.type === "init") {
                this.onInitialized && this.onInitialized(message.config);

            } else if (message.type === "update") {
                this._onUpdate(message.state);
            }
        }

        this.webSocket.send(JSON.stringify({
            type: "init",
            nodes,
            links
        }));
    }

    public update(
        update: MachineUpdate
    ) {
        if (!this.webSocket) return;
        if (this.webSocket.readyState === WebSocket.CLOSED) {

        }

        this.webSocket.send(JSON.stringify({
            type: "update",
            update
        }));
    }

    private async connectToServer(url: string) {
        const ws = new WebSocket(url);
        return new Promise<WebSocket>(resolve => {
            const timer = setInterval(() => {
                if (ws.readyState === 1) {
                    clearInterval(timer)
                    resolve(ws);
                }
            }, 10);
        });
    }
}