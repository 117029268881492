import {connect, Connector, disconnect, Node, NodesChanges, updateNodes} from "../../store/nodes";
import {recordAndExecute} from "./CommandHandler";
import store from "../../store";
import {Command} from "./Command";
import deepEqual from "deep-equal";
import {objectMap} from "../utils";

export function connect_(from: Connector, to: Connector, level?: number) {
    recordAndExecute(
        () => store.dispatch(connect(from, to, level)),
        () => store.dispatch(disconnect(from, to)),
        "connect"
    )
}

export function getNodesMemento(): {[key: string]: Node} {
    return JSON.parse(JSON.stringify({...store.getState().nodes.nodes}));
}

export function getNodesChanges(state1: {[key: string]: Node}, state2: {[key: string]: Node}): NodesChanges {
    let keys: string[] = [];
    Object.keys(state1).forEach(key => keys.push(key));
    Object.keys(state2).forEach(key => keys.push(key));

    let changes: NodesChanges = {
        added: {},
        changed: {},
        deleted: {}
    }

    keys.forEach(key => {
        if (state1[key] === undefined && state2[key] !== undefined) {
            changes.added[key] = {...state2[key]}
        } else if (state1[key] !== undefined && state2[key] === undefined) {
            changes.deleted[key] = {...state1[key]}
        } else if (state1[key] !== undefined && state2[key] !== undefined) {
            if (!deepEqual(state1[key], state2[key])) changes.changed[key] = [{...state1[key]}, {...state2[key]}]
        }
    });

    return changes;
}

export class MementoNodeCommand extends Command {
    private _changes: NodesChanges;
    constructor(state1: {[key: string]: Node}, state2: {[key: string]: Node}) {
        let changes = getNodesChanges(state1, state2);
        super(() => {
            store.dispatch(
                updateNodes({
                    add: changes.added,
                    change: objectMap(changes.changed, (change: [Node, Node]) => change[1]),
                    delete: Object.keys(changes.deleted)
                })
            )
        }, () => {
            store.dispatch(
                updateNodes({
                    add: changes.deleted,
                    change: objectMap(changes.changed, (change: [Node, Node]) => change[0]),
                    delete: Object.keys(changes.added)
                })
            )
        }, "node state");
        this._changes = changes;
    }
}