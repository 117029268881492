import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import {useState} from "react";
import {useStyle} from "../../hooks";
import {AnimatePresence, motion} from "framer-motion";

export default ({value, onChange} : {
    value: boolean,
    onChange: () => any
}) => {
    const [hovered, setHovered] = useState<boolean>(false);

    const handleHover = () => setHovered(true);
    const handleUnhover = () => setHovered(false);

    const style = useStyle();

    return <div
        style={{
            cursor: 'pointer',
            opacity: hovered ? 1.0 : 0.5,
            padding: 2
        }}
        onClick={onChange}
        onMouseEnter={handleHover}
        onMouseLeave={handleUnhover}
    >
        {<AnimatePresence>
            {value && <motion.div key='light mode icon'
                initial={{scale: 0}}
                animate={{scale: 1, transition: {duration: .25, delay: .25}}}
                exit={{scale: 0, transition: {duration: .25}}}
            >
                <LightModeIcon style={{
                    fontSize: 20,
                    color: style.palette.default.light
                }}/>
            </motion.div>}
            {!value && <motion.div key='dark mode icon'
                initial={{scale: 0}}
                animate={{scale: 1, transition: {duration: .25, delay: .25}}}
                exit={{scale: 0, transition: {duration: .25}}}
            >
                <DarkModeIcon style={{
                    fontSize: 20,
                    color: style.palette.default.light
                }}/>
            </motion.div>}
        </AnimatePresence>}
    </div>;
}