import {useTypedSelector} from "../../store";
import {useMousePosition} from "../../hooks/input/events";
import {useColorFade, useStyle} from "../../hooks";
import {useZoomTranslate} from "../workspace/ZoomTranslate";
import React, {useEffect} from "react";
import {Position} from "../../store/nodes";
import {getHighlightAura} from "./FancyNodeHighlights";
import {dist} from "../../common/math";
import {getStretchBlobString} from "../../common/svg";
import {_scale} from "./index";

const ConnectedConnectionLine = () => {
    let line = useTypedSelector(state => state.nodes.connectionLine);
    let hovered = useTypedSelector(state => state.nodes.hovered);
    let hoveredPort = useTypedSelector(state => hovered ? state.nodes.hoveredPort : undefined);
    let mousePos = useMousePosition();
    let style = useStyle();
    let highlightColorBaseGhost = style.palette.highlight.ghost;
    let [color, setColor] = useColorFade(highlightColorBaseGhost);
    let {x, y, zoom} = useZoomTranslate();

    useEffect(() => {
        if (line) {
            const wrongDir = line.direction === 'in-in' || line.direction === 'out-out';
            setColor(wrongDir ? '#ff002060' : highlightColorBaseGhost)
        }
    }, [line])

    if (!line) return null;

    let targetPos: Position;
    let targetIsState = line.target?.id && (line.target?.index === undefined);

    if (line.to) {
        targetPos = {
            x: line.to.x,
            y: line.to.y
        }
    } else if (!mousePos.x || !mousePos.y) {
        targetPos = {
            x: line.from.x,
            y: line.from.y
        }
    } else {
        targetPos = {
            x: (mousePos.x - x) / zoom,
            y: (mousePos.y - y) / zoom
        }
    }

    let hhh = line.target !== undefined && line.target !== null;

    const aura = getHighlightAura(8, _scale, zoom); //8 * _scale;
    const portSize = 6 * _scale;
    const dot = 2 * _scale;
    const width = 2 * _scale;

    let targetRad = targetIsState ? 12 * _scale : portSize / 2;

    /*
    hh: if (hoveredPort) {
        if (!node) break hh;

        const exn = node as ExecutableNode;
        if (!exn) break hh;

        const width = exn.shape.width;
        const xPos = n.__rf.position.x;
        const yPos = n.__rf.position.y;

        const hAt = (i: number) => i * theme.components.Port.spacing + theme.components.Port.margin;
        const h = exn.height - theme.components.Node.strokeWidth;

        let i = hovered.handle;
        let right = false;
        if (i >= exn.input.length) {
            i -= exn.input.length;
            right = true;
        }

        targetPos.x = xPos + (right ? width + theme.components.Node.strokeWidth / 2 * 3 : theme.components.Node.strokeWidth / 2);
        targetPos.y = yPos + hAt(i) + theme.components.Node.strokeWidth;

        hhh = true;
    }
    */

    const sx = line.from.x; // + txOr0(props.data.from);
    const sy = line.from.y; // + tyOr0(props.data.from);
    const tx = targetPos.x; // + txOr0(props.data.to);
    const ty = targetPos.y; // + tyOr0(props.data.to);

    const distX = tx - sx;
    const distY = ty - sy;
    const flip = distY < 0 ? -1 : 1;
    const bx = distY / 2 * flip;
    const sbx = sx + bx;
    const tbx = tx - bx;

    return <svg style={{
        position: 'absolute',
        left: 0,
        top: 0,
        overflow: 'visible',
        pointerEvents: 'none'
    }}>{dist(sx, sy, tx, ty) > 1.25 * (aura + portSize / 2) ? <path
        d={getStretchBlobString(
            sx,
            sy,
            tx,
            ty,
            aura + portSize / 2,
            hhh ? aura + targetRad : dot,
            aura * 2,
            hhh ? aura * 2 : dot * 4,
            width,
            .25
        )}
        fill={color}/> : <circle cx={sx} cy={sy} r={aura + portSize / 2} fill={highlightColorBaseGhost}/>}
    </svg>
}

export default ConnectedConnectionLine;