export class Command {
    private _execute: () => any;
    private _undo: () => any;
    private _name: string | undefined;

    constructor(execute: () => any, undo: () => any, name?: string) {
        this._execute = execute;
        this._undo = undo;
        this._name = name;
    }

    public execute() {
        this._execute();
    }

    public undo() {
        this._undo();
    }
}