import {Edge, ExecutableNode, isExecutableNode, Port} from "../../store/nodes";
import {useTypedSelector} from "../../store";
import {getBezierString} from "../../common/svg";
import React, {SVGProps, useContext} from "react";
import {NodeStyleContext} from "../style/FancyNodeStyleProvider";
import {_scale} from "./index";

const FancyEdge = ({edge, skipHighlighted, skipUnhighlighted}: {
    edge: Edge,
    skipHighlighted?: boolean,
    skipUnhighlighted?: boolean
}) => {
    let fromNode = useTypedSelector(state => state.nodes.nodes[edge.from.id]);
    let toNode = useTypedSelector(state => state.nodes.nodes[edge.to.id]);
    let hovered = useTypedSelector(state => {
        if (!state.nodes.hoveredPort) return state.nodes.hovered === edge.from.id || state.nodes.hovered === edge.to.id;
        else return (
            state.nodes.hovered === edge.from.id
            && state.nodes.hoveredPort.direction === 'out'
            && state.nodes.hoveredPort.index === edge.from.index
        ) || (
            state.nodes.hovered === edge.to.id
            && state.nodes.hoveredPort.direction === 'in'
            && state.nodes.hoveredPort.index === edge.to.index
        )
    });

    const style = useContext(NodeStyleContext);

    let fromPort: Port | undefined = undefined;
    if (isExecutableNode(fromNode)) {
        let exn = fromNode as ExecutableNode;
        if (exn.output && edge.from.index !== undefined) {
            fromPort = exn.output[edge.from.index]
        }
    }

    let toPort: Port | undefined = undefined;
    if (isExecutableNode(toNode)) {
        let exn = toNode as ExecutableNode;
        if (exn.input && edge.to.index !== undefined) {
            toPort = exn.input[edge.to.index]
        }
    }

    const portDist = 12.0;
    const portSize = 6.0;
    const portShift = portSize * 0.48;

    let sx, sy, tx, ty;
    if (isExecutableNode(fromNode)) {
        let exn = fromNode as ExecutableNode;
        sx = fromNode.shape.x + exn.shape.width + portShift;
        sy = fromNode.shape.y + ((edge.from.index ?? 0) + 1) * portDist;
    } else {
        sx = fromNode.shape.x;
        sy = fromNode.shape.y;
    }
    if (isExecutableNode(toNode)) {
        tx = toNode.shape.x - portShift;
        ty = toNode.shape.y + ((edge.to.index ?? 0) + 1) * portDist;
    } else {
        tx = toNode.shape.x;
        ty = toNode.shape.y;
    }

    const distX = tx - sx;
    const distY = ty - sy;
    const flip = distY < .0 ? -1.0 : 1.0;
    const bx = distY / 2 * flip;
    const sbx = sx + bx;
    const tbx = tx - bx;
    const xo = 1.0;

    const pathString = getBezierString(
        (sx - xo) * _scale,
        sy * _scale,
        sbx * _scale,
        tbx * _scale,
        (tx + xo) * _scale,
        ty * _scale
    );

    if (hovered && skipHighlighted) return null;
    if (!hovered && skipUnhighlighted) return null;

    let empty = fromPort && !fromPort.data;

    let state = fromNode.state;
    let color = state === 'disabled' ? (style.components.Edge.disabled?.color ?? style.components.Edge.color) : style.components.Edge.color;

    const dashedProps: SVGProps<SVGPathElement> = {
        strokeWidth: 2.25 * _scale,
        strokeDasharray: '.01 10',
        strokeDashoffset: 2,
        strokeLinecap: 'round'
    }

    const nondashedProps: SVGProps<SVGPathElement> = {
        strokeWidth: style.components.Edge.strokeWidth//2 * _scale
    }

    const pathProps = empty ? dashedProps : nondashedProps

    return <path
        d={pathString}
        stroke={hovered ? style.palette.highlight.main : color}
        fill='transparent'
        {...pathProps}
    />
}

const ConnectedEdge = ({id, skipHighlighted, skipUnhighlighted}: {
    id: string,
    skipHighlighted?: boolean,
    skipUnhighlighted?: boolean
}) => {
    let edge = useTypedSelector(state => state.nodes.edges[id]) as Edge;

    if (!edge) return null;

    return <FancyEdge edge={edge} skipHighlighted={skipHighlighted} skipUnhighlighted={skipUnhighlighted}/>
}

export default ConnectedEdge;