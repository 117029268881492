import {
    ExecutableNode,
    hoverNode,
    Node,
    Position,
    setConnectionLine,
    setMode, StateNode,
    unhoverNode, updateConnectionLine,
    updateNode
} from "../../store/nodes";
import React, {CSSProperties, useRef, useState} from "react";
import store, {useTypedSelector} from "../../store";
import {DraggableCore, DraggableEventHandler} from "react-draggable";
import {NodeStyleContext} from "../style/FancyNodeStyleProvider";
import FancyPort from "./FancyPort";
import {constrain, constrainDelta, getOverlap, intersects, mean} from "../../common/math";
import {useZoom} from "../workspace/ZoomTranslate";
import {rfScale} from "../style";
import {_scale, scaled} from "./index";

interface RelativePosition {
    left: number
    top: number
    right: number
    bottom: number
}

const FancyStateNode = ({node, shadow, style}: {
    node: StateNode,
    shadow?: boolean,
    style?: CSSProperties
}) => {
    const [relMousePos, setRelMousePos] = useState<RelativePosition>({left: 0, top: 0, right: 0, bottom: 0});

    const [dragging, setDragging] = useState<boolean>(false);
    const dragRef = useRef<HTMLDivElement>(null);

    const zoom = useZoom();

    const [cursor, setCursor] = useState<'grab' | 'grabbing'>('grab');
    let hovered = useTypedSelector(state => state.nodes.hovered === node.id);

    const screen = (x: number) => x * _scale * zoom;
    const world = (x: number) => x / _scale / zoom;

    const handleStart: DraggableEventHandler = (e, data) => {
        setRelMousePos({
            left: data.x - screen(node.shape.x),
            top: data.y - screen(node.shape.y),
            right: screen(node.shape.radius * 2 + node.shape.x) - data.x,
            bottom: screen(node.shape.radius * 2 + node.shape.y) - data.y
        });

        setCursor('grabbing');
        setDragging(true);
    }

    const handleDrag: DraggableEventHandler = (e, data) => {
        let newX = world(data.x - relMousePos.left);
        let newY = world(data.y - relMousePos.top);

        store.dispatch(updateNode({
            id: node.id,
            shape: {x: newX, y: newY}
        }));
    }

    const handleStop: DraggableEventHandler = (e, data) => {
        setDragging(false);
        setCursor('grab');
        //store.dispatch(setMode('default'));
    }

    const handleMouseEnter = () => store.dispatch(hoverNode(node));
    const handleMouseLeave = () => !dragging && store.dispatch(unhoverNode(node));

    return <NodeStyleContext.Consumer>{(theme) => {
        let nodeStyle = theme.components.Node;
        let border = 2 * _scale;

        let perimeter = node.shape.radius * 2 * _scale;

        return <DraggableCore nodeRef={dragRef} onStart={handleStart} onDrag={handleDrag} onStop={handleStop}>
            <div
                ref={dragRef}
                style={{
                    position: 'absolute',
                    left: (node.shape.x - node.shape.radius) * _scale,
                    top: (node.shape.y - node.shape.radius) * _scale,
                    width: perimeter,
                    height: perimeter,
                    cursor: cursor
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        left: -border / 2,
                        top: -border / 2,
                        width: perimeter - border,
                        height: perimeter - border,
                        background: 'linear-gradient(135deg, rgba(255,255,255,1.0) 20%, rgba(240,242,246,0.88)',
                        //backdropFilter: 'blur(2px)',
                        borderRadius: '50%',
                        border: border + 'px solid #d8e2ec', //#e0e8f0',
                        boxShadow: shadow ? 3 * _scale + 'px ' + 3 * _scale + 'px ' + 12 * _scale + 'px 0px #00408050' : undefined,
                        ...style
                    }}

                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
                {/*<div
                    style={{
                        position: 'absolute',
                        inset: 0,
                        cursor: cursor,
                        lineHeight: perimeter + "px",
                        textAlign: 'center',
                        fontFamily: 'Georgia',
                        fontSize: 28,
                        fontWeight: 600,
                        color: '#b8c6d4',
                        pointerEvents: 'none'
                    }}>
                    {node.id}
                </div>*/}
            </div>
        </DraggableCore>
    }}
    </NodeStyleContext.Consumer>
}

const noShapeUpdate = (prevNodeProps: { node: StateNode }, nextNodeProps: { node: StateNode }) =>
    prevNodeProps.node.shape.x === nextNodeProps.node.shape.x &&
    prevNodeProps.node.shape.y === nextNodeProps.node.shape.y &&
    prevNodeProps.node.shape.radius === nextNodeProps.node.shape.radius

const MemoFancyStateNode = React.memo(FancyStateNode, noShapeUpdate);

const ConnectedStateNode = ({id, shadow}: { id: string, shadow?: boolean }) => {
    let node = useTypedSelector(state => state.nodes.nodes[id]) as StateNode;

    return <MemoFancyStateNode node={node} shadow={shadow}/>
}

export default ConnectedStateNode;