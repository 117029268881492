import {Action, PayloadAction} from "@reduxjs/toolkit";
import {ReactNode} from "react";
import {Data, DefinitionState, MachineDefinitionState, NodeData} from "../../common/machine/Machine";
import {ExecutableNodeTypeInstance} from "../../common/types/nodeTypes";

export interface Position {
    x: number
    y: number
}

export interface CartesianDimensions {
    width: number
    height: number
}

export type Resizable = boolean | 'fixed aspect ratio' | 'horizontally' | 'vertically';

export interface ConstrainedCartesianDimensions extends CartesianDimensions {
    minWidth?: number
    maxWidth?: number
    minHeight?: number
    maxHeight?: number
    resizable?: Resizable
}

export interface Rectangle extends ConstrainedCartesianDimensions, Position {}

export interface Circle extends Position {
    radius: number
}

export type NodeIcon = {package?: string, icon: string, dimensions?: CartesianDimensions}
export type NodeController = {package?: string, controller: string, dimensions?: CartesianDimensions}
export type NodeLabel = string | NodeIcon | NodeController

export interface Drawable<T = Rectangle | Circle> {
    shape: T
    label?: NodeLabel
}

export interface ShapeUpdate<T = Partial<Rectangle & Circle>> {
    shape: T
}

export interface Sequential<T> {
    next?: T | null
    prev?: T | null
}

export interface Composable<P, C> {
    parent?: P
    children?: C[]
}

export interface Unique {
    id: string
}

export type Warnings = {[level: number]: any[]};
export interface Failable {
    warnings?: Warnings
}

export interface Port extends Failable {
    data?: Data
    shell?: any
}

export type PortPosition = {
    direction: 'in' | 'out'
    index: number
}

export interface DataProcessor {
    input?: Port[]
    output?: Port[]
}

export interface ExecutableNode extends Unique,
    Drawable<Rectangle>,
    Failable,
    DataProcessor,
    Sequential<string>,
    Composable<string, string>,
    Partial<ExecutableNodeTypeInstance> {
    state?: string
}

export interface StateNode extends Unique,
    Drawable<Circle>,
    Port,
    Composable<string, string> {
    state?: string
}

export type Node = ExecutableNode | StateNode;

export type ExecutableNodeConnector = Unique & {index: number};
export type StateNodeConnector = Unique & {index: undefined};
export type Connector = ExecutableNodeConnector | StateNodeConnector;

export type DataflowDirection = 'forward' | 'backward' | 'in-in' | 'out-out';
export type StackDirection = 'forward' | 'backward';

export interface NodeUpdateOptions {
    updateStack?: boolean | StackDirection
    moveStack?: boolean
    updateChildren?: boolean
}
export type NodeUpdate = Unique & Partial<ShapeUpdate> & Sequential<string>;

export interface Edge {
    from: Connector
    to: Connector
    update?: number
    level?: number
}

export type Mode = 'default' | 'resize' | 'drag' | 'connect'

export interface Connection {
    from: Position
    start: Connector
    direction: DataflowDirection
}

export interface ConnectionLine extends Connection {
    to: Position | null
    target: Connector | null
}

export interface MachineState {
    initialized?: boolean
    running?: boolean
}

export type NodeInfo = { [key: string]: { [key: string]: any } };
export type Adjacency = { [key: string]: { [index: number]: {[key: string]: number[]} } };

export interface NodesState {
    nodes: { [key: string]: Node }
    edges: { [key: string]: Edge }
    nodeInfo: NodeInfo
    controllers: { [key: string]: any }
    forwardAdjacency: Adjacency
    backwardAdjacency: Adjacency
    hovered?: string
    hoveredPort?: PortPosition
    selected?: string[]
    mode: Mode
    connectionLine?: ConnectionLine
    nodeSearchConnections?: Connection[]
    machineState: MachineState
}

export interface NodesChanges {
    added: {[key: string]: Node}
    changed: {[key: string]: [Node, Node]}
    deleted: {[key: string]: Node}
}

export interface NodesUpdate {
    add: {[key: string]: Node}
    change: {[key: string]: Node}
    delete: string[]
}

export enum NodesActionType {
    ADDCONTROLLER = "ADDCONTROLLER",
    REMOVECONTROLLER = "REMOVECONTROLLER",
    UPDATECONTROLLER = "UPDATECONTROLLER",
    ADDNODE = "ADDNODE",
    REMOVENODE = "REMOVENODE",
    UPDATENODE = "UPDATENODE",
    UPDATENODES = "UPDATENODES",
    HOVERNODE = "HOVERNODE",
    UNHOVERNODE = "UNHOVERNODE",
    HOVERPORT = "HOVERPORT",
    UNHOVERPORT = "UNHOVERPORT",
    CONNECT = "CONNECT",
    DISCONNECT = "DISCONNECT",
    COMPOSE = "COMPOSE",
    DECOMPOSE = "DECOMPOSE",
    SETMODE = "SETMODE",
    SETCONNECTIONLINE = "SETCONNECTIONLINE",
    UPDATECONNECTIONLINE = "UPDATECONNECTIONLINE",
    MOVECONNECTIONLINETONODESEARCH = "MOVECONNECTIONLINETONODESEARCH",
    CLEARNODESEARCHCONNECTIONS = "CLEARNODESEARCHCONNECTIONS",
    UPDATESTATE = "UPDATESTATE",
    SETMACHINESTATE = "SETMACHINESTATE"
}

export type AddControllerAction = PayloadAction<Unique & {state: any}, NodesActionType.ADDCONTROLLER>
export type RemoveControllerAction = PayloadAction<Unique, NodesActionType.REMOVECONTROLLER>
export type UpdateControllerAction = PayloadAction<Unique & {state: any}, NodesActionType.UPDATECONTROLLER>
export type AddNodeAction = PayloadAction<Node, NodesActionType.ADDNODE>
export type RemoveNodeAction = PayloadAction<Unique, NodesActionType.REMOVENODE>
export type UpdateNodeAction = PayloadAction<{update: NodeUpdate, options?: NodeUpdateOptions}, NodesActionType.UPDATENODE>
export type UpdateNodesAction = PayloadAction<NodesUpdate, NodesActionType.UPDATENODES>
export type HoverNodeAction = PayloadAction<Unique | undefined, NodesActionType.HOVERNODE>
export type UnhoverNodeAction = PayloadAction<Unique, NodesActionType.UNHOVERNODE>
export type HoverPortAction = PayloadAction<Unique & PortPosition, NodesActionType.HOVERPORT>
export type UnhoverPortAction = PayloadAction<Unique & PortPosition, NodesActionType.UNHOVERPORT>
export type ConnectAction = PayloadAction<Edge, NodesActionType.CONNECT>
export type DisconnectAction = PayloadAction<Edge, NodesActionType.DISCONNECT>
export type ComposeAction = PayloadAction<{parent: string, child: string}, NodesActionType.COMPOSE>
export type DecomposeAction = PayloadAction<string, NodesActionType.DECOMPOSE>
export type SetModeAction = PayloadAction<Mode, NodesActionType.SETMODE>
export type SetConnectionLineAction = PayloadAction<ConnectionLine | undefined, NodesActionType.SETCONNECTIONLINE>
export type MoveConnectionLineToNodeSearchAction = Action<NodesActionType.MOVECONNECTIONLINETONODESEARCH>
export type ClearNodeSearchConnectionsAction = Action<NodesActionType.CLEARNODESEARCHCONNECTIONS>
export type UpdateConnectionLineAction = PayloadAction<Partial<ConnectionLine>, NodesActionType.UPDATECONNECTIONLINE>
export type UpdateStateAction = PayloadAction<MachineDefinitionState, NodesActionType.UPDATESTATE>
export type SetMachineStateAction = PayloadAction<MachineState, NodesActionType.SETMACHINESTATE>

export type NodeActions =
    AddNodeAction
    | RemoveNodeAction
    | UpdateNodeAction
    | AddControllerAction
    | RemoveControllerAction
    | UpdateControllerAction
    | UpdateNodesAction
    | HoverNodeAction
    | UnhoverNodeAction
    | HoverPortAction
    | UnhoverPortAction
    | ConnectAction
    | DisconnectAction
    | ComposeAction
    | DecomposeAction
    | SetModeAction
    | SetConnectionLineAction
    | UpdateConnectionLineAction
    | MoveConnectionLineToNodeSearchAction
    | ClearNodeSearchConnectionsAction
    | UpdateStateAction
    | SetMachineStateAction;
