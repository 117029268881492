import {
    DialogActions,
    DialogActionType,
    DialogState,
    OpenDialogAction, SetActiveDialogCallbackAction, SetZoomTranslateAction, TransformActions, ZoomTranslateActionType
} from "./types";
import {ZoomTranslateState} from "../../components/workspace/ZoomTranslate";
import {default as deepMerge} from "deepmerge-json";

const initialDialogState: DialogState = {};

export const dialog = (
    state: DialogState = initialDialogState,
    action: DialogActions
): DialogState => {
    switch (action.type) {
        case DialogActionType.OPEN:
            return { ...state, activeDialog: (action as OpenDialogAction).payload};
        case DialogActionType.CLOSE:
            return { ...state, activeDialog: undefined};
        case DialogActionType.SETCALLBACK:
            const sadc = (action as SetActiveDialogCallbackAction).payload;
            if (state.activeDialog) {
                if (!state.activeDialog.buttonProps) {
                    state.activeDialog.buttonProps = {
                        [sadc.key]: {
                            callback: sadc.callback,
                            disabled: sadc.disabled
                        }
                    }
                } else {
                    let prevDisabled = undefined;
                    if (state.activeDialog.buttonProps[sadc.key] && state.activeDialog.buttonProps[sadc.key].disabled) {
                        prevDisabled = true;
                    }
                    state.activeDialog.buttonProps[sadc.key] = {
                        callback: sadc.callback,
                        disabled: sadc.disabled ?? prevDisabled
                    };
                }
            }
            return { ...state};
        default:
            return state;
    }
};

const initialZoomTranslateState: ZoomTranslateState = {x: 0, y: 0, zoom: .75};

export const transform = (
    state: ZoomTranslateState = initialZoomTranslateState,
    action: TransformActions
): ZoomTranslateState => {
    switch (action.type) {
        case ZoomTranslateActionType.SET:
            let ztap = (action as SetZoomTranslateAction).payload;
            return deepMerge(state, ztap);
        default:
            return state;
    }
};