import {InputBaseProps} from "@mui/material/InputBase/InputBase";
import {InputBase} from "@mui/material";
import React from "react";

const StyledSettingsInput = ({background, textColor, ...props}: {
    background: string,
    textColor: string
} & InputBaseProps) => <div
    style={{
        width: '100%',
        height: 28
    }}
>
    <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        //borderRadius: 5,
        background
    }}/>
    <div style={{position: 'absolute', top: 0, left: 8, right: 8, width: '100%'}}>
        <InputBase
            {...props}
            inputProps={{
                onMouseDown: (e) => e.stopPropagation()
            }}
            //fullWidth
            style={{
                fontFamily: 'Roboto Mono',
                fontSize: 13,
                fontWeight: 'bold',
                lineHeight: '12px',
                color: textColor,
                //background: theme.components.Input.background
            }}/>
    </div>
</div>

export default StyledSettingsInput;