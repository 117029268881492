import React, {ComponentClass, FunctionComponent, ReactNode} from "react";
import {FancySliderProps, OutputControlSlider} from "../controllers/FancySlider";
import {DivideIcon, IconPackages, MinusIcon, MultiplyIcon, PlusIcon, SliderIcon} from "./icons";
import {FancyTextFieldProps, OutputControlTextField} from "../controllers/FancyTextField";
import {FancyType, PortController, PortControllerSettings, StateController} from "../controllers";
import FancySliderSettings from "../controllers/settings/FancySliderSettings";
import FancyTextFieldSettings from "../controllers/settings/FancyTextFieldSettings";
import {OutputControlToggle} from "../controllers/FancyToggle";
import FancyKeyEvent from "../controllers/action/FancyKeyEvent";

export interface Controller<T> {
    component: FunctionComponent<T>,
    props?: Partial<T>
}

export type ControllerDictionary = {[key: string]: Controller<any>}
export type ControllerPackages = {[key: string]: ControllerDictionary}

export var controllers: ControllerPackages = {
    "": {
        "number slider": {
            component: OutputControlSlider, //(props: PortController<any, any>) => <OutputControlSlider {...props}/>,
            props: {
                port: {direction: "out", index: 0, type: 'float64'}
            }
        },
        "text field": {
            component: OutputControlTextField,
            props: {
                port: {direction: "out", index: 0, type: 'string'}
            }
        },
        "toggle": {
            component: OutputControlToggle,
            props: {
                port: {direction: "out", index: 0, type: 'boolean'}
            }
        },
        "key event": {
            component: FancyKeyEvent,
            props: {}
        }
    }
}

export var getController = (name: string, packageName: string = ""): Controller<any> | undefined => {
    let result = controllers[packageName][name];
    if (!result) return undefined;
    return ("component" in result && result.component) ? result : undefined
}

export type ControllerSettings<T> = {
    settingsContent: FunctionComponent<PortControllerSettings<T>> | ComponentClass<PortControllerSettings<T>, any>
    defaultState: T
}

export type ControllerSettingsDictionary = {[key: string]: ControllerSettings<any>}
export type ControllerSettingsPackages = {[key: string]: ControllerSettingsDictionary}

export var controllerSettings: ControllerSettingsPackages = {
    "": {
        "number slider": {
            settingsContent: (props: PortControllerSettings<FancySliderProps>) => <FancySliderSettings {...props}/>,
            defaultState: {
                min: 0,
                max: 1,
                value: 0,
                step: .01
            }
        },
        "text field": {
            settingsContent: (props: PortControllerSettings<FancyTextFieldProps>) => <FancyTextFieldSettings {...props}/>,
            defaultState: {
                value: ""
            }
        }
    }
}

export var getControllerSettings = (name: string, packageName: string = ""): ControllerSettings<any> => {
    return controllerSettings[packageName][name];
}