import React, {CSSProperties} from "react";
import {defaultNodeStyle, defaultStyle} from "./defaults";
import {default as deepMerge} from "deepmerge-json";

export interface Color {
    ghost?: string
    light?: string
    main: string
}

export interface FancyColor {
    ghost: string
    light: string
    main: string
    ghostAlpha: number
    lightAlpha: number
    mainAlpha: number
}

export function convertHex(hexc: string, a = 1) {
    let hex = hexc.replace('#', '');

    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    let r = parseInt(hex.substring(0,2), 16),
        g = parseInt(hex.substring(2,4), 16),
        b = parseInt(hex.substring(4,6), 16);

    if (a > 1 && a <= 100) {
        a = a / 100;
    }

    return 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')';
}

export function getRGB(hexc: string) {
    if (hexc.startsWith('rgba')) return getRGBRGBA(hexc);
    if (hexc.startsWith('rgb')) return hexc;
    let hex = hexc.replace('#', '');

    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    let r = parseInt(hex.substring(0,2), 16),
        g = parseInt(hex.substring(2,4), 16),
        b = parseInt(hex.substring(4,6), 16);

    return 'rgb(' + r + ',' + g + ',' + b + ')';
}

export function getAlpha(hexc: string): number {
    if (hexc.startsWith('rgba')) return getAlphaRGBA(hexc);
    if (hexc.startsWith('rgb')) return 1;
    let hex = hexc.replace('#', '');
    if (hex.length < 8) return 1;
    let a = parseInt(hex.substring(6,8), 16);
    return a;
}

function getAlphaRGBA(rgba: string): number {
    rgba = rgba.replace(' ', '');
    rgba = rgba.replace('rgba', '');
    rgba = rgba.substring(1, rgba.length - 1);
    let rgba_ = rgba.split(',')
    return +rgba_[3];
}

function getRGBRGBA(rgba: string) {
    rgba = rgba.replace(' ', '');
    rgba = rgba.replace('rgba', '');
    rgba = rgba.substring(1, rgba.length - 1);
    let rgba_ = rgba.split(',')
    return 'rgb(' + rgba_[0] + ',' + rgba_[1] + ',' + rgba_[2] + ')';
}

export function interpolateLightAndGhost(color: string, mainAlpha = 1.0, lightAlpha = 0.45, ghostAlpha = 0.15): FancyColor {
    return {
        ghostAlpha,
        lightAlpha,
        mainAlpha,
        ghost: convertHex(color, ghostAlpha),
        light: convertHex(color, lightAlpha),
        main: mainAlpha === 1.0 ? color : convertHex(color, mainAlpha),
    }
}

export function complementColor(color: Color): FancyColor {
    return {
        ghostAlpha: .15,
        lightAlpha: .45,
        mainAlpha: 1,
        ghost: color.ghost || convertHex(color.main, 0.15),
        light: color.light || convertHex(color.main, 0.45),
        main: color.main
    }
}

export interface NodeStyle {
    width: number
    strokeWidth: number
    cornerRadius: number
    cornerRadiusStack: number
    showAugmentations: boolean
    showLabels: boolean
    textColor: string
}

export interface SolidNodeStyle {
    width: number
    strokeWidth: number
    cornerRadius: number
    cornerRadiusStack: number
    showAugmentations: boolean
    showLabels: boolean
    gradient: string[]
    customShadowColor?: string
    shadowType?: 'glow' | 'drop'
}

export interface PortStyle {
    size: number
    spacing: number
    margin: number
    strokeWidth: number
    align: 'top' | 'center' | 'bottom'
    colorEmpty: string
    colorValue: string
}

export interface EdgeStyle {
    strokeWidth: number
}

export interface LabelStyle {}

export interface SliderStyle {
    railColor: string
}

export interface InputStyle {
    color: string
    background: string
}

export interface ShadowStyle {
    offsetX: number
    offsetY: number
    blurRadius: number
    spreadRadius: number
    color: string
}

export interface DialogStyle {
    minWidth: number
    maxWidth: number
    defaultWidth: number
    windowControlButtonSize: number
    windowControlBarPadding: number
    windowControlButtonSpacing: number
    transparentBackground: string
}

export interface FancyStyleSheet {
    window: DialogStyle & CSSProperties
    shadow: ShadowStyle
    palette: {
        highlight: FancyColor
        default: FancyColor
        background: FancyColor
    }
    mascot: {
        eyes: {
            color: FancyColor
            shadow: FancyColor
            iris: FancyColor
            pupil: string
            reflection: string
        }
        background: [string, string]
    }
}

export type StyleSheet = DeepPartial<FancyStyleSheet>;

export type CustomNodeStyle = Partial<NodeStyle | SolidNodeStyle> & CSSProperties & {'&:hover'?: Partial<NodeStyle | SolidNodeStyle> & CSSProperties};

export interface FancyNodeStyleSheet {
    components: {
        Node: NodeStyle & CSSProperties & {
            '&:hover'?: Partial<NodeStyle> & CSSProperties
            disabled?: Partial<NodeStyle> & CSSProperties
            custom?: {[name: string]: CustomNodeStyle}
        }
        Block: NodeStyle & CSSProperties
        Edge: EdgeStyle & CSSProperties & {
            disabled?: Partial<EdgeStyle> & CSSProperties
        }
        Port: PortStyle & CSSProperties & {
            disabled?: Partial<PortStyle> & CSSProperties
        }
        Label: LabelStyle & CSSProperties
        Slider: SliderStyle
        Input: InputStyle
    }
    palette: {
        highlight: FancyColor
        warning: {[level: number]: FancyColor}
        default: FancyColor
    }
    typography: {
        fontFamily: React.CSSProperties['fontFamily']
        fontSize: React.CSSProperties['fontSize']
        fontWeight: React.CSSProperties['fontWeight']
    }
    highlightWidth: number
    connectorWidth: number
    connectorSize: number
    background: React.CSSProperties['background']
    shadow: ShadowStyle & {background: string, disabledColor: string}
}

type DeepPartial<T> = T extends object ? { [p in keyof T]?: DeepPartial<T[p]>; } : T;

export type NodeStyleSheet = DeepPartial<FancyNodeStyleSheet>;

export function createStyle(style?: StyleSheet): FancyStyleSheet {
    return style ? mergeStyles(style, defaultStyle) : defaultStyle
}

export function mergeStyles(style: StyleSheet, def: FancyStyleSheet): FancyStyleSheet {
    return deepMerge(def, style);
}

export function createNodeStyle(style?: NodeStyleSheet): FancyNodeStyleSheet {
    return style ? mergeNodeStyles(style, defaultNodeStyle) : defaultNodeStyle
}

export function mergeNodeStyles(style: NodeStyleSheet, def: FancyNodeStyleSheet): FancyNodeStyleSheet {
    return deepMerge(def, style);
}