import store, {useTypedSelector} from "../../../store";
import FancyDialog from "./FancyDialog";
import {closeDialog, DialogTypeMap, ErrorDialogActionProps, NodeInfoDialogActionProps} from "../../../store/workspace";
import {loremIpsum} from "../../../common/dev";
import React from "react";
import {useWindowStyle} from "../../../hooks";
import {FancyWindowProps} from "./FancyWindow";
import {ConnectedExecutableNodePreview, ExecutableNodeTypePreview} from "../../nodes/FancyExecutableNode";
import {ExecutableNode, isExecutableNode, Node, updateController} from "../../../store/nodes";
import {getControllerSettings} from "../../labels";
import {getNodeTypeFromPath} from "../../../common/machine/MachineHandler";

export interface FancyDialogsProps {}

export function FancyNodeInfoDialog({id}: {id: string}) {
    const style = useWindowStyle();
    const node = useTypedSelector(state => state.nodes.nodes[id]);
    const controller = useTypedSelector(state => state.nodes.controllers[id]);
    const answerButtonProps = useTypedSelector(state => {
        let activeDialog = state.dialog.activeDialog;
        let result: {[key: string]: {disabled: boolean}} = {};
        if (activeDialog && activeDialog.buttonProps) {
            let {buttonProps} = activeDialog;
            Object.keys(buttonProps).forEach(key => {
                if (buttonProps[key].disabled) {
                    result[key] = {disabled: true}
                }
            })
        }
        if (result === {}) return undefined;
        else return result;
    });

    if (!isExecutableNode(node)) return null;
    let exn = node as ExecutableNode;

    const handleCloseCancel = (s: string) => {
        if (s === 'x' || s === 'Cancel' || s === 'Close') {
            let activeDialog = store.getState().dialog.activeDialog;
            if ("buttonProps" in activeDialog && activeDialog.buttonProps && "Close" in activeDialog.buttonProps) {
                let callback = activeDialog.buttonProps["Close"].callback;
                if (callback) callback();
            }
            store.dispatch(closeDialog());

        } else if (s === 'Reset') {
            let activeDialog = store.getState().dialog.activeDialog;
            if ("buttonProps" in activeDialog && activeDialog.buttonProps && "Reset" in activeDialog.buttonProps) {
                let callback = activeDialog.buttonProps["Reset"].callback;
                if (callback) callback();
            }
        }
    }

    const handleClickAway = () => store.dispatch(closeDialog())

    let nodeType = getNodeTypeFromPath("type" in node ? node.type : undefined);

    const controllerSettingsType = nodeType
        && typeof nodeType?.label !== "string"
        && nodeType?.label
        && "controller" in nodeType?.label
        && getControllerSettings(nodeType?.label?.controller);

    const infoDialog: FancyWindowProps = {
        shadowColor: style.shadow.color,
        pointTo: [],
        title: nodeType?.name || 'Info',
        answerButtons: controllerSettingsType ? ['Reset', 'Close'] : ['Close'],
        answerButtonProps,
        callback: handleCloseCancel,
        resizable: true,
        header: true,
        icon: 'info',
        iconSize: 48
    }

    return <>
        <FancyDialog
            {...infoDialog}
            open={true}
            icon={<ConnectedExecutableNodePreview id={node.id}/>}
            onClickAway={handleClickAway}
        >
            {controllerSettingsType && <div style={{pointerEvents: 'auto'}}>
                {
                    React.createElement(controllerSettingsType.settingsContent, {
                        id,
                        controllerState: controller,
                        onChange: (state: any) => store.dispatch(updateController({id, state}))
                    })
                }
            </div> || (nodeType?.description + ". " + loremIpsum(1))}
        </FancyDialog>
    </>
}

export default function FancyDialogs(props: FancyDialogsProps) {
    const dialog = useTypedSelector(state => state.dialog.activeDialog);
    const style = useWindowStyle();

    if (!dialog) return null;

    if (dialog.type === 'node info') {
        let id = (dialog as NodeInfoDialogActionProps).nodeId;
        if (!id) return null;
        return <FancyNodeInfoDialog id={id}/>
    }

    const handleCloseCancel = (s: string) => {
        if (s === 'x' || s === 'Cancel' || s === 'Close') {
            store.dispatch(closeDialog())
        }
    }

    const handleClickAway = () => store.dispatch(closeDialog())

    const errorDialog: FancyWindowProps = {
        shadowColor: '#ff002590',
        pointTo: [],
        title: 'Error!',
        answerButtons: ['Close'],
        callback: handleCloseCancel,
        resizable: true,
        header: true,
        //x: 0,
        //y: 0,
        highlightColor: '#ff0025',
        icon: 'exception'
    }

    const infoDialog: FancyWindowProps = {
        shadowColor: style.shadow.color,
        pointTo: [],
        title: 'Info',
        answerButtons: ['Close'],
        callback: handleCloseCancel,
        resizable: true,
        header: true,
        icon: 'info',
        iconSize: 48
    }

    const styleMap: {[key in DialogTypeMap as string]: FancyWindowProps} = {
        "node info": infoDialog,
        "error": errorDialog
    }

    const dialogStyle = styleMap[dialog.type];

    return <>
        <FancyDialog
            {...dialogStyle}
            open={true}
            icon={dialog.type === 'node info' ? <ConnectedExecutableNodePreview id={dialog?.nodeId}/> : dialogStyle.icon}
            onClickAway={handleClickAway}
        >
            {dialog?.children || loremIpsum(1)}
        </FancyDialog>
    </>
}