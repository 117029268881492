import {useEffect, useRef, useState} from "react";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function useWindowDimensionUpdate() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export function useStopScroll() {
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const scrollElement = scrollRef.current
        scrollElement?.addEventListener('wheel', stopScroll)
        return () => scrollElement?.removeEventListener('wheel', stopScroll)
    }, []);

    const stopScroll = (e: WheelEvent) => e.preventDefault();

    return scrollRef;
}