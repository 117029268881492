import {useEffect, useState} from "react";
import store from "../../store";
import {setActiveDialogCallback} from "../../store/workspace";
import deepEqual from "deep-equal";

export function useResetClose<S>(value: S, onChange: (value: S) => any): [S]{
    const [initial, setInitial] = useState<S | undefined>(undefined);

    useEffect(() => {
        let initialState = {...value}
        setInitial(initialState);
        store.dispatch(setActiveDialogCallback("Reset", () => {
            onChange(initialState);
        }, true));
    }, []);

    useEffect(() => {
        if (initial === undefined) {
            let initialState = {...value}
            setInitial(initialState);
            store.dispatch(setActiveDialogCallback("Reset", () => {
                onChange(initialState);
            }, true));
        } else {
            let noChange = deepEqual(initial, value);

            store.dispatch(setActiveDialogCallback("Reset", () => {
                onChange(initial);
            }, noChange));
        }
    }, [value]);

    return [initial ?? value];
}