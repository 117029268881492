import {InputBaseProps} from "@mui/material/InputBase/InputBase";
import {InputBase} from "@mui/material";
import React, {ReactNode} from "react";

const LabeledComponent = (props: {indent: number | string, label?: string, children?: ReactNode}) => {
    let indent: number | string;
    let rest: number | string;

    if (typeof props.indent === "string") {
        if (props.indent.endsWith("%")) {
            indent = props.indent
            let inum = Number.parseFloat(indent);
            rest = "" + (100 - inum) + "%";
        } else {
            indent = props.indent
            rest = "calc(100% - " + indent + ")";
        }
    } else {
        indent = props.indent
        rest = "calc(100% - " + indent + "px)";
    }

    return <>
        <div style={{position: 'absolute', left: 0, top: 5, width: indent}}>{props.label}</div>
        <div style={{position: 'absolute', left: indent, width: rest}}>{props.children}</div>
    </>
}

export default LabeledComponent;