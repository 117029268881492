import {CSSProperties, useState} from "react";
import {useStyle} from "../../hooks";
import store, {useTypedSelector} from "../../store";
import {setZoomTranslate} from "../../store/workspace";

export default ({style, ...props}: {scale?: number, style?: CSSProperties}) => {
    let scale = props.scale || 1;
    const [hovered, setHovered] = useState<boolean>(false);

    const handleHover = () => setHovered(true);
    const handleUnhover = () => setHovered(false);
    const handleClick = () => {
        let newZoom = 1 / scale;
        let pos = store.getState().transform;
        let zoom = pos.zoom;
        let x = pos.x;
        let y = pos.y;

        let w = window.innerWidth;
        let h = window.innerHeight;

        let steps = 5;
        let interval = 50;

        let recursiveTimeout = (i: number) => {
            let d = i / steps;
            let stepZoom = zoom + (newZoom - zoom) * d * d;
            const ratio = 1 - stepZoom / zoom;

            store.dispatch(setZoomTranslate(
                x + (w / 2 - x) * ratio,
                y + (h / 2 - y) * ratio,
                stepZoom
            ));

            if (i === steps) return;

            setTimeout(() => recursiveTimeout(i + 1), interval / steps);
        }

        recursiveTimeout(0);

        /*
        for (let i = 0; i <= steps; i++) {
            setTimeout(function () {
                let d = i / steps;
                let stepZoom = zoom + (newZoom - zoom) * d * d;
                const ratio = 1 - stepZoom / zoom;

                store.dispatch(setZoomTranslate(
                    x + (w / 2 - x) * ratio,
                    y + (h / 2 - y) * ratio,
                    stepZoom
                ));
            }, i * interval / steps)
        }
        */
        /*
        const ratio = 1 - newZoom / pos.zoom;

        store.dispatch(setZoomTranslate(
            pos.x + (w / 2 - pos.x) * ratio,
            pos.y + (h / 2 - pos.y) * ratio,
            newZoom
        ));
         */
        //store.dispatch(setZoom(1 / scale));
    }

    const zoom = useTypedSelector(state => state.transform.zoom)
    const nodeStyle = useStyle();

    return <div
        style={{
            cursor: 'pointer',
            opacity: hovered ? 1.0 : 0.5,
            padding: 1,
            color: nodeStyle.palette.default.light,
            fontFamily: 'Open Sans',
            fontSize: 12,
            ...style
        }}
        onClick={handleClick}
        onMouseEnter={handleHover}
        onMouseLeave={handleUnhover}
    >
        {Math.round(zoom * scale * 100) + "%"}
    </div>;
}