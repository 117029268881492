import {InputBase} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {InputBaseProps} from "@mui/material/InputBase/InputBase";
import {AnimatePresence, motion} from "framer-motion";
import {useMousePosition} from "../../hooks/input/events";
import {dist} from "../../common/math";
import {useColorFade, useStyle, useWindowStyle} from "../../hooks";
import {getCurrentTimeInMillis} from "../../common/utils";
import store, {useTypedSelector} from "../../store";
import {setZoomTranslate} from "../../store/workspace";

const BlinkingDot = ({color, x, y, r}: {color: string, x: number, y: number, r: number}) => {
    const [on, setOn] = useState<boolean>(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setOn(true);
            setTimeout(() => setOn(false), 250);
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    return on ? <circle r={r} cx={x} cy={y} fill={color}/> : null
}

const TriggeredBlinkingDot = ({color, x, y, r, trigger}: {color: string, x: number, y: number, r: number, trigger: any}) => {
    const [on, setOn] = useState<boolean>(false);

    useEffect(() => {
        setOn(true);
        setTimeout(() => setOn(false), 150);
    }, [trigger]);

    return on ? <circle r={r} cx={x} cy={y} fill={color}/> : null
}

const Eyes = ({eyePosL, eyePosR, lerp}: { eyePosL: [number, number], eyePosR: [number, number], lerp?: number }) => {
    const style = useWindowStyle();
    const eyeIris = style.mascot.eyes.iris;

    return <>
        <motion.g animate={{x: 37 + eyePosL[0] * 5, y: 50 + eyePosL[1] * 5}} transition={{duration: lerp ?? 0}}>
            <circle r={6.5} cx={0} cy={0} fill={eyeIris.ghost}/>
            <circle r={4.5} cx={0} cy={0} fill={eyeIris.main}/>
            <circle r={2.5} cx={0} cy={0} fill={"#000000"}/>
        </motion.g>
        <motion.g animate={{x: 63 + eyePosR[0] * 5, y: 50 + eyePosR[1] * 5}} transition={{duration: lerp ?? 0}}>
            <circle r={6.5} cx={0} cy={0} fill={eyeIris.ghost}/>
            <circle r={4.5} cx={0} cy={0} fill={eyeIris.main}/>
            <circle r={2.5} cx={0} cy={0} fill={"#000000"}/>
        </motion.g>
    </>
}

let lastAction = getCurrentTimeInMillis();

const RobotIcon = ({focus, active, ledTrigger}: {focus?: [number, number], active?: boolean, ledTrigger?: any}) => {
    const mousePos = useMousePosition("mousemove", "touchmove");
    const svgRef = useRef<SVGSVGElement>(null);
    const [eyePosL, setEyePosL] = useState<[number, number]>([0, 0]);
    const [eyePosR, setEyePosR] = useState<[number, number]>([0, 0]);

    const [sleeping, setSleeping] = useState<boolean>(false);
    const [blinking, setBlinking] = useState<boolean>(false);

    const [lerp, setLerp] = useState<number>(0);

    const setEyePosDyn = (to: [number, number], l: number = 250) => {
        setLerp(l / 1000);
        setEyePosL(to);
        setEyePosR(to);
    }

    const blink = () => {
        setBlinking(true);
        setTimeout(() => setBlinking(false), 150);
    }

    const wakeUp = () => {
        let now = getCurrentTimeInMillis();
        setSleeping(false);
        setLerp(0);
        lastAction = now;

        setTimeout(() => {
            if (lastAction === now) {
                setEyePosDyn([0, 0])
            }
        }, 4000);

        setTimeout(() => {
            if (lastAction === now) {
                blink()
            }
        }, 5000);

        setTimeout(() => {
            if (lastAction === now) {
                let x = Math.random() * 1.5 - .75;
                let y = Math.random() * 1.5 - .75;
                setEyePosDyn([x, y], 350)
            }
        }, 8000);

        setTimeout(() => {
            if (lastAction === now) {
                let x = Math.random() - .5;
                let y = Math.random() - .5;
                setEyePosDyn([x, y + .25], 450)
            }
        }, 10000);

        setTimeout(() => {
            if (lastAction === now) {
                let x = Math.random() * .5 - .275;
                let y = Math.random() * .5 - .25;
                setEyePosDyn([x, y - .5], 1200);
                setLerp(0);
            }
        }, 14000);

        setTimeout(() => {
            if (lastAction === now) {
                setSleeping(true);
            }
        }, 12500);
    }

    const style = useWindowStyle();
    const fill = style.palette.default.light;

    const setEyePositions = (x: number, y: number, fx: number, fy: number) => {
        let dl = dist(fx, fy, x - 13, y);
        if (dl === 0) {
            setEyePosL([0, 0]);
        } else {
            let dx = (fx - (x - 13)) / dl;
            let dy = (fy - y) / dl;
            setEyePosL([dx, dy]);
        }

        let dr = dist(fx, fy, x + 13, y);
        if (dr === 0) {
            setEyePosL([0, 0]);
        } else {
            let dx = (fx - (x + 13)) / dr;
            let dy = (fy - y) / dr;
            setEyePosR([dx, dy]);
        }

        wakeUp();
    }

    useEffect(() => {
        if (svgRef.current && focus) {
            let bbox = svgRef.current.getBoundingClientRect();
            let x = bbox.left + bbox.width / 2;
            let y = bbox.top + bbox.height / 2;

            setEyePositions(x, y, focus[0], focus[1]);
        }
    }, [focus])

    useEffect(() => {
        if (svgRef.current && mousePos.x !== null && mousePos.y !== null) {
            let bbox = svgRef.current.getBoundingClientRect();
            let x = bbox.left + bbox.width / 2;
            let y = bbox.top + bbox.height / 2;

            setEyePositions(x, y, mousePos.x, mousePos.y);
        }
    }, [svgRef, mousePos]);

    const eyeBackground = style.mascot.eyes.color.main; // "#007fff80"
    const eyeShadow = style.mascot.eyes.shadow; //"#607890"
    const eyeIris = style.mascot.eyes.iris; //"#607890"

    let eyesClosed = sleeping || blinking;
    let eyeCloseFn = (x: number) => x * .9 + .1 * (0.5 - Math.cos(x * x * Math.PI * 5) / 2)

    const transition = { duration: eyesClosed && !blinking ? 2.5 : .15, ease: blinking ? undefined : eyeCloseFn }
    const transition2 = { duration: eyesClosed ? 2.5 : .15, ease: eyeCloseFn }

    return <svg ref={svgRef} style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2
    }} viewBox={"0 0 100 100"}>
        <defs>
            <linearGradient id="background-gradient" x1={0} x2={.25} y1={0} y2={1}>
                <stop offset="20%" stopColor={style.mascot.background[0]}/>
                <stop offset="100%" stopColor={style.mascot.background[1]}/>
            </linearGradient>
            <clipPath id="close-eyes">
                <motion.rect
                    key={"close-eyes"}
                    x={0}
                    y={0}
                    width={100}
                    height={.5}
                    initial={{
                        height: eyesClosed ? .5 : 32,
                        y: eyesClosed ? 53 : 37
                    }}
                    animate={{
                        height: eyesClosed ? .5 : 32,
                        y: eyesClosed ? 53 : 37
                    }}
                    transition={transition}
                />
            </clipPath>
            <clipPath id="eye">
                <circle r={12} cx={37} cy={50}/>
                <circle r={12} cx={63} cy={50}/>
            </clipPath>
        </defs>

        {/*<g style={{opacity: .5}}>
            <circle r={13.5} cx={36.5} cy={51} fill={fill}/>
            <circle r={13.5} cx={63.5} cy={51} fill={fill}/>
        </g>*/}

        <circle r={12} cx={37} cy={50} strokeOpacity={0} fillOpacity={.5} fill={"url(#background-gradient)"}/>
        <circle r={12} cx={63} cy={50} strokeOpacity={0} fillOpacity={.5} fill={"url(#background-gradient)"}/>

        <g clipPath={"url(#close-eyes)"}> {/*transform={"rotate(6, 37, 50)"}>*/}
            <circle r={12} cx={37} cy={50} fill={eyeBackground}/>
            <circle r={12} cx={63} cy={50} fill={eyeBackground}/>

            {/*<motion.g
                initial={{opacity: eyesClosed ? 0 : 1}}
                animate={{opacity: eyesClosed ? 0 : 1}}
                transition={transition2}
                key={"eyes"}
            >
                <Eyes eyePosL={eyePosL} eyePosR={eyePosR}/>
            </motion.g>*/}

            <Eyes eyePosL={eyePosL} eyePosR={eyePosR} lerp={lerp}/>

            <circle r={11.5} cx={37} cy={50} stroke={eyeShadow.light} strokeWidth={1} fillOpacity={0}/>
            <circle r={11.5} cx={63} cy={50} stroke={eyeShadow.light} strokeWidth={1} fillOpacity={0}/>
            <circle r={11} cx={37} cy={50} stroke={eyeShadow.light} strokeWidth={2} fillOpacity={0}/>
            <circle r={11} cx={63} cy={50} stroke={eyeShadow.light} strokeWidth={2} fillOpacity={0}/>
            <circle r={10} cx={37} cy={50} stroke={eyeShadow.ghost} strokeWidth={4} fillOpacity={0}/>
            <circle r={10} cx={63} cy={50} stroke={eyeShadow.ghost} strokeWidth={4} fillOpacity={0}/>

            <circle r={2.2} cx={32} cy={45} fill={style.mascot.eyes.reflection}/>
            <circle r={1.2} cx={30} cy={49} fill={style.mascot.eyes.reflection}/>
            {/*<circle r={1.4} cx={36} cy={43} fill={"#ffffff80"}/>*/}

            <circle r={2.2} cx={58} cy={45} fill={style.mascot.eyes.reflection}/>
            <circle r={1.2} cx={56} cy={49} fill={style.mascot.eyes.reflection}/>
            {/*<circle r={1.4} cx={62} cy={43} fill={"#ffffff80"}/>*/}

            <g clipPath={"url(#eye)"}>
                <motion.circle
                    //clipPath={"url(#eye-l)"}
                    cx={50}
                    cy={50}
                    r={102}
                    initial={{
                        cy: 94 + (eyesClosed ? 58 : 37)
                    }}
                    animate={{
                        cy: 94 + (eyesClosed ? 58 : 37)
                    }}
                    transition={transition}
                    fillOpacity={0}
                    stroke={"#7090b040"}
                    strokeWidth={12}
                />
                <motion.circle
                    //clipPath={"url(#eye-l)"}
                    cx={50}
                    cy={50}
                    r={153}
                    initial={{
                        cy: 144 + (eyesClosed ? 58 : 37)
                    }}
                    animate={{
                        cy: 144 + (eyesClosed ? 58 : 37)
                    }}
                    transition={transition}
                    fillOpacity={0}
                    stroke={"#7090b080"}
                    strokeWidth={12}
                />
            </g>

            {/*<motion.circle
                r={12}
                cx={37}
                cy={50}
                strokeOpacity={0}
                fillOpacity={0}
                initial={{fillOpacity: eyesClosed ? 1 : 0}}
                animate={{fillOpacity: eyesClosed ? 1 : 0}}
                transition={transition2}
                fill={"#7090b0"}
            />
            <motion.circle
                r={12}
                cx={63}
                cy={50}
                strokeOpacity={0}
                fillOpacity={0}
                initial={{fillOpacity: eyesClosed ? 1 : 0}}
                animate={{fillOpacity: eyesClosed ? 1 : 0}}
                transition={transition2}
                fill={"#7090b0"}
            />*/}
        </g>

        <circle r={1.5} cx={47.5} cy={62} fill={fill}/>
        <circle r={1.5} cx={52.5} cy={62} fill={fill}/>

        <path
            d="M86,37.408L86,19.917C83.164,19.44 81,16.971 81,14C81,10.689 83.689,8 87,8C90.311,8 93,10.689 93,14C93,16.971 90.836,19.44 88,19.917L88,37.904C93.877,39.779 95,44.389 95,50C95,57.056 93.153,62.529 82.441,62.971C77.825,73.396 66.979,84 50,84C32.993,84 22.139,73.435 17.537,62.971C6.774,62.524 5,57.053 5,50C5,44.4 6.164,39.796 12,37.914L12,19.917C9.164,19.44 7,16.971 7,14C7,10.689 9.689,8 13,8C16.311,8 19,10.689 19,14C19,16.971 16.836,19.44 14,19.917L14,37.413C15.074,37.211 16.273,37.08 17.609,37.027C22.317,26.564 33.32,16 50,16C66.652,16 77.647,26.603 82.369,37.026C83.714,37.077 84.919,37.207 86,37.408ZM50,26C69.953,26 77,33.96 77,50C77,66.04 70.173,74 50,74C29.827,74 23,66.414 23,50C23,33.586 30.047,26 50,26Z"
            strokeOpacity={0}
            fill={"url(#background-gradient)"}
            fillOpacity={.66667}
        />
        {/*<circle r={1.25} cx={16} cy={46} fill={fill}/>*/}
        <circle r={1.25} cx={15.8} cy={50} fill={fill}/>
        <circle r={1.25} cx={15.9} cy={54} fill={fill}/>
        {/*<circle r={1.25} cx={16.2} cy={57.9} fill={fill}/>*/}

        {/*<circle r={1.25} cx={84} cy={46} fill={fill}/>*/}
        <circle r={1.25} cx={84.2} cy={50} fill={fill}/>
        <TriggeredBlinkingDot r={1} x={84.2} y={50} color={"#007fff"} trigger={ledTrigger}/>
        <circle r={1.25} cx={84.1} cy={54} fill={fill}/>
        <BlinkingDot r={1} x={84.1} y={54} color={"#ff0000"}/>
        {/*<circle r={1.25} cx={83.8} cy={57.9} fill={fill}/>*/}

        <circle r={1.4} cx={11.2} cy={11.5} fill={style.mascot.eyes.reflection}/>
        <circle r={.8} cx={9.9} cy={14} fill={style.mascot.eyes.reflection}/>

        <circle r={1.4} cx={11.2 + 74} cy={11.5} fill={style.mascot.eyes.reflection}/>
        <circle r={.8} cx={9.9 + 74} cy={14} fill={style.mascot.eyes.reflection}/>
    </svg>
}

const ConnectMe = (props: InputBaseProps & {onConnected: (url: string) => any}) => {
    const [hovered, setHovered] = useState<boolean>(false);
    const handleHover = () => setHovered(true);
    const handleUnhover = () => setHovered(false);

    const[value, setValue] = useState<string>("");
    const[focus, setFocus] = useState<[number, number] | undefined>(undefined);

    const textRef = useRef<HTMLDivElement>(null);
    const style = useWindowStyle();

    useEffect(() => {
        if (textRef.current && value !== "") {
            let bbox = textRef.current.getBoundingClientRect();
            let x = bbox.left + bbox.width;
            let y = bbox.top + bbox.height / 2;

            setFocus([x, y])
        }
    }, [value])

    return <div
        style={{
            position: 'absolute',
            left: "50%",
            top: "50%",
            width: 32,
            height: 32,
            transform: "translate(-50%, -50%)"
        }}
        onMouseOver={handleHover}
        onMouseOut={handleUnhover}
    >
        <motion.div
            style={{
                position: 'absolute',
                left: "50%",
                top: 16,
                width: 128,
                height: 128,
                transform: "translate(-50%, -50%)",
                pointerEvents: "none"
            }}
            animate={{
                top: hovered ? -56 : 16
            }}
            exit={{
                top: 16
            }}
        >
            <motion.div initial={{opacity: 1}} exit={{opacity: 0}} transition={{delay: .5, duration: .5}}>
                <RobotIcon focus={focus} ledTrigger={value}/>
            </motion.div>
        </motion.div>
        <motion.div
            style={{
                position: "absolute",
                width: 0,
                height: 24,
                pointerEvents: 'auto',
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                opacity: 0
            }}
            animate={{
                width: hovered ? 200 : 0,
                opacity: hovered ? 1 : 0
            }}
            exit={{
                width: 0,
                opacity: 0
            }}
        >
            <div style={{
                position: 'absolute',
                top: -6,
                bottom: -6,
                left: -6 - 12,
                right: -6 - 12,
                borderRadius: 18,
                background: style.window.background,
                boxShadow: "inset 8px 8px 52px -28px " + style.shadow.color //"inset 8px 8px 40px -4px #00408040"
            }}/>
            <div style={{position: 'absolute', top: -2, left: -4, width: '100%'}}>
                <InputBase
                    {...props}
                    inputProps={{
                        onMouseDown: (e) => e.stopPropagation(),
                        style: value === "" ? {color: style.palette.default.main} : undefined
                    }}
                    onChange={e => {
                        setValue(e.target.value)
                    }}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            props.onConnected && props.onConnected(value);
                        }
                    }}
                    fullWidth
                    placeholder={"wss://"}
                    style={{
                        fontFamily: 'Roboto Mono',
                        fontSize: 13,
                        fontWeight: 'bold',
                        lineHeight: '12px',
                        color: style.window.color
                        //background: theme.components.Input.background
                    }}/>
                <div ref={textRef} style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: 0,
                    fontFamily: 'Roboto Mono',
                    fontSize: 13,
                    pointerEvents: 'none'
                }}>{value}</div>
            </div>
        </motion.div>
    </div>
}

const Blocker = () => <div style={{
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'auto',
    display: 'block',
    zIndex: 0
}}/>

export default ({onConnected}: {onConnected: (url: string) => any}) => {
    const machineInitialized = useTypedSelector(state => !!state.nodes.machineState.initialized)

    return <AnimatePresence>{!machineInitialized && <><Blocker/><ConnectMe onConnected={onConnected}/></>}</AnimatePresence>
}