import {Edge} from "../../store/nodes";
import {useTypedSelector} from "../../store";
import FancyEdge from "./FancyEdge";
import React from "react";

const FancyEdges = ({skipHighlighted, skipUnhighlighted, filter}: {
    skipHighlighted?: boolean,
    skipUnhighlighted?: boolean,
    filter?: (edge: Edge) => boolean
}) => {
    let edges = useTypedSelector(state => {
        if (!filter) return state.nodes.edges;
        else return Object.fromEntries<Edge>(
            Object.entries(state.nodes.edges).filter(
                ([, e]) => filter(e)
            )
        )
    });

    return <>
        {Object.keys(edges).map(key => edges[key] ? <FancyEdge
            id={key}
            key={key}
            skipHighlighted={skipHighlighted}
            skipUnhighlighted={skipUnhighlighted}
        /> : null)}
    </>
}

export default FancyEdges;