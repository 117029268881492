import React, {
    ChangeEvent,
    DetailedHTMLProps,
    HTMLAttributes,
    useEffect,
    useLayoutEffect,
    useRef,
    useState
} from "react";
import {DraggableData, DraggableEvent} from "react-draggable";
import {useForceUpdate, useResizeObserver, useStyle, useWindowStyle} from "../../../hooks";
import FancyWindow, {FancyWindowProps} from "../dialogs/FancyWindow";
import ClickAway from "../ClickAway";
import {InputBase} from "@mui/material";
import {
    ExecutableNodeType,
    iteratePackages, iteratePackagesIndexed,
    NodeType,
    NodeTypePackages
} from "../../../common/types/nodeTypes";
import {ExecutableNodeTypePreview} from "../../nodes/FancyExecutableNode";
import styled from "styled-components";
import {AnimatePresence, motion} from "framer-motion";
import {Type, Types} from "../../../common/types/types";
import store, {useTypedSelector} from "../../../store";
import {setConnectionLine} from "../../../store/nodes";
import {getNodeTypes} from "../../../common/machine/MachineHandler";

const HighlightedText = ({text, highlight}: {text: string, highlight?: string}) => {
    let highlightColor = useWindowStyle().palette.highlight.main;
    if (!highlight) return <>{text}</>;

    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span>{parts.map((part, i) =>
        <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? {fontWeight: 'bold', color: highlightColor} : {}}>
            {part}
        </span>)
    }</span>;
}

const ExecutableNodeTypeItem = ({type, zIndex, onClick, highlight, highlightPorts}: {
    type: ExecutableNodeType,
    zIndex?: number,
    onClick?: () => {},
    highlight?: string,
    highlightPorts?: {in?: boolean[], out?: boolean[]}
}) => {
    const iconSize = 36;
    const itemPadding = 4;
    const hoverPadding = 3;
    const windowStyle = useWindowStyle();

    /*
    const [hovered, setHovered] = useState<boolean>();

    const handleMouseEnter = () => {setHovered(true)}
    const handleMouseLeave = () => {setHovered(false)}
    */

    return <div
        style={{
            position: 'relative',
            width: "calc(100% - " + itemPadding * 2 + "px)",
            height: iconSize,
            padding: itemPadding,
            cursor: 'pointer',
            pointerEvents: 'none',
            zIndex
        }}
        /*
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
         */
        onClick={onClick}
    >
        <div style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '200%',
            height: '200%',
            transform: 'scale(0.5) translate(-50%, -50%)'
        }}>
            <motion.div
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    background: windowStyle.window.transparentBackground,
                    opacity: 1,
                    pointerEvents: 'auto'
                }}
                whileHover={{
                    left: hoverPadding * 2,
                    top: hoverPadding * 2,
                    right: hoverPadding * 2,
                    bottom: hoverPadding * 2,
                    opacity: .8
                }}
            />
        </div>
        <div style={{position: 'absolute', width: iconSize, height: iconSize, left: itemPadding, top: itemPadding}}>
            <ExecutableNodeTypePreview
                type={type}
                scale={28}
                style={{position: 'absolute', left: iconSize / 2, top: iconSize / 2}}
                highlightPorts={highlightPorts}
            />
        </div>
        <div style={{
            position: 'absolute',
            height: iconSize,
            width: "calc(100% - " + (itemPadding * 3 + iconSize) + "px)",
            left: itemPadding * 3 + iconSize,
            top: itemPadding + 4,
            color: windowStyle.window.color,
            lineHeight: '14px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        }}>
            <span style={{
                fontFamily: 'Montserrat',
                fontWeight: 800,
                fontSize: 12
            }}>
                {type.name && <HighlightedText text={type.name} highlight={highlight}/>}
            </span><br/>
            <span style={{
                fontFamily: 'Open Sans',
                fontSize: 12
            }}>
                {(type.description && <HighlightedText text={type.description} highlight={highlight}/>) || "Adfh sajd klfajs dh fj. sdlkf dfjs"}
            </span>
        </div>
    </div>
}

export interface TypedNodeFilter {
    query?: string
    type?: Type | Types
    input?: (Type | Types)[]
    output?: (Type | Types)[]
}

export interface ExecutableNodeTypeListProps {
    types: NodeTypePackages
    filter?: TypedNodeFilter
    onSelectItem?: (type: ExecutableNodeType, key: string, pckg: string, index: number) => any
}

const ExecutableNodeTypeList = ({types, onSelectItem, filter, ...props}: ExecutableNodeTypeListProps & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const handleSelect = onSelectItem ?? (() => {});
    const windowStyle = useWindowStyle();

    let query = filter?.query?.toLowerCase();

    let results: JSX.Element[] = [];
    iteratePackagesIndexed(types, (key, pckg, type, i, a) => {
    //Object.values(types).forEach((type, i, a) => {
        let highlightPorts = undefined;

        if (filter) {
            if (query) {
                if (!type.name?.toLowerCase().includes(query) && !type.description?.toLowerCase().includes(query)) {
                    return;
                }
            }

            if (filter.input) {
                if (!type.input || type.input.length < filter.input.length) return;
                let hIn: boolean[] = [];
                filter.input.forEach(i => hIn.push(!!i));
                highlightPorts = {in: hIn};
            }

            if (filter.output) {
                if (!type.output || type.output.length < filter.output.length) return;
                let hOut: boolean[] = [];
                filter.output.forEach(o => hOut.push(!!o));
                highlightPorts = highlightPorts ? {...highlightPorts, out: hOut} : {out: hOut};
            }
        }

        results.push(<ExecutableNodeTypeItem
            type={type}
            zIndex={a.length - i}
            onClick={() => handleSelect(type, key, pckg, i)}
            highlight={filter?.query ? filter.query : undefined}
            highlightPorts={highlightPorts}
        />);
    });

    return <>{
        results.length > 0 ? results : <span style={{
            position: 'absolute',
            height: '100%',
            marginBottom: '-100%',
            fontFamily: 'Open Sans',
            fontSize: 12,
            width: '100%',
            textAlign: 'center',
            color: windowStyle.palette.default.ghost
        }}>nothing found!</span>
    }</>; //</div>;
}

const MemoExecutableNodeTypeList = React.memo(ExecutableNodeTypeList, (prev: ExecutableNodeTypeListProps, next: ExecutableNodeTypeListProps) => {
    return prev.filter?.query === next.filter?.query
})

const ExecutableNodeTypeListScroller = ({filter, onSelectItem, types}: {
    filter?: TypedNodeFilter,
    onSelectItem?: (type: ExecutableNodeType, key: string, pckg: string) => any,
    types: NodeTypePackages
}) => {
    const boxRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const windowStyle = useWindowStyle();

    const ScrollDiv = styled.div`
      ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: ${windowStyle.window.background};
      }

      ::-webkit-scrollbar {
        width: 8px;
        background-color: ${windowStyle.window.transparentBackground};
      }
    `;

    return <div ref={boxRef} style={{minHeight: 248 + 14, marginTop: 48 - 14, paddingBottom: 8}}>
        <div style={{
            position: 'absolute',
            top: 2,
            left: 16,
            right: 16,
            bottom: 16,
            background: 'transparent', //windowStyle.palette.background.ghost,
            //overflowY: listFiller <= 0 ? 'scroll' : 'clip',
            overflowY: 'clip',
            overflowX: 'clip',
            pointerEvents: 'auto',
            display: 'flex',
            flexFlow: 'column',
            height: 'calc(100% - 18px)'
        }}>
            <ScrollDiv ref={listRef} style={{
                height: 'auto',
                overflow: 'auto',
                flex: '0 1 auto'
            }}>
                <MemoExecutableNodeTypeList
                    types={types}
                    filter={filter}
                    onSelectItem={onSelectItem}
                />
            </ScrollDiv>
            <div style={{
                flex: '1 1 auto',
                width: '100%',
                background: windowStyle.window.transparentBackground,
                //height: listFiller
            }}/>
        </div>
    </div>
}

const NodeSearch = ({position, onClose, onSelect, disableClickAway, ...props}: {
    position: number[],
    onClose?: () => any,
    onSelect?: (type: NodeType, name: string, pckg: string) => any,
    disableClickAway?: boolean,
    filter?: TypedNodeFilter
}) => {
    const [disposed, setDisposed] = useState<boolean>(false);
    const connectionLine = useTypedSelector(state => state.nodes.connectionLine);

    const update = useForceUpdate();

    const handleDisposeTyper = () => {
        setDisposed(true);
        onClose && onClose();
    }
    const handleClickAway = () => {
        if (!disableClickAway) handleDisposeTyper();
    }
    const handleTyperBlur = () => {
        if (!store.getState().nodes.connectionLine) {
            handleDisposeTyper();
        }
    }
    const handleSelect = (type: ExecutableNodeType, key: string, pckg: string) => {
        setDisposed(true);
        onSelect && onSelect(type, key, pckg);
    }
    const [value, setValue] = useState<string>("");
    const [delta, setDelta] = useState<number[]>([0, 0]);
    const [filter, setFilter] = useState<TypedNodeFilter | undefined>(props.filter);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)
    const handleDrag = (e: DraggableEvent, {deltaX, deltaY}: DraggableData) => setDelta(d => {
        d[0] += deltaX;
        d[1] += deltaY;
        return [...d];
    });

    const style = useStyle();
    const windowStyle = useWindowStyle();

    const typerDialogStyle: FancyWindowProps = {
        shadowColor: style.shadow.color,
        callback: handleDisposeTyper,
        resizable: true,
        header: false
    }

    return <>
        {/*<ClickAway onClick={handleClickAway} disabled/>*/}
        {position && (value.length > 2 || (filter && (filter.input || filter.output))) && <FancyWindow
            {...typerDialogStyle}
            x={position[0] - (windowStyle.window?.windowControlBarPadding ?? 0) - 3}
            y={position[1] - (windowStyle.window?.windowControlBarPadding ?? 0) - 12}
            width={360}
            onDrag={handleDrag}
            hollow
            paddingTop={2}
        >
            {disposed && <div style={{
                position: 'absolute',
                left: (windowStyle.window?.windowControlBarPadding ?? 0) + 3,
                top: (windowStyle.window?.windowControlBarPadding ?? 0) - 21
            }}>
                <div style={{
                    transform: 'translateY(-50%)',
                    border: 0,
                    background: 'transparent', //'#00204020',
                    //width: 'auto',
                    fontFamily: 'Roboto Mono',
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: '14px',
                    color: style.components.Input.color
                }}>
                    {value}
                </div>
            </div>}
            <ExecutableNodeTypeListScroller
                types={getNodeTypes()}
                filter={{query: value, input: filter?.input, output: filter?.output}}
                onSelectItem={handleSelect}
            />
            {connectionLine && <div
                style={{
                    position: 'absolute',
                    left: 0,
                    top: -32,
                    right: 0,
                    bottom: 0,
                    background: '#ff000020',
                    zIndex: 9999,
                    pointerEvents: 'auto'
                }}
                onMouseUp={() => {
                    setFilter(f => f && {...f, input: f.input ? [...f.input, []] : [[]]});
                    store.dispatch(setConnectionLine(undefined));
                    update();
                }}
            />}
        </FancyWindow>}
        {position && !disposed && <div style={{
            position: 'absolute',
            left: position[0] + delta[0],
            top: position[1] + delta[1],
            zIndex: 9999
        }}><InputBase
            style={{
                transform: 'translateY(-50%)',
                border: 0,
                background: 'transparent', //'#00204020',
                //width: 'auto',
                fontFamily: 'Roboto Mono',
                fontSize: 14,
                fontWeight: 400,
                lineHeight: '14px',
                color: style.components.Input.color,
                width: (value.length + 1) + 'ch'
            }}
            type="text"
            value={value}
            onChange={handleChange}
            onBlur={handleTyperBlur}
            onKeyDown={e => e.key === 'Escape' && handleDisposeTyper()}
            autoFocus
        /></div>}
    </>
}

export default NodeSearch;