import React from "react";
import {ExecutableNode, PortPosition} from "../../store/nodes";
import {useTypedSelector} from "../../store";
import {useNodeStyle, useStyle} from "../../hooks";
import {_scale} from "./index";

const FancyPortLabel = ({nodeId, direction, index}: {
    nodeId: string
} & PortPosition) => {
    const connected = useTypedSelector(
        state => direction === 'out' ?
            state.nodes.forwardAdjacency[nodeId]
            && state.nodes.forwardAdjacency[nodeId][index]
            :
            state.nodes.backwardAdjacency[nodeId]
            && state.nodes.backwardAdjacency[nodeId][index]
    );

    const data = useTypedSelector(
        state => {
            let exn = state.nodes.nodes[nodeId] as ExecutableNode;
            return direction === 'out' ?
                exn.output && exn.output[index].data :
                exn.input && exn.input[index].data
        }
    );

    const shell = useTypedSelector(
        state => {
            let exn = state.nodes.nodes[nodeId] as ExecutableNode;
            return direction === 'out' ?
                exn.output && exn.output[index].shell :
                exn.input && exn.input[index].shell
        }
    );

    const hovered = useTypedSelector(state => state.nodes.hovered);
    const hoveredPort = useTypedSelector(state => hovered ? state.nodes.hoveredPort : undefined);

    const style = useStyle();

    const portSize = 6 * _scale;
    const portDist = 12 * _scale;
    const shellDist = 3 * _scale;

    const inputPosition = {
        left: -portSize / 2 - (!!shell ? shellDist : 0),
        top: (index + 1) * portDist
    }

    const outputPosition = {
        right: -portSize / 2 - (!!shell ? shellDist : 0),
        top: (index + 1) * portDist
    }

    const position = direction === 'in' ? inputPosition : outputPosition

    const isHovered = hoveredPort && hovered === nodeId && hoveredPort.direction === direction && hoveredPort.index === index;

    return (!isHovered && connected) || !data ? null : <div
        style={{
            ...position,
            position: 'absolute',
            width: 0,
            height: 0,
            pointerEvents: 'none'
        }}
    >
        <div style={{
            position: 'absolute',
            fontFamily: 'Roboto Mono',
            fontWeight: 400,
            fontSize: 18,
            color: style.components.Port.color,
            top: -.5,
            right: direction === 'in' ? 4 : undefined,
            left: direction === 'out' ? 4 : undefined,
            textAlign: direction === 'in' ? 'right' : 'left',
            whiteSpace: 'nowrap',
            transform: 'translateY(-50%)',
            background: 'transparent'
        }}>
            {data.value}
        </div>
    </div>
}

export default FancyPortLabel;