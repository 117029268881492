import {
    CloseDialogAction,
    DialogActionProps,
    DialogActionType, DialogTypeMap,
    OpenDialogAction, SetActiveDialogCallbackAction, SetZoomTranslateAction, ZoomTranslateActionType
} from "./types";

export const openDialog = (mode: DialogActionProps<keyof DialogTypeMap>): OpenDialogAction => ({
    type: DialogActionType.OPEN,
    payload: mode
});

export const closeDialog = (): CloseDialogAction => ({
    type: DialogActionType.CLOSE
});

export const setActiveDialogCallback = (key: string, callback: (() => any) | undefined, disabled?: boolean): SetActiveDialogCallbackAction => ({
    type: DialogActionType.SETCALLBACK,
    payload: {key, callback, disabled}
});

export const setZoom = (zoom: number): SetZoomTranslateAction => ({
    type: ZoomTranslateActionType.SET,
    payload: {zoom}
});

export const setTranslate = (x: number, y: number): SetZoomTranslateAction => ({
    type: ZoomTranslateActionType.SET,
    payload: {x, y}
});

export const setZoomTranslate = (x: number, y: number, zoom: number): SetZoomTranslateAction => ({
    type: ZoomTranslateActionType.SET,
    payload: {x, y, zoom}
});