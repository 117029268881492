import {Node} from "../../store/nodes";
import {useTypedSelector} from "../../store";
import {useZoom} from "../workspace/ZoomTranslate";
import {constrain} from "../../common/math";
import FancyExecutableNodeLabels from "./FancyExecutableNodeLabels";
import React from "react";

const FancyLabels = ({filter, forceUpdate, doNotFade}: {
    filter?: (n: Node) => boolean,
    forceUpdate?: boolean,
    doNotFade?: boolean
}) => {
    let nodes = useTypedSelector(state => {
        if (!filter) return state.nodes.nodes;
        else return Object.fromEntries<Node>(
            Object.entries(state.nodes.nodes).filter(
                ([, n]) => filter(n)
            )
        )
    });
    let zoom = useZoom();

    let thresh = 0.5;

    if (zoom < thresh) return null;

    return <div style={{opacity: doNotFade ? undefined : constrain((zoom - thresh) * 8, 0, 1)}}>
        {Object.keys(nodes).map(key => {
            let node = nodes[key];
            if ('width' in node.shape) {
                return <FancyExecutableNodeLabels id={key} key={key} forceUpdate={forceUpdate}/>
            } else return null;
        })}
    </div>
}

export default FancyLabels;