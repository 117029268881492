import {MutableRefObject, useContext, useEffect, useState} from "react";
import {NodeStyleContext} from "../components/style/FancyNodeStyleProvider";
import {StyleContext} from "../components/style/FancyStyleProvider";
import {TypedUseSelectorHook, useSelector} from "react-redux";
import {useTypedSelector} from "../store";
import {FancyNodeStyleSheet} from "../components/style";
import {ExecutableNode} from "../store/nodes";

export function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const toRect = (rect: DOMRect | undefined) => {
    const width = rect?.width || 0,
        height = rect?.height || 0,
        x = rect?.x || 0,
        y = rect?.y || 0;
    return {width, height, x, y};
};

export const useResizeObserver = (
    ref: MutableRefObject<HTMLElement | null>
) => {
    const [rect, setRect] = useState(
        toRect(ref.current?.getBoundingClientRect())
    );
    useEffect(() => {
        const ob = new ResizeObserver(() =>
            setRect(toRect(ref.current?.getBoundingClientRect()))
        );
        ob.observe(ref.current!);
        return () => ob.disconnect();
    }, [ref]);

    return rect;
};

export type RGBA = { r: number, g: number, b: number, a: number }
export function getRGBA(hexc: string): RGBA {
    if (hexc.startsWith("rgb")) {
        let str = hexc;
        str = str.replace("rgb", "");
        str = str.replace("a", "");
        str = str.replace("(", "");
        str = str.replace(")", "");
        str = str.replaceAll(" ", "");
        let strs = str.split(",");
        return {r: +strs[0], g: +strs[1], b: +strs[2], a: (+strs[3] || 1) * 255}
    }
    let hex = hexc.replace('#', '');

    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    if (hex.length === 4) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
    }

    if (hex.length === 6) {
        hex += "ff";
    }

    let r = parseInt(hex.substring(0, 2), 16),
        g = parseInt(hex.substring(2, 4), 16),
        b = parseInt(hex.substring(4, 6), 16),
        a = parseInt(hex.substring(6, 8), 16);

    return {r, g, b, a}
}

export function useColorFade(init: string, duration: number = 1000, fps: number = 60): [string, (hex: string) => any, (hex: string) => any] {
    let initRGBA = getRGBA(init);
    const [color, setColor] = useState<RGBA>(initRGBA);
    const [colorTarget, setColorTarget] = useState<RGBA>(initRGBA);

    const [colorString, setColorString] = useState<string>(init);
    const setColorTargetString = (hex: string) => setColorTarget(getRGBA(hex));
    const setColorTargetStringNoFade = (hex: string) => {
        let rgba = getRGBA(hex);
        setColor(rgba);
        setColorTarget(rgba);
    }

    const compToString = (x: number) => {
        let str = Math.round(x).toString(16);
        if (str.length === 1) str = "0" + str;
        return str;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setColor(c => {
                let {r, g, b, a} = c;
                let r_ = r + (colorTarget.r - r) * .1;
                let g_ = g + (colorTarget.g - g) * .1;
                let b_ = b + (colorTarget.b - b) * .1;
                let a_ = a + (colorTarget.a - a) * .1;
                setColorString(
                    "#"
                    + compToString(r_)
                    + compToString(g_)
                    + compToString(b_)
                    + compToString(a_)
                )
                return {r: r_, g: g_, b: b_, a: a_}
            })
        }, 1000 / fps);
        return () => clearInterval(interval);
    }, [colorTarget]);

    return [colorString, setColorTargetString, setColorTargetStringNoFade];
}

export const useStack = (id: string) => useTypedSelector(state => {
    let result: ExecutableNode[] = [];
    let startNode = state.nodes.nodes[id] as ExecutableNode;
    while (startNode.prev) {
        startNode = state.nodes.nodes[startNode.prev] as ExecutableNode;
    }
    result.push(startNode);
    while (startNode.next) {
        startNode = state.nodes.nodes[startNode.next] as ExecutableNode;
        result.push(startNode);
    }
    return result;
});

export const useStyle = () => useContext(NodeStyleContext);
export const useNodeStyle = () => useContext(NodeStyleContext).components.Node;
export const useShadowStyle = () => useContext(NodeStyleContext).shadow;

export const useWindowStyle = () => useContext(StyleContext);

export const useStyleSelector: TypedUseSelectorHook<FancyNodeStyleSheet> = useSelector