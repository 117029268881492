import {CSSProperties, useState} from "react";

const clickAwayStyle: CSSProperties = {
    position: 'absolute',
    inset: 0,
    pointerEvents: 'auto',
    background: 'transparent'
}

const ClickAwayDisappearOnClick = ({onClick}: { onClick?: () => any }) => {
    const [open, setOpen] = useState<boolean>(true);

    const handleClick = () => setOpen(() => {
        onClick && onClick();
        return false;
    });

    return open ? <div
        onClick={handleClick}
        style={clickAwayStyle}
    /> : null;
}

const ClickAwayDefault = ({onClick}: { onClick?: () => any }) => <div
    onClick={onClick}
    style={clickAwayStyle}
/>;

export default ({onClick, disappearOnClick, disabled}: {
    disappearOnClick?: boolean,
    onClick?: () => any,
    disabled?: boolean
}) => {
    if (disabled) return null;
    return disappearOnClick ? <ClickAwayDisappearOnClick onClick={onClick}/> : <ClickAwayDefault onClick={onClick}/>
}