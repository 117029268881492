import {dist, getAngle, polarToCartesian} from "../math";

export function getStartVertexString(x: number, y: number): string {
    return "M " + x + " " + y;
}

export function getVertexString(x: number, y: number): string {
    return "L " + x + " " + y;
}

export function getBezierString(sx: number, sy: number, sbx: number, tbx: number, tx: number, ty: number): string {
    return "M " + sx + " " + sy + " C " + sbx + " " + sy + ", " + tbx + " " + ty + ", " + tx + " " + ty;
}

export function getBezierString_(sbx: number, sby: number, tbx: number, tby: number, tx: number, ty: number): string {
    return "C " + sbx + " " + sby + ", " + tbx + " " + tby + ", " + tx + " " + ty;
}

export function getBezierSegment(sx: number, sy: number, sbx: number, tbx: number, tx: number, ty: number): string {
    return "M " + sx + " " + sy + " C " + sbx + " " + sy + ", " + tbx + " " + ty + ", " + tx + " " + ty;
}

export function getArcSegment(r: number, laf: number, sf: number, tx: number, ty: number): string {
    return "A " + r + " " + r + " 0 " + laf + " " + sf + " " + tx + " " + ty;
}

export function getCircleSegment(
    x: number,
    y: number,
    radius: number,
    fromAngle: number,
    toAngle: number
): string {
    let fromPoint = polarToCartesian(x, y, radius, fromAngle);
    let toPoint = polarToCartesian(x, y, radius, toAngle);

    let dir = (toAngle - fromAngle) > 180 ? 1 : 0

    return `M ${fromPoint.x} ${fromPoint.y} A ${radius} ${radius} 0 ${dir} 1 ${toPoint.x} ${toPoint.y}`
}

export function getStretchBlobString(
    x1: number, y1: number,
    x2: number, y2: number,
    rad1: number, rad2: number,
    maxBlob1: number, maxBlob2: number,
    stroke: number,
    dynF: number): string {

    let dir = [x2 - x1, y2 - y1];
    let dd = dist(x1, y1, x2, y2);
    let dd_ = 200. / (dd + 200.);

    if (dynF > 0) {
        rad1 *= (1 - dynF) + dd_ * dynF;
        rad2 *= (1 - dynF) + dd_ * dynF;
    }

    let dir_n = [dir[0] / dd, dir[1] / dd];
    let ver_n = [dir_n[1], -dir_n[0]];
    stroke /= 2;
    maxBlob1 /= 2;
    maxBlob2 /= 2;

    let d = (dd - rad1 - rad2) / 8;
    let dd1 = d > maxBlob1 ? maxBlob1 : d;
    let dd2 = d > maxBlob2 ? maxBlob2 : d;

    let angle = getAngle(dir[0], dir[1]);
    let angle1 = Math.acos(rad1 / (dd1 + rad1));
    let angle2 = Math.acos(rad2 / (dd2 + rad2));

    let rad1_2 = rad1 * 2;
    //pg.arc(x1, y1, rad1_2, rad1_2, angle + angle1, angle + PApplet.TWO_PI - angle1);

    let rad2_2 = rad2 * 2;
    //pg.arc(x2, y2, rad2_2, rad2_2, angle - PApplet.PI + angle2, angle + PApplet.PI - angle2);

    let d1 = dd1 + rad1, d1_ = dd1 * 2 + rad1, d1__ = dd1 * 3 + rad1;
    let d2 = dd2 + rad2, d2_ = dd2 * 2 + rad2, d2__ = dd2 * 3 + rad2;

    let d1__2 = rad1 + rad1 / (rad1 + rad2) * (dd - rad1 - rad2) / 2;
    let d2__2 = rad2 + rad2 / (rad1 + rad2) * (dd - rad1 - rad2) / 2;
    if (d1__2 > d1__) d1__ = d1__2;
    if (d2__2 > d2__) d2__ = d2__2;

    const TWO_PI = Math.PI * 2;

    let result: string = ""
    //pg.beginShape();
    result += getStartVertexString(x1 + Math.cos(angle + angle1) * rad1, y1 + Math.sin(angle + angle1) * rad1);//x1, y1);
    result += " " + getArcSegment(rad1, 1, 1, x1 + Math.cos(angle + TWO_PI - angle1) * rad1, y1 + Math.sin(angle + TWO_PI - angle1) * rad1);

    //result += " " + getVertexString(x1 + Math.cos(angle + TWO_PI - angle1) * rad1, y1 + Math.sin(angle + TWO_PI - angle1) * rad1);
    result += " " + getBezierString_(
        x1 + dir_n[0] * d1 + ver_n[0] * stroke, y1 + dir_n[1] * d1 + ver_n[1] * stroke,
        x1 + dir_n[0] * d1_ + ver_n[0] * stroke, y1 + dir_n[1] * d1_ + ver_n[1] * stroke,
        x1 + dir_n[0] * d1__ + ver_n[0] * stroke, y1 + dir_n[1] * d1__ + ver_n[1] * stroke
    );

    //result += " " + getVertexString(x2 - dir_n[0] * d2__ + ver_n[0] * stroke, y2 - dir_n[1] * d2__ + ver_n[1] * stroke);
    result += " " + getBezierString_(
        x2 - dir_n[0] * d2_ + ver_n[0] * stroke, y2 - dir_n[1] * d2_ + ver_n[1] * stroke,
        x2 - dir_n[0] * d2 + ver_n[0] * stroke, y2 - dir_n[1] * d2 + ver_n[1] * stroke,
        x2 + Math.cos(angle - Math.PI + angle2) * rad2, y2 + Math.sin(angle - Math.PI + angle2) * rad2
    );

    //result += " " + getVertexString(x2, y2);
    result += " " + getArcSegment(rad2, 1, 1, x2 + Math.cos(angle + Math.PI - angle2) * rad2, y2 + Math.sin(angle + Math.PI - angle2) * rad2);//x1 + Math.cos(angle + TWO_PI - angle1) * rad1, y1 + Math.sin(angle + TWO_PI - angle1) * rad1);

    //result += " " + getVertexString(x2 + Math.cos(angle + Math.PI - angle2) * rad2, y2 + Math.sin(angle + Math.PI - angle2) * rad2);
    result += " " + getBezierString_(
        x2 - dir_n[0] * d2 - ver_n[0] * stroke, y2 - dir_n[1] * d2 - ver_n[1] * stroke,
        x2 - dir_n[0] * d2_ - ver_n[0] * stroke, y2 - dir_n[1] * d2_ - ver_n[1] * stroke,
        x2 - dir_n[0] * d2__ - ver_n[0] * stroke, y2 - dir_n[1] * d2__ - ver_n[1] * stroke
    );

    //result += " " + getVertexString(x1 + dir_n[0] * d1__ - ver_n[0] * stroke, y1 + dir_n[1] * d1__ - ver_n[1] * stroke);
    result += " " + getBezierString_(
        x1 + dir_n[0] * d1_ - ver_n[0] * stroke, y1 + dir_n[1] * d1_ - ver_n[1] * stroke,
        x1 + dir_n[0] * d1 - ver_n[0] * stroke, y1 + dir_n[1] * d1 - ver_n[1] * stroke,
        x1 + Math.cos(angle + angle1) * rad1, y1 + Math.sin(angle + angle1) * rad1
    );

    //result += " " + getVertexString(x1, y1);
    return result;
}