import React, {useState} from 'react';
import './App.css';
import {FancyNodesVPE} from "./components/FancyNodesVPE";
import store from "./store";
import {Provider} from "react-redux";
import {WorkspaceMenu} from "./components/workspace/WorkspaceMenu";

function App() {
    const [darkMode, setDarkMode] = useState<boolean>(false);
    const handleToggleDarkMode = () => setDarkMode(d => !d);

    return <div className="App">
        <Provider store={store}>
            <FancyNodesVPE/>
        </Provider>
    </div>
}

export default App;
