import React from "react";
import {KeyEventIcon} from "../../labels";
import {_scale} from "../../nodes";

const FancyKeyEvent = (props: any) => {
    return <div style={{lineHeight: 0}}>
        <KeyEventIcon/>
    </div>
}

export default FancyKeyEvent;