import {Edge, ExecutableNode, Node, isExecutableNode, Port, Position, getEdgeId} from "../../store/nodes";
import React, {SVGProps, useContext, useEffect, useState} from "react";
import {useTypedSelector} from "../../store";
import {useNodeStyle} from "../../hooks";
import {useZoomTranslate, ZoomTranslateState} from "../workspace/ZoomTranslate";
import Attractor from "./Attractor";
import {NodeStyleContext} from "../style/FancyNodeStyleProvider";
import {useMousePosition} from "../../hooks/input/events";
import {IconPort} from "../nodes/FancyPort";
import {dist, getPointAt, getTotalLength, length} from "../../common/math";
import {getConnections} from "../../common/graph";
import FullscreenSvg from "../workspace/FullscreenSvg";
import {_scale} from "../nodes";

function getRoundedRectanglePathString(
    x: number,
    y: number,
    width: number,
    height: number,
    radiusTL: number,
    radiusTR: number,
    radiusBR: number,
    radiusBL: number,
    ccw?: boolean,
    swTL: number = 0,
    swTR: number = 0,
    swBR: number = 0,
    swBL: number = 0
) {
    let tlfx = x - swTL, tlfy = y + radiusTL;
    let tltx = x + radiusTL, tlty = y - swTL;

    let trfx = x + width - radiusTR, trfy = y - swTR;
    let trtx = x + width + swTR, trty = y + radiusTR;

    let brfx = x + width + swBR, brfy = y + height - radiusBR;
    let brtx = x + width - radiusBR, brty = y + height + swBR;

    let blfx = x + radiusBL, blfy = y + height + swBL;
    let bltx = x - swBL, blty = y + height - radiusBL;

    radiusTL += swTL;
    radiusTR += swTR;
    radiusBR += swBR;
    radiusBL += swBL;

    if (!ccw) return 'M ' + getClockwiseArcPathString({x: tlfx, y: tlfy}, {x: tltx, y: tlty}, radiusTL, -90, 0)
        + 'L ' + getClockwiseArcPathString({x: trfx, y: trfy}, {x: trtx, y: trty}, radiusTR, 0, 90)
        + 'L ' + getClockwiseArcPathString({x: brfx, y: brfy}, {x: brtx, y: brty}, radiusBR, 90, 180)
        + 'L ' + getClockwiseArcPathString({x: blfx, y: blfy}, {x: bltx, y: blty}, radiusBL, 180, 270)
        + 'z'

    else return 'M ' + getCounterclockwiseArcPathString({x: bltx, y: blty}, {x: blfx, y: blfy}, radiusBL, 270, 180)
        + 'L ' + getCounterclockwiseArcPathString({x: brtx, y: brty}, {x: brfx, y: brfy}, radiusBR, 180, 90)
        + 'L ' + getCounterclockwiseArcPathString({x: trtx, y: trty}, {x: trfx, y: trfy}, radiusTR, 90, 0)
        + 'L ' + getCounterclockwiseArcPathString({x: tltx, y: tlty}, {x: tlfx, y: tlfy}, radiusTL, 0, -90)
        + 'z'
}

function getClockwiseArcPathString(fromPoint: Position, toPoint: Position, radius: number, fromAngle: number, toAngle: number): string {
    let dir = (toAngle - fromAngle) > 180 ? 1 : 0

    return `${fromPoint.x} ${fromPoint.y} A ${radius} ${radius} 0 ${dir} 1 ${toPoint.x} ${toPoint.y}`;
}

function getCounterclockwiseArcPathString(fromPoint: Position, toPoint: Position, radius: number, fromAngle: number, toAngle: number): string {
    let dir = (toAngle - fromAngle) > 180 ? 1 : 0

    return `${fromPoint.x} ${fromPoint.y} A ${radius} ${radius} 0 ${dir} 0 ${toPoint.x} ${toPoint.y}`;
}

export function normalize(vec: Position): Position {
    let l = length(vec.x, vec.y);
    return {x: vec.x / l, y: vec.y / l};
}

function getPathString(vecs: Position[], aa: Attractors, strokeFunc: (v: number) => number, scaleDist: number): string {
    let vtcl: Position[] = [];
    let str: number[] = [];

    for (let i = 0; i < vecs.length; i++) {
        let vi = vecs[i];
        if (i == 0) {
            let v1 = {x: vecs[i + 1].x - vi.x, y: vecs[i + 1].y - vi.y};
            vtcl[i] = {x: v1.y, y: -v1.x};
            vtcl[i] = normalize(vtcl[i]);
        } else if (i == vecs.length - 1) {
            let v1 = {x: vi.x - vecs[i - 1].x, y: vi.y - vecs[i - 1].y};
            vtcl[i] = {x: v1.y, y: -v1.x};
            vtcl[i] = normalize(vtcl[i]);
        } else {
            let v1 = {x: vi.x - vecs[i - 1].x, y: vi.y - vecs[i - 1].y};
            let v2 = {x: vecs[i + 1].x - vi.x, y: vecs[i + 1].y - vi.y};
            v1 = normalize(v1);
            v2 = normalize(v2);
            vtcl[i] = {x: (v1.y + v2.y) / 2, y: -(v1.x + v2.x) / 2};
        }

        str[i] = strokeFunc(Attractor.getValue(vi.x, vi.y, aa, scaleDist));
    }

    let edgeDetail = 12;
    let result = "M ";
    for (let iii = 1; iii < edgeDetail; iii++) {
        let alpha = iii / edgeDetail * Math.PI;
        if (iii > 1) result += "L ";
        result += (vecs[0].x + vtcl[0].y * str[0] * Math.sin(alpha) - vtcl[0].x * str[0] * Math.cos(alpha)) + " " +
            (vecs[0].y - vtcl[0].x * str[0] * Math.sin(alpha) - vtcl[0].y * str[0] * Math.cos(alpha))
    }
    for (let i = 0; i < vecs.length; i++) {
        let thisVec = vecs[i];
        result += " L " + (thisVec.x + vtcl[i].x * str[i]) + " " + (thisVec.y + vtcl[i].y * str[i]);
    }
    let ll = vecs.length - 1;
    for (let iii = 1; iii < edgeDetail; iii++) {
        let alpha = iii / edgeDetail * Math.PI;
        result += " L " + (vecs[ll].x - vtcl[ll].y * str[ll] * Math.sin(alpha) + vtcl[ll].x * str[ll] * Math.cos(alpha)) + " " +
            (vecs[ll].y + vtcl[ll].x * str[ll] * Math.sin(alpha) + vtcl[ll].y * str[ll] * Math.cos(alpha))
    }
    for (let i = vecs.length - 1; i >= 0; i--) {
        let thisVec = vecs[i];
        result += " L " + (thisVec.x - vtcl[i].x * str[i]) + " " + (thisVec.y - vtcl[i].y * str[i]);
    }
    result += " Z";
    return result;
}

function bezier(t: number, p0: Position, p1: Position, p2: Position, p3: Position): Position {
    let cX = 3 * (p1.x - p0.x),
        bX = 3 * (p2.x - p1.x) - cX,
        aX = p3.x - p0.x - cX - bX;

    let cY = 3 * (p1.y - p0.y),
        bY = 3 * (p2.y - p1.y) - cY,
        aY = p3.y - p0.y - cY - bY;

    let x = (aX * Math.pow(t, 3)) + (bX * Math.pow(t, 2)) + (cX * t) + p0.x;
    let y = (aY * Math.pow(t, 3)) + (bY * Math.pow(t, 2)) + (cY * t) + p0.y;

    return {x: x, y: y};
}

function getBezierPath(p0: Position, p1: Position, p2: Position, p3: Position, start?: Position, end?: Position): Position[] {
    let d = dist(p0.x, p0.y, p1.x, p1.y) + dist(p1.x, p1.y, p2.x, p2.y) + dist(p2.x, p2.y, p3.x, p3.y);
    let dt = 12 / d;
    dt = Math.min(dt, 1);
    let vecs: Position[] = [];
    if (start) vecs.push(start);
    for (let t = 0; t <= 1 + dt / 2; t += dt) {
        vecs.push(bezier(t, p0, p1, p2, p3));
    }
    if (end) vecs.push(end);
    return vecs;
}

function getBezierPathString(p0: Position, p1: Position, p2: Position, p3: Position, aa: Attractors, strokeFunc: (v: number) => number, scaleDist: number, start?: Position, end?: Position): string {
    let d = dist(p0.x, p0.y, p1.x, p1.y) + dist(p1.x, p1.y, p2.x, p2.y) + dist(p2.x, p2.y, p3.x, p3.y);
    let dt = 12 / d;
    dt = Math.min(dt, 1);
    let vecs: Position[] = [];
    if (start) vecs.push(start);
    for (let t = 0; t <= 1 + dt / 2; t += dt) {
        vecs.push(bezier(t, p0, p1, p2, p3));
    }
    if (end) vecs.push(end);
    return getPathString(vecs, aa, strokeFunc, scaleDist);
}

type Attractors = Attractor[];
const AttractorsContext = React.createContext<Attractors>([]);
export const useAttractors = () => useContext(AttractorsContext);

const ConnectedExecutableNodeSketch = ({id, strokeWidthMax, scaleDist, hidePorts}: {
    id: string,
    strokeWidthMax?: number,
    scaleDist?: number,
    hidePorts?: boolean
}) => {
    let node = useTypedSelector(state => state.nodes.nodes[id]) as ExecutableNode;

    let zoomTranslate = useZoomTranslate();
    let attractors = useAttractors();

    let prev: ExecutableNode | undefined = useTypedSelector(state => {
        let id = node.prev;
        if (id) return state.nodes.nodes[id] as ExecutableNode; else return undefined;
    });

    let next: ExecutableNode | undefined = useTypedSelector(state => {
        let id = node.next;
        if (id) return state.nodes.nodes[id] as ExecutableNode; else return undefined;
    });

    let nodeStyle = useNodeStyle();

    let border = nodeStyle.strokeWidth;
    let borderRadius = nodeStyle.cornerRadius;
    let borderRadius_ = nodeStyle.cornerRadiusStack;
    let thresh = 4;

    let scaled = {
        left: zoomTranslate.x + zoomTranslate.zoom * (node.shape.x * _scale),
        top: zoomTranslate.y + zoomTranslate.zoom * (node.shape.y * _scale),
        width: zoomTranslate.zoom * (node.shape.width * _scale),
        height: zoomTranslate.zoom * (node.shape.height * _scale)
    }

    let borderTLRadius = prev && node.shape.x + thresh > prev.shape.x ? borderRadius_ : borderRadius;
    let borderTRRadius = prev && node.shape.x + node.shape.width - thresh < prev.shape.x + prev.shape.width ? borderRadius_ : borderRadius;
    let borderBLRadius = next && node.shape.x + thresh > next.shape.x ? borderRadius_ : borderRadius;
    let borderBRRadius = next && node.shape.x + node.shape.width - thresh < next.shape.x + next.shape.width ? borderRadius_ : borderRadius;

    let swTL = 0, swTR = 0, swBR = 0, swBL = 0;

    if (attractors && attractors[0]) {
        let a = attractors[0];
        swTL = a.getValue(scaled.left, scaled.top, (scaleDist || 1) / _scale / zoomTranslate.zoom) * (strokeWidthMax || 8);
        swTR = a.getValue(scaled.left + scaled.width, scaled.top, (scaleDist || 1) / _scale / zoomTranslate.zoom) * (strokeWidthMax || 8);
        swBR = a.getValue(scaled.left + scaled.width, scaled.top + scaled.height, (scaleDist || 1) / _scale / zoomTranslate.zoom) * (strokeWidthMax || 8);
        swBL = a.getValue(scaled.left, scaled.top + scaled.height, (scaleDist || 1) / _scale / zoomTranslate.zoom) * (strokeWidthMax || 8);
    }

    const portSize = 6 * _scale * 1.125;
    const portDist = 12 * _scale;

    const inputPosition = (index: number) => ({
        left: scaled.left,
        top: scaled.top + (index + 1) * portDist * zoomTranslate.zoom
    })

    const outputPosition = (index: number) => ({
        right: scaled.left + scaled.width,
        top: scaled.top + (index + 1) * portDist * zoomTranslate.zoom
    })

    return <>
        <path d={getRoundedRectanglePathString(
            scaled.left,
            scaled.top,
            scaled.width,
            scaled.height,
            zoomTranslate.zoom * borderTLRadius,
            zoomTranslate.zoom * borderTRRadius,
            zoomTranslate.zoom * borderBRRadius,
            zoomTranslate.zoom * borderBLRadius,
            false,
            swTL / 2,
            swTR / 2,
            swBR / 2,
            swBL / 2
        ) + getRoundedRectanglePathString(
            scaled.left,
            scaled.top,
            scaled.width,
            scaled.height,
            zoomTranslate.zoom * borderTLRadius,
            zoomTranslate.zoom * borderTRRadius,
            zoomTranslate.zoom * borderBRRadius,
            zoomTranslate.zoom * borderBLRadius,
            true,
            -swTL / 2,
            -swTR / 2,
            -swBR / 2,
            -swBL / 2
        )}/>
        {!hidePorts && node.input?.map((port, i) => {
            let pos = inputPosition(i);
            let r = 0;
            if (attractors && attractors[0]) {
                let a = attractors[0];
                r = a.getValue(pos.left, pos.top, (scaleDist || 1) / _scale / zoomTranslate.zoom) * (strokeWidthMax || 8);
            }
            return <circle cx={pos.left} cy={pos.top}
                           r={Math.min(r * 1.5, portSize * zoomTranslate.zoom / 2 + (strokeWidthMax || 8) / 4)}/>
        })}
        {!hidePorts && node.output?.map((port, i) => {
            let pos = outputPosition(i);
            let r = 0;
            if (attractors && attractors[0]) {
                let a = attractors[0];
                r = a.getValue(pos.right, pos.top, (scaleDist || 1) / _scale / zoomTranslate.zoom) * (strokeWidthMax || 8);
            }
            return <circle cx={pos.right} cy={pos.top}
                           r={Math.min(r * 1.5, portSize * zoomTranslate.zoom / 2 + (strokeWidthMax || 8) / 4)}/>
        })}
    </>
}

const ConnectedNodesSketches = ({gProps, nodes, ...props}: {
    strokeWidthMax?: number,
    scaleDist?: number,
    hidePorts?: boolean
    gProps?: SVGProps<SVGGElement>
    nodes: {[p: string]: Node}
}) => {
    return <g fill={'#ff0020'} {...gProps}>{Object.keys(nodes).map(key => {
        let node = nodes[key];
        if ('width' in node.shape) {
            return <ConnectedExecutableNodeSketch {...props} id={key}/>
        } else {
            return null;
        }
    })}</g>
}

const ConnectedEdgeSketch = ({edge, scaleDist, attractors, showConnectors, strokeWidthMax}: {
    edge: Edge,
    scaleDist?: number,
    attractors: Attractors,
    showConnectors?: boolean,
    strokeWidthMax?: number
}) => {
    let fromNode = useTypedSelector(state => state.nodes.nodes[edge.from.id]);
    let toNode = useTypedSelector(state => state.nodes.nodes[edge.to.id]);
    let zoomTranslate = useZoomTranslate();

    const portDist = 12.0;
    const portSize = 6.0;
    const postSizeScaled = portSize * _scale * 1.125;
    const portShift = portSize * 0.48;

    let sx, sy, tx, ty;
    let sxo, txo;
    if (isExecutableNode(fromNode)) {
        let exn = fromNode as ExecutableNode;
        sxo = fromNode.shape.x + exn.shape.width;
        sx = sxo + portShift;
        sy = fromNode.shape.y + ((edge.from.index ?? 0) + 1) * portDist;
    } else {
        sx = fromNode.shape.x;
        sy = fromNode.shape.y;
        sxo = sx;
    }
    if (isExecutableNode(toNode)) {
        txo = toNode.shape.x;
        tx = txo - portShift;
        ty = toNode.shape.y + ((edge.to.index ?? 0) + 1) * portDist;
    } else {
        tx = toNode.shape.x;
        ty = toNode.shape.y;
        txo = tx;
    }

    const distX = tx - sx;
    const distY = ty - sy;
    const flip = distY < .0 ? -1.0 : 1.0;
    const bx = distY / 2 * flip;
    const sbx = sx + bx;
    const tbx = tx - bx;
    const xo = 1.0;

    const pathString = getBezierPathString(
        {x: zoomTranslate.x + zoomTranslate.zoom * (sx - xo) * _scale, y: zoomTranslate.y + zoomTranslate.zoom * sy * _scale},
        {x: zoomTranslate.x + zoomTranslate.zoom * sbx * _scale, y: zoomTranslate.y + zoomTranslate.zoom * sy * _scale},
        {x: zoomTranslate.x + zoomTranslate.zoom * tbx * _scale, y: zoomTranslate.y + zoomTranslate.zoom * ty * _scale},
        {x: zoomTranslate.x + zoomTranslate.zoom * (tx + xo) * _scale, y: zoomTranslate.y + zoomTranslate.zoom * ty * _scale},
        attractors,
        x => x * (strokeWidthMax ?? 8) / 2,
        (scaleDist || 1) / _scale / zoomTranslate.zoom,
        {x: zoomTranslate.x + zoomTranslate.zoom * sxo * _scale, y: zoomTranslate.y + zoomTranslate.zoom * sy * _scale},
        {x: zoomTranslate.x + zoomTranslate.zoom * txo * _scale, y: zoomTranslate.y + zoomTranslate.zoom * ty * _scale}
    );

    return !showConnectors ? <path
        d={pathString}
    /> : <>
        <path d={pathString}/>
        {(() => {
            let pos = {x: zoomTranslate.x + zoomTranslate.zoom * _scale * sxo, y: zoomTranslate.y + zoomTranslate.zoom * _scale * sy};
            let r = 0;
            if (attractors && attractors[0]) {
                let a = attractors[0];
                r = a.getValue(pos.x, pos.y, (scaleDist || 1) / _scale / zoomTranslate.zoom) * (strokeWidthMax || 8);
            }
            return <circle cx={pos.x} cy={pos.y}
                           r={Math.min(r * .8, postSizeScaled * zoomTranslate.zoom / 2 + (strokeWidthMax || 8) / 4)}/>
        })()}
        {(() => {
            let pos = {x: zoomTranslate.x + zoomTranslate.zoom * _scale * txo, y: zoomTranslate.y + zoomTranslate.zoom * _scale * ty};
            let r = 0;
            if (attractors && attractors[0]) {
                let a = attractors[0];
                r = a.getValue(pos.x, pos.y, (scaleDist || 1) / _scale / zoomTranslate.zoom) * (strokeWidthMax || 8);
            }
            return <circle cx={pos.x} cy={pos.y}
                           r={Math.min(r * .8, postSizeScaled * zoomTranslate.zoom / 2 + (strokeWidthMax || 8) / 4)}/>
        })()}
    </>
}

const ConnectedEdgesSketches = ({gProps, edges, scaleDists, strokeWidthMaxs, ...props}: {
    strokeWidthMax?: number,
    strokeWidthMaxs?: {[p: string]: number},
    scaleDist?: number,
    scaleDists?: {[p: string]: number},
    showConnectors?: boolean,
    gProps?: SVGProps<SVGGElement>,
    edges: {[p: string]: Edge}
}) => {
    let zoomTranslate = useZoomTranslate();
    let attractors = useAttractors();

    return <g fill={'#ff0020'} {...gProps}>
        {Object.keys(edges).map(key => edges[key] ? <ConnectedEdgeSketch
            {...props}
            edge={edges[key]}
            attractors={attractors}
            scaleDist={scaleDists && scaleDists[key] ? scaleDists[key] : props.scaleDist}
            strokeWidthMax={strokeWidthMaxs && (strokeWidthMaxs[key] !== undefined) ? strokeWidthMaxs[key] : props.strokeWidthMax}
        /> : null)}
    </g>
}

const FancySketchpad = () => {
    let nodes = useTypedSelector(state => state.nodes.nodes);
    let edges = useTypedSelector(state => state.nodes.edges);

    const [attractors, setAttractors] = useState<Attractors>([]);
    const mousePos = useMousePosition();

    useEffect(() => {
        setAttractors((mousePos.x && mousePos.y) ? [new Attractor(mousePos.x, mousePos.y, 1.0, 20, 160)] : [])
    }, [mousePos])

    return <FullscreenSvg style={{zIndex: 101}}>
        <AttractorsContext.Provider value={attractors}>
            <ConnectedNodesSketches nodes={nodes} scaleDist={2} strokeWidthMax={8} hidePorts/>
            <ConnectedEdgesSketches
                edges={edges}
                scaleDist={2}
                strokeWidthMax={8}
                showConnectors
            />
        </AttractorsContext.Provider>
    </FullscreenSvg>
}

export default FancySketchpad;

export const ConnectedSketchpad = () => {
    let dialog = useTypedSelector(state => state.dialog.activeDialog);
    let nodes = useTypedSelector(state => state.nodes.nodes);
    let edges = useTypedSelector(state => state.nodes.edges);
    let zoomTranslate = useZoomTranslate();

    const [attractors, setAttractors] = useState<Attractors>([]);
    const [factor, setFactor] = useState<number>(0);
    //const mousePos = useMousePosition();

    const getEdgePath = (edge: Edge) => {
        let fromNode = nodes[edge.from.id];
        let toNode = nodes[edge.to.id];

        const portDist = 12.0;
        const portSize = 6.0;
        const postSizeScaled = portSize * _scale * 1.125;
        const portShift = portSize * 0.48;

        let sx, sy, tx, ty;
        let sxo, txo;
        if (isExecutableNode(fromNode)) {
            let exn = fromNode as ExecutableNode;
            sxo = fromNode.shape.x + exn.shape.width;
            sx = sxo + portShift;
            sy = fromNode.shape.y + ((edge.from.index ?? 0) + 1) * portDist;
        } else {
            sx = fromNode.shape.x;
            sy = fromNode.shape.y;
            sxo = sx;
        }
        if (isExecutableNode(toNode)) {
            txo = toNode.shape.x;
            tx = txo - portShift;
            ty = toNode.shape.y + ((edge.to.index ?? 0) + 1) * portDist;
        } else {
            tx = toNode.shape.x;
            ty = toNode.shape.y;
            txo = tx;
        }

        const distX = tx - sx;
        const distY = ty - sy;
        const flip = distY < .0 ? -1.0 : 1.0;
        const bx = distY / 2 * flip;
        const sbx = sx + bx;
        const tbx = tx - bx;
        const xo = 1.0;

        return getBezierPath(
            {x: zoomTranslate.x + zoomTranslate.zoom * (sx - xo) * _scale, y: zoomTranslate.y + zoomTranslate.zoom * sy * _scale},
            {x: zoomTranslate.x + zoomTranslate.zoom * sbx * _scale, y: zoomTranslate.y + zoomTranslate.zoom * sy * _scale},
            {x: zoomTranslate.x + zoomTranslate.zoom * tbx * _scale, y: zoomTranslate.y + zoomTranslate.zoom * ty * _scale},
            {x: zoomTranslate.x + zoomTranslate.zoom * (tx + xo) * _scale, y: zoomTranslate.y + zoomTranslate.zoom * ty * _scale},
            {x: zoomTranslate.x + zoomTranslate.zoom * sxo * _scale, y: zoomTranslate.y + zoomTranslate.zoom * sy * _scale},
            {x: zoomTranslate.x + zoomTranslate.zoom * txo * _scale, y: zoomTranslate.y + zoomTranslate.zoom * ty * _scale}
        );
    }

    /*
    useEffect(() => {
        setAttractors((mousePos.x && mousePos.y) ? [new Attractor(mousePos.x, mousePos.y, 1.0, 20, 160)] : [])
    }, [mousePos])
    */
    //if (!dialog || dialog.type !== 'error' || !dialog.nodes) return null;

    let loopNodes: {[p: string]: Node} = {};
    let loopEdges: {[p: string]: Edge} = {};
    let loopEdgeSegments: {[p: string]: {from: number, to: number}} = {};
    let loopEdgesPath: Position[] = [];

    let total = 0;
    let lastPos: Position | null = null;
    !(!dialog || dialog.type !== 'error' || !dialog.nodes) && dialog.nodes.forEach((id: string, i: number, a: string[]) => {
        loopNodes[id] = nodes[id];
        let j = i + 1; if (j > a.length - 1) j = 0;
        let edges = getConnections(id, a[j]);
        edges.forEach(edge => loopEdges[getEdgeId(edge)] = edge);
        if (edges.length > 0) {
            let loopPath = getEdgePath(edges[0]);
            if (lastPos) total += dist(lastPos.x, lastPos.y, loopPath[0].x, loopPath[0].y);
            lastPos = loopPath[loopPath.length - 1];
            //console.log("lastPos: " + lastPos);
            let from = total;
            //console.log("from: " + from);
            loopPath.forEach(p => loopEdgesPath.push(p));
            let length = getTotalLength(loopPath);
            total = from + length;
            //console.log("total: " + total);
            edges.forEach(edge => loopEdgeSegments[getEdgeId(edge)] = {from, to: total});
        }
    });

    let totalLength = getTotalLength(loopEdgesPath, true);

    useEffect(() => {
        if (dialog?.nodes) {
            const interval = setInterval(() => {
                let time = Date.now();
                let fullCircleTime = 200 + totalLength * 3;
                let t = time % fullCircleTime;
                let newPos = getPointAt(loopEdgesPath, t / fullCircleTime * totalLength);
                setFactor(t / fullCircleTime * totalLength);
                setAttractors([new Attractor(newPos.x, newPos.y, 1.0, 20, 160)])
            }, 1000/30);
            return () => clearInterval(interval);
        }
    }, [dialog]);

    if (!dialog || dialog.type !== 'error' || !dialog.nodes) return null;

    let scaleDists: {[p: string]: number} = {};
    let strokeWidthMaxs: {[p: string]: number} = {};
    Object.keys(loopEdgeSegments).forEach(les => {
        let seg = loopEdgeSegments[les];

        if (factor >= seg.from && factor <= seg.to) {
            scaleDists[les] = 2;
            strokeWidthMaxs[les] = 8;

        } else {
            let segLength = seg.to - seg.from;
            let rLength = totalLength - segLength;
            let halfRLength = rLength / 2;
            let ff = seg.from - halfRLength;
            let tt = seg.to + halfRLength;
            let fff = factor;
            if (fff > seg.from) fff -= totalLength;
            let ttt = factor;
            if (ttt < seg.to) ttt += totalLength;
            let df = (seg.from - fff) / halfRLength;
            let dt = (ttt - seg.to) / halfRLength;
            let d = Math.min(df, dt);

            scaleDists[les] = 2 + d * 4;
            strokeWidthMaxs[les] = 8 - d * 4;
        }
    })

    return <FullscreenSvg style={{zIndex: 101}}>
        <AttractorsContext.Provider value={attractors}>
            <ConnectedNodesSketches nodes={loopNodes} scaleDist={2} strokeWidthMax={8} hidePorts/>
            <ConnectedEdgesSketches
                edges={loopEdges}
                scaleDist={2}
                scaleDists={scaleDists}
                strokeWidthMax={8}
                strokeWidthMaxs={strokeWidthMaxs}
                showConnectors
            />
        </AttractorsContext.Provider>
    </FullscreenSvg>
}