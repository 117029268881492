import {Action, PayloadAction} from "@reduxjs/toolkit";
import {ReactNode} from "react";
import {ZoomTranslateState} from "../../components/workspace/ZoomTranslate";

export interface NodeInfoDialogActionProps {
    nodeId?: string
    handle?: number
    rect?: number[]
}

export interface ErrorDialogActionProps {
    nodes?: string[]
    rects?: number[][]
    onClose?: () => any
}

export interface DialogTypeMap {
    "node info": NodeInfoDialogActionProps
    "error": ErrorDialogActionProps
}

export type DialogActionProps<T extends keyof DialogTypeMap> = {
    type: T,
    children?: ReactNode,
    buttonProps?: { [key: string]: {callback: () => any, disabled?: boolean}}
} & DialogTypeMap[T]

export enum DialogActionType {
    OPEN = "OPENDIALOG",
    CLOSE = "CLOSEDIALOG",
    SETCALLBACK = "SETACTIVEDIALOGCALLBACK"
}

export interface DialogState {
    activeDialog?: DialogActionProps<any>
}

export type OpenDialogAction = PayloadAction<DialogActionProps<keyof DialogTypeMap>, DialogActionType.OPEN>
export type CloseDialogAction = Action<DialogActionType.CLOSE>
export type SetActiveDialogCallbackAction = PayloadAction<{ key: string, callback: (() => any) | undefined, disabled?: boolean }, DialogActionType.SETCALLBACK>
export type DialogActions = OpenDialogAction | CloseDialogAction | SetActiveDialogCallbackAction;


export enum ZoomTranslateActionType {
    SET = "SETZOOMTRANSLATE"
}

export type SetZoomTranslateAction = PayloadAction<Partial<ZoomTranslateState>, ZoomTranslateActionType.SET>
export type TransformActions = SetZoomTranslateAction;