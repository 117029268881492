import {ExecutableNode, Node, StateNode} from "../../store/nodes";
import {useTypedSelector} from "../../store";
import {NodeStyleContext} from "../style/FancyNodeStyleProvider";
import React from "react";
import {useStyle} from "../../hooks";
import {SolidNodeStyle} from "../style";
import {_scale} from "./index";

const FancyNodeShadows = ({filter}: { filter?: (n: Node) => boolean }) => {
    let nodes = useTypedSelector(state => {
        if (!filter) return state.nodes.nodes;
        else return Object.fromEntries<Node>(
            Object.entries(state.nodes.nodes).filter(
                ([, n]) => filter(n)
            )
        )
    });
    let hovered = useTypedSelector(state => state.nodes.hovered);

    return <NodeStyleContext.Consumer>{(theme) => {
        //let nodeStyle = theme.components.Node;

        return <>{Object.keys(nodes).map(key => {
            let node = nodes[key];
            if (node.id === hovered) return null;
            if ('width' in node.shape) {
                return <ConnectedExecutableNodeShadow id={key} key={key}/>
            } else {
                return <ConnectedStateNodeShadow id={key} key={key}/>;
            }
        })}</>
    }}
    </NodeStyleContext.Consumer>
}

export default FancyNodeShadows;

export const ConnectedExecutableNodeShadow = ({id}: { id: string }) => {
    let node = useTypedSelector(state => state.nodes.nodes[id]) as ExecutableNode;

    const theme = useStyle();

    let appearance = undefined;
    if (!node.input || node.input.length === 0) appearance = {type: 'solid'};

    let themeShadowColor = node.state === 'disabled' ? theme.shadow.disabledColor : theme.shadow.color;
    let shadowColor = appearance && appearance.type === 'solid' ? ((theme.components.Node.custom?.solid as SolidNodeStyle).customShadowColor ?? themeShadowColor) : themeShadowColor;

    let border = 2 * _scale - 2;
    let borderRadius = 8 * _scale;

    let mt = (appearance && appearance.type === 'action') && node.prev ? border / 2 : 0;
    let mb = (appearance && appearance.type === 'action') && node.next ? border / 2 : 0;

    let objectShadow = true;
    let shadowType = (appearance && appearance.type === 'solid' && (theme.components.Node.custom?.solid as SolidNodeStyle).shadowType) ?? 'drop'

    if (shadowType === 'glow' || !objectShadow) return <div style={{
        position: 'absolute',
        left: node.shape.x * _scale - border / 2,
        top: node.shape.y * _scale - border / 2 + mt,
        width: node.shape.width * _scale + border,
        height: node.shape.height * _scale + border - mt - mb,
        borderRadius: borderRadius,
        boxShadow: (shadowType === 'glow' ? 0 : theme.shadow.offsetX) + 'px ' +
            (shadowType === 'glow' ? 0 : theme.shadow.offsetY) + 'px ' +
            theme.shadow.blurRadius + 'px ' +
            theme.shadow.spreadRadius + 'px ' +
            shadowColor
        //boxShadow: 3 * _scale + 'px ' + 3 * _scale + 'px ' + 12 * _scale + 'px 0px #00408050'
    }}/>; else return <div style={{
        position: 'absolute',
        left: node.shape.x * _scale - border / 2 + theme.shadow.offsetX + theme.shadow.blurRadius,
        top: node.shape.y * _scale - border / 2 + mt + theme.shadow.offsetY + theme.shadow.blurRadius,
        width: node.shape.width * _scale + border - theme.shadow.blurRadius * 2,
        height: node.shape.height * _scale + border - mt - mb - theme.shadow.blurRadius * 2,
        borderRadius: borderRadius - theme.shadow.blurRadius / 2,
        background: shadowColor,
        boxShadow: '0 0 ' + theme.shadow.blurRadius + 'px ' + theme.shadow.blurRadius + 'px ' + shadowColor
        //boxShadow: 3 * _scale + 'px ' + 3 * _scale + 'px ' + 12 * _scale + 'px 0px #00408050'
    }}/>
}

export const ConnectedStateNodeShadow = ({id}: { id: string }) => {
    let node = useTypedSelector(state => state.nodes.nodes[id]) as StateNode;

    let border = 2 * _scale - 2;

    return <div
        style={{
            position: 'absolute',
            left: (node.shape.x - node.shape.radius) * _scale - border / 2,
            top: (node.shape.y - node.shape.radius) * _scale - border / 2,
            width: node.shape.radius * 2 * _scale + border,
            height: node.shape.radius * 2 * _scale + border,
            borderRadius: '50%',
            boxShadow: 3 * _scale + 'px ' + 3 * _scale + 'px ' + 12 * _scale + 'px 0px #00408050'
        }}
    />
}