import {AnimatePresence} from "framer-motion";
import React, {useEffect} from "react";
import FancyWindow, {FancyWindowProps} from "./FancyWindow";
import {useTypedSelector} from "../../../store";
import {ExecutableNode} from "../../../store/nodes";
import {useZoomTranslate} from "../ZoomTranslate";
import {_scale} from "../../nodes";

export type ButtonTypes = ['OK'] | ['OK', 'Cancel'] | ['Close'] | ['Yes', 'No', 'Cancel'] | (string[] & {})
export type WindowControlButtonTypes = ['x'] | ['-', 'x']

export interface FancyDialogProps extends FancyWindowProps {
    open: boolean
}

export interface AbsoluteRectangle {
    x1: number,
    y1: number,
    x2: number,
    y2: number
}

export type AbsoluteRectangleGroup = AbsoluteRectangle[];

export default function FancyDialog({open, ...props}: FancyDialogProps) {
    const focus = useTypedSelector(state => state.dialog);
    const nodes = useTypedSelector(state => state.nodes.nodes);
    const zoomTranslate = useZoomTranslate();

    const pointTo = focus.activeDialog?.rect ? [
        focus.activeDialog.rect[0] + focus.activeDialog.rect[2] / 2,
        focus.activeDialog.rect[1] + focus.activeDialog.rect[3] / 2
    ] : undefined

    const pointToRect = focus.activeDialog?.rect ? {
        x1: focus.activeDialog.rect[0],
        y1: focus.activeDialog.rect[1],
        x2: focus.activeDialog.rect[0] + focus.activeDialog.rect[2],
        y2: focus.activeDialog.rect[1] + focus.activeDialog.rect[3]
    } : undefined

    const pointToRectGroup = focus.activeDialog?.nodes ? focus.activeDialog?.nodes.map((n: string)  => {
        let node = nodes[n] as ExecutableNode;
        let rect = node.shape;
        return {
            x1: zoomTranslate.x + zoomTranslate.zoom * rect.x * _scale,
            y1: zoomTranslate.y + zoomTranslate.zoom * rect.y * _scale,
            x2: zoomTranslate.x + zoomTranslate.zoom * (rect.x + rect.width) * _scale,
            y2: zoomTranslate.y + zoomTranslate.zoom * (rect.y + rect.height) * _scale
        }
    }) : undefined

    useEffect(() => {
        if (!open) sessionStorage.removeItem('dialogrect') //.setItem('dialogrect', JSON.stringify(windowRect));
    }, [open]);

    return <AnimatePresence>
        {open && <FancyWindow
            {...props}
            pointTo={pointTo}
            pointToRect={pointToRectGroup ?? pointToRect}
            onClickAway={open ? props.onClickAway : undefined}
            callback={s => {
                focus.activeDialog.onClose && focus.activeDialog.onClose();
                props.callback && props.callback(s);
            }}
        />}
    </AnimatePresence>
}