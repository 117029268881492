import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import React, {useState} from "react";
import {useStyle} from "../../hooks";
import {AnimatePresence, motion} from "framer-motion";

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import {PortController, useControllerValue} from "./index";
import {useTypedSelector} from "../../store";
import StyledSlider from "./input/StyledSlider";
import {MultiplyIcon, ToggleOffIcon, ToggleOnIcon} from "../labels";

const StyledToggle = ({value, onChange} : {
    value: boolean,
    onChange: () => any
}) => {
    const [hovered, setHovered] = useState<boolean>(false);

    const handleHover = () => setHovered(true);
    const handleUnhover = () => setHovered(false);

    const style = useStyle();

    return <div
        style={{
            cursor: 'pointer',
            //opacity: hovered ? 1.0 : 0.5,
            width: 36,
            height: 36,
            position: 'relative',
            marginLeft: 'auto',
            marginRight: 'auto',
            pointerEvents: 'auto'
        }}
        onClick={onChange}
        onMouseEnter={handleHover}
        onMouseLeave={handleUnhover}
    >
        <AnimatePresence>{hovered && <motion.div
            style={{position: 'absolute', inset: 0, borderRadius: '50%', background: style.palette.highlight.main, opacity: .16}}
            initial={{scale: 0}}
            animate={{scale: 1, transition: {duration: .1}}}
            exit={{scale: 0, transition: {duration: .1}}}
        />}</AnimatePresence>
        <AnimatePresence>
            {value && <motion.div
                key='boolean toggle on icon'
                style={{position: 'absolute'/*, opacity: hovered ? style.palette.highlight.mainAlpha : style.palette.default.lightAlpha*/}}
                initial={{scale: 0}}
                animate={{scale: 1, transition: {duration: .125, delay: .125}}}
                exit={{scale: 0, transition: {duration: .125}}}
            >
                <ToggleOnIcon color={style.palette.highlight.main/*hovered ? style.palette.highlight.main : style.palette.default.main*/}/>
            </motion.div>}
            {!value && <motion.div
                key='boolean toggle off icon'
                style={{position: 'absolute'/*, opacity: hovered ? style.palette.highlight.mainAlpha : style.palette.default.lightAlpha*/}}
                initial={{scale: 0}}
                animate={{scale: 1, transition: {duration: .125, delay: .125}}}
                exit={{scale: 0, transition: {duration: .125}}}
            >
                <ToggleOffIcon color={style.palette.highlight.main/*hovered ? style.palette.highlight.main : style.palette.default.main*/}/>
            </motion.div>}
        </AnimatePresence>
    </div>;
}

export type OutputBooleanController = PortController<"out", boolean>

export interface FancyToggleProps {
    value?: boolean
}

export const OutputControlToggle = (props: OutputBooleanController) => {
    const [value, setValue] = useControllerValue<boolean>(props.id, true);

    const handleChange = () => {
        let newV = !value;
        props.onChange(newV);
        setValue(newV);
    }

    return <StyledToggle
        value={value}
        onChange={handleChange}
    />
}