import {Command} from "./Command";
import {Node} from "../../store/nodes";
import {getNodesMemento, MementoNodeCommand} from "./index";

export class CommandHandler {
    private _redoStack: Command[];
    private _undoStack: Command[];

    private _nodeMemento: {[key: string]: Node} | null = null;

    constructor() {
        this._redoStack = [];
        this._undoStack = [];
    }

    public execute(command: Command) {
        command.execute();
        this.push(command);
    }

    public push(command: Command) {
        this._undoStack.push(command);
        this._redoStack = [];
    }

    public undo(andForget = false) {
        let last = this._undoStack.pop();
        if (last) {
            last.undo();
            if (!andForget) {
                this._redoStack.push(last);
            }
        }
    }

    public redo() {
        let last = this._redoStack.pop();
        if (last) {
            last.execute();
            this._undoStack.push(last);
        }
    }

    public memoNodes() {
        this._nodeMemento = getNodesMemento();
    }

    public pushMemoNodes() {
        if (this._nodeMemento) {
            this.push(new MementoNodeCommand(
                this._nodeMemento,
                getNodesMemento()
            ));
            this._nodeMemento = null;
        }
    }
}

export const commands: CommandHandler = new CommandHandler();

export function recordAndExecute(
    execute: () => any,
    undo: () => any,
    name?: string
) {
    commands.execute(new Command(execute, undo, name));
}

const handleDown = (e: KeyboardEvent) => {
    if (e.ctrlKey && (e.key === 'z' || e.key === 'Z')) {
        if (e.shiftKey) {
            commands.redo();
        } else {
            commands.undo();
        }
    }
}
window.addEventListener('keydown', handleDown);