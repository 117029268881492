import React, {CSSProperties, ReactNode} from "react";

const FullscreenSvg = ({children, style}: { children?: ReactNode, style?: CSSProperties }) => <svg style={{
    position: 'absolute',
    left: 0,
    top: 0,
    overflow: 'visible',
    pointerEvents: 'none',
    height: '100vh',
    ...style
}}>
    {children}
</svg>

export default FullscreenSvg;