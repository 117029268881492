import React, {useRef} from "react";
import ZoomLabel from "./ZoomLabel";
import DarkLightModeButton from "./DarkLightModeButton";
import {useTypedSelector} from "../../store";

export const WorkspaceMenu = ({handleToggleDarkMode, darkMode}: {
    handleToggleDarkMode: () => any,
    darkMode: boolean
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const opacity = 1;

    const machineInitialized = useTypedSelector(state => !!state.nodes.machineState.initialized);

    return opacity > .01 ? <div
        ref={ref}
        style={{
            cursor: 'pointer',
            opacity: opacity
        }}
    >
        {machineInitialized && <div style={{
            position: 'absolute',
            right: 28,
            top: 3
        }}>
            <ZoomLabel
                style={{
                    textAlign: 'right'
                }}
                scale={100 / 75}
            />
        </div>}
        <div style={{
            position: 'absolute',
            right: 0,
            top: 0
        }}>
            <DarkLightModeButton onChange={handleToggleDarkMode} value={darkMode}/>
        </div>
    </div> : null;
}