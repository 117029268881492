import {ExecutableNode} from "../../store/nodes";
import React from "react";
import {useTypedSelector} from "../../store";
import {NodeStyleContext} from "../style/FancyNodeStyleProvider";
import FancyPortLabel from "./FancyPortLabel";
import FancyLabels from "./FancyLabels";
import {_scale} from "./index";

const FancyExecutableNodeLabels = ({node}: {
    node: ExecutableNode
}) => {
    return <>
        <div
            style={{
                position: 'absolute',
                left: node.shape.x * _scale,
                top: node.shape.y * _scale,
                width: node.shape.width * _scale,
                height: node.shape.height * _scale,
                pointerEvents: 'none'
            }}
        >
            {node.input?.map((port, i) => <FancyPortLabel
                direction={'in'}
                index={i}
                nodeId={node.id}
                key={node.id + "in" + i + "label"}
            />)}
            {node.output?.map((port, i) => <FancyPortLabel
                direction={'out'}
                index={i}
                nodeId={node.id}
                key={node.id + "out" + i + "label"}
            />)}
        </div>
        {node.children && <FancyLabels filter={n => n.parent === node.id} forceUpdate doNotFade/>}
    </>
}

const noShapeUpdate = (prevNodeProps: { node: ExecutableNode }, nextNodeProps: { node: ExecutableNode }) =>
    prevNodeProps.node.shape.x === nextNodeProps.node.shape.x &&
    prevNodeProps.node.shape.y === nextNodeProps.node.shape.y &&
    prevNodeProps.node.shape.width === nextNodeProps.node.shape.width &&
    prevNodeProps.node.shape.height === nextNodeProps.node.shape.height &&

    prevNodeProps.node.input?.filter(
        (v, i) => nextNodeProps.node.input && v !== nextNodeProps.node.input[i]
    ).length === 0 &&

    prevNodeProps.node.output?.filter(
        (v, i) => nextNodeProps.node.output && v !== nextNodeProps.node.output[i]
    ).length === 0

const MemoFancyExecutableNodeLabels = React.memo(FancyExecutableNodeLabels, noShapeUpdate);

const ConnectedExecutableNodeLabels = ({id, forceUpdate}: { id: string, forceUpdate?: boolean }) => {
    let node = useTypedSelector(state => state.nodes.nodes[id]) as ExecutableNode;

    return forceUpdate ? <FancyExecutableNodeLabels node={node}/> : <MemoFancyExecutableNodeLabels node={node}/>
}

export default ConnectedExecutableNodeLabels;