import {connect, ExecutableNode, hoverPort, PortPosition, setConnectionLine, unhoverPort} from "../../store/nodes";
import store, {useTypedSelector} from "../../store";
import React, {useContext} from "react";
import {NodeStyleContext} from "../style/FancyNodeStyleProvider";
import {useStyle} from "../../hooks";
import {deepPathSearch} from "../../common/graph";
import {openDialog} from "../../store/workspace";
import {connect_} from "../../common/session";
import {commands} from "../../common/session/CommandHandler";
import {_scale} from "./index";
import {useWarningColorMainOr} from "./FancyNodeHighlights";
import {getCircleSegment} from "../../common/svg";

const FancyPort = ({nodeId, direction, index, highlight, doNotHover}: {
    nodeId: string,
    highlight?: boolean,
    doNotHover?: boolean;
} & PortPosition) => {
    const hovered = useTypedSelector(state => {
        if (!state.nodes.hovered) return false;
        if (!state.nodes.hoveredPort) {
            return (
                direction === 'out'
                && state.nodes.forwardAdjacency[nodeId]
                && state.nodes.forwardAdjacency[nodeId][index]
                && state.nodes.forwardAdjacency[nodeId][index][state.nodes.hovered]
            ) || (
                direction === 'in'
                && state.nodes.backwardAdjacency[nodeId]
                && state.nodes.backwardAdjacency[nodeId][index]
                && state.nodes.backwardAdjacency[nodeId][index][state.nodes.hovered]
            )
        } else {
            return (
                direction === 'out'
                && state.nodes.hoveredPort.direction === 'in'
                && state.nodes.forwardAdjacency[nodeId]
                && state.nodes.forwardAdjacency[nodeId][index]
                && state.nodes.forwardAdjacency[nodeId][index][state.nodes.hovered]
                && state.nodes.forwardAdjacency[nodeId][index][state.nodes.hovered].includes(state.nodes.hoveredPort.index)
            ) || (
                direction === 'in'
                && state.nodes.hoveredPort.direction === 'out'
                && state.nodes.backwardAdjacency[nodeId]
                && state.nodes.backwardAdjacency[nodeId][index]
                && state.nodes.backwardAdjacency[nodeId][index][state.nodes.hovered]
                && state.nodes.backwardAdjacency[nodeId][index][state.nodes.hovered].includes(state.nodes.hoveredPort.index)
            )
        }
    });

    const data = useTypedSelector(
        state => {
            let exn = state.nodes.nodes[nodeId] as ExecutableNode;
            return direction === 'out' ?
                exn.output && exn.output[index].data :
                exn.input && exn.input[index].data
        }
    );

    const shell = useTypedSelector(
        state => {
            let exn = state.nodes.nodes[nodeId] as ExecutableNode;
            return direction === 'out' ?
                exn.output && exn.output[index].shell :
                exn.input && exn.input[index].shell
        }
    );

    const state = useTypedSelector(
        state => {
            let exn = state.nodes.nodes[nodeId] as ExecutableNode;
            return exn.state;
        }
    );

    const style = useContext(NodeStyleContext);

    const portSize = style.components.Port.size;
    const portDist = style.components.Port.spacing;
    const portStrokeWeight = style.components.Port.strokeWidth;

    const inputPosition = {
        left: -portSize / 2,
        top: (index + 1) * portDist - portSize / 2
    }

    const outputPosition = {
        right: -portSize / 2,
        top: (index + 1) * portDist - portSize / 2
    }

    const position = direction === 'in' ? inputPosition : outputPosition

    const handleMouseEnter = () => !doNotHover && store.dispatch(hoverPort({id: nodeId, direction, index}));
    const handleMouseLeave = () => store.dispatch(unhoverPort({id: nodeId, direction, index}));
    const handleMouseUp = () => {
        if (store.getState().nodes.mode === 'connect' && store.getState().nodes.connectionLine) {
            let line = store.getState().nodes.connectionLine;
            if (!line) return;

            let fromNode = line.start.id;
            let fromPort = line.start.index;

            let level = store.getState().nodes.nodes[line.start.id].parent || store.getState().nodes.nodes[nodeId].parent ? 1 : undefined;

            let from = {
                id: fromNode,
                index: fromPort
            }

            let to = {
                id: nodeId,
                index: index
            }

            if (line.direction === 'forward') {
                let loop = deepPathSearch(to.id, from.id, [], null);
                if (loop) store.dispatch(
                    openDialog({
                        type: 'error',
                        nodes: loop,
                        onClose: () => {
                            commands.undo()
                        }
                    })
                );
                //store.dispatch(connect(from, to, level));
                connect_(from, to, level);

            } else if (line.direction === 'backward') {
                connect_(to, from, level);
            }

            store.dispatch(setConnectionLine(undefined));
        }
    }

    let colorValue = style.components.Port.colorValue;
    let colorNoValue = (state === 'disabled' || state === 'initializing') ? (style.components.Port.disabled?.colorValue ?? colorValue) : colorValue;
    let colorHighlight = style.palette.highlight.main; //useWarningColorMainOr(nodeId, direction, index, style.palette.highlight.main);

    return <div
        style={{
            ...position,
            position: 'absolute',
            width: portSize,
            height: portSize,
            cursor: 'crosshair'
        }}

        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
    >
        <svg style={{
            ...position,
            position: 'absolute',
            left: 0,
            top: 0,
            width: portSize,
            height: portSize,
            overflow: 'visible'
        }}>
            <circle
                fill={data ? ((highlight || hovered) ? colorHighlight : colorValue) : style.components.Port.colorEmpty}
                stroke={(highlight || hovered) ? colorHighlight : colorNoValue}
                strokeWidth={data ? 0 : portStrokeWeight}
                cx={portSize / 2}
                cy={portSize / 2}
                r={portSize / 2 + (data ? portStrokeWeight / 4 : -portStrokeWeight / 4)}
            />
            {shell && <path
                fillOpacity={0}
                stroke={(highlight || hovered) ? colorHighlight : (data ? colorValue : colorNoValue)}
                strokeWidth={portStrokeWeight}
                d={getCircleSegment(
                    portSize / 2,
                    portSize / 2,
                    portSize + portStrokeWeight / 4,
                    direction === "out" ? 45 : -135,
                    direction === "out" ? 135 : -45
                )}
            />}
        </svg>
        {/*data ? <div
            style={{
                ...position,
                position: 'absolute',
                left: 0,
                top: 0,
                width: portSize,
                height: portSize,
                background: (highlight || hovered) ? colorHighlight : colorValue,
                borderRadius: '50%',
                cursor: 'crosshair'
            }}
        /> : <div
            style={{
                ...position,
                position: 'absolute',
                left: 0,
                top: 0,
                width: portSize - portStrokeWeight * 1.5,
                height: portSize - portStrokeWeight * 1.5,
                border: portStrokeWeight + "px solid " + ((highlight || hovered) ? colorHighlight : colorNoValue),
                background: style.components.Port.colorEmpty,
                borderRadius: '50%',
                cursor: 'crosshair'
            }}
        />*/}
    </div>
}

export default FancyPort;

export const IconPort = ({direction, index, highlight}: PortPosition & {highlight?: boolean}) => {
    const style = useStyle();

    const portSize = 6 * _scale * 1.125;
    const portDist = 12 * _scale;

    const inputPosition = {
        left: -portSize / 2,
        top: (index + 1) * portDist - portSize / 2
    }

    const outputPosition = {
        right: -portSize / 2,
        top: (index + 1) * portDist - portSize / 2
    }

    const position = direction === 'in' ? inputPosition : outputPosition

    return <div
        style={{
            ...position,
            position: 'absolute',
            width: portSize,
            height: portSize
        }}
    >
        <div
            style={{
                ...position,
                position: 'absolute',
                left: 0,
                top: 0,
                width: portSize,
                height: portSize,
                background: highlight ? style.palette.highlight.main : style.components.Port.color,
                borderRadius: portSize / 2
            }}
        />
    </div>
}