import {getSessionStorageOrDefault} from "../../../hooks/session";
import React, {ReactNode, useEffect, useLayoutEffect, useRef, useState} from "react";
import {useWindowDimensionUpdate} from "../../../hooks/window";
import {useTypedSelector} from "../../../store";
import {DraggableCore, DraggableData, DraggableEvent} from "react-draggable";
import {motion} from "framer-motion";
import FancyButtonBar from "./FancyButtonBar";
import {ExceptionIcon, InfoIcon} from "../../labels";
import {dist, getOverlap, intersects} from "../../../common/math";
import {ButtonTypes, AbsoluteRectangle, WindowControlButtonTypes, AbsoluteRectangleGroup} from "./FancyDialog";
import {getStartVertexString, getVertexString} from "../../../common/svg";
import {useWindowStyle} from "../../../hooks";
import {Cross} from "../../debug";
import {_DEBUG} from "../../../common/dev";
import ClickAway from "../ClickAway";
import {useZoomTranslate} from "../ZoomTranslate";

const buttonFont = 'Montserrat'
const textFont = 'Open Sans'

export interface TriangleProps {
    x1: number
    y1: number
    x2: number
    y2: number
    x3: number
    y3: number
}

export interface vec {
    x: number,
    y: number
}

export interface PolygonProps {
    vecs: vec[]
}

const triBB = (props: TriangleProps) => {
    const minX = Math.min(props.x1, props.x2, props.x3)
    const minY = Math.min(props.y1, props.y2, props.y3)
    const maxX = Math.max(props.x1, props.x2, props.x3)
    const maxY = Math.max(props.y1, props.y2, props.y3)
    return minX + ' ' + minY + ' ' + maxX + ' ' + maxY
}
const polyBB = (props: PolygonProps) => {
    const xx = props.vecs.map((v) => v.x)
    const yy = props.vecs.map((v) => v.y)

    const minX = Math.min(...xx)
    const minY = Math.min(...yy)
    const maxX = Math.max(...xx)
    const maxY = Math.max(...yy)

    return minX + ' ' + minY + ' ' + maxX + ' ' + maxY
}

export const Triangle = (props: TriangleProps & { fill: string }) => <svg viewBox={triBB(props)}>
    <path fill={props.fill} d={
        getStartVertexString(props.x1, props.y1) + ' ' +
        getVertexString(props.x2, props.y2) + ' ' +
        getVertexString(props.x3, props.y3)}/>
</svg>

export const Polygon = (props: PolygonProps & { fill: string, viewBox?: string }) => {
    if (props.vecs.length < 3) return null;
    const vec0 = props.vecs[0];
    let pathString = getStartVertexString(vec0.x, vec0.y)
    for (let i = 1; i < props.vecs.length; i++)
        pathString += ' ' + getVertexString(props.vecs[i].x, props.vecs[i].y)
    return (<svg viewBox={props.viewBox || polyBB(props)}>
        <path fill={props.fill} d={pathString}/>
    </svg>)
}

export const iconMap = {
    "info": InfoIcon,
    "exception": ExceptionIcon
}

export interface FancyWindowProps {
    windowControlButtons?: WindowControlButtonTypes
    answerButtons?: ButtonTypes
    answerButtonProps?: {[key: string]: {disabled?: boolean}}
    callback?: (s: string) => void
    appearance?: 'default' | 'balloon'
    pointTo?: number[]
    pointToRect?: AbsoluteRectangle | AbsoluteRectangleGroup
    autoPosition?: boolean
    shadowColor?: 'exception' | 'error' | 'warning' | (string & {})
    children?: ReactNode
    resizable?: boolean
    title?: string
    header?: boolean
    hollow?: boolean
    hAlign?: 'left' | 'center' | 'right'
    vAlign?: 'top' | 'center' | 'bottom'
    x?: number
    y?: number
    width?: number
    minWidth?: number
    maxWidth?: number
    height?: number
    highlightColor?: string
    onClickAway?: () => any
    onDrag?: (event: DraggableEvent, data: DraggableData) => any
    icon?: keyof typeof iconMap | ReactNode
    iconSize?: number
    paddingTop?: number
}

function BubbleArrow({x, y, winWidth, winHeight, width, height, base, pointTo}: {
    x: number
    y: number
    winWidth: number
    winHeight: number
    width: number
    height: number
    base: string
    pointTo?: number[]
}) {
    if (!pointTo) return null;

    pointTo = [pointTo[0] - width / 2, pointTo[1] - height / 2];

    const bb = [(x + winWidth / 2) - width / 2, (y + winHeight / 2) - height / 2, width, height]
    const left = bb[0], right = bb[0] + bb[2]
    const top = bb[1], bottom = bb[1] + bb[3]
    const triW = 24, triH = 12, triWH = triW + triH, triWh = triW / 2
    const off = 1, triM = -(triH - off)
    const tzx = 50, tzy = 50

    const TriDown = (props: { sx: number }) => <Polygon
        fill={base as string}
        vecs={[{x: 0, y: 0}, {x: triW, y: 0}, {x: triWh + props.sx, y: triH}]}/>

    const TriUp = (props: { sx: number }) => <Polygon
        fill={base as string}
        vecs={[{x: triW, y: triH}, {x: 0, y: triH}, {x: triWh + props.sx, y: 0}]}/>

    const TriRight = (props: { sy: number }) => <Polygon
        fill={base as string}
        vecs={[{x: 0, y: triW}, {x: 0, y: 0}, {x: triH, y: triWh + props.sy}]}/>

    const TriLeft = (props: { sy: number }) => <Polygon
        fill={base as string}
        vecs={[{x: triH, y: 0}, {x: triH, y: triW}, {x: 0, y: triWh + props.sy}]}/>

    const edgeBox = '0 0 ' + triWH + ' ' + triWH
    const TriRightBottom = (props: { sx: number, sy: number }) => <Polygon
        fill={base as string}
        viewBox={edgeBox}
        vecs={[{x: triW, y: triW}, {x: triW, y: 0}, {
            x: triW + props.sx * triH,
            y: triW + props.sy * triH
        }, {x: 0, y: triW}]}/>

    const TriLeftBottom = (props: { sx: number, sy: number }) => <Polygon
        fill={base as string}
        viewBox={edgeBox}
        vecs={[{x: triH, y: triW}, {x: triH, y: 0}, {
            x: triH + props.sx * triH,
            y: triW + props.sy * triH
        }, {x: triWH, y: triW}]}/>

    const TriRightTop = (props: { sx: number, sy: number }) => <Polygon
        fill={base as string}
        viewBox={edgeBox}
        vecs={[{x: triW, y: triH}, {x: triW, y: triWH}, {
            x: triW + props.sx * triH,
            y: triH + props.sy * triH
        }, {x: 0, y: triH}]}/>

    const TriLeftTop = (props: { sx: number, sy: number }) => <Polygon
        fill={base as string}
        viewBox={edgeBox}
        vecs={[{x: triH, y: triH}, {x: triH, y: triWH}, {
            x: triH + props.sx * triH,
            y: triH + props.sy * triH
        }, {x: triWH, y: triH}]}/>

    if (pointTo[0] > left && pointTo[0] < right) {
        let sl = 0;
        if (pointTo[0] - left < tzx) sl = (pointTo[0] - left - tzx) * triWh / tzx
        if (right - pointTo[0] < tzx) sl = -(right - pointTo[0] - tzx) * triWh / tzx
        if (pointTo[1] > bottom) {
            return <div style={{
                position: 'absolute',
                bottom: 0,
                left: pointTo[0] - left - triWh - sl,
                width: triW, height: triH, lineHeight: 0, marginBottom: triM
            }}><TriDown sx={sl}/></div>
        } else if (pointTo[1] < top) {
            return <div style={{
                position: 'absolute',
                top: 0,
                left: pointTo[0] - left - triWh - sl,
                width: triW, height: triH, lineHeight: 0, marginTop: triM
            }}><TriUp sx={sl}/></div>
        }

    } else if (pointTo[1] > top && pointTo[1] < bottom) {
        let st = 0;
        if (pointTo[1] - top < tzy) st = (pointTo[1] - top - tzy) * triWh / tzy
        if (bottom - pointTo[1] < tzy) st = -(bottom - pointTo[1] - tzy) * triWh / tzy
        if (pointTo[0] > right) {
            return <div style={{
                position: 'absolute',
                right: 0,
                top: pointTo[1] - top - triWh - st,
                width: triH, height: triW, lineHeight: 0, marginRight: triM
            }}><TriRight sy={st}/></div>
        } else if (pointTo[0] < left) {
            return <div style={{
                position: 'absolute',
                left: 0,
                top: pointTo[1] - top - triWh - st,
                width: triH, height: triW, lineHeight: 0, marginLeft: triM
            }}><TriLeft sy={st}/></div>
        }

    } else if (pointTo[0] >= right && pointTo[1] >= bottom) {
        const d = dist(right, bottom, pointTo[0], pointTo[1])
        const pointToN = [(pointTo[0] - right) / d, (pointTo[1] - bottom) / d]
        return <div style={{
            pointerEvents: 'none',
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: triWH, height: triWH,
            lineHeight: 0,
            marginRight: triM, marginBottom: triM
        }}><TriRightBottom sx={pointToN[0]} sy={pointToN[1]}/></div>

    } else if (pointTo[0] <= left && pointTo[1] >= bottom) {
        const d = dist(left, bottom, pointTo[0], pointTo[1])
        const pointToN = [(pointTo[0] - left) / d, (pointTo[1] - bottom) / d]
        return <div style={{
            pointerEvents: 'none',
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: triWH, height: triWH,
            lineHeight: 0,
            marginLeft: triM, marginBottom: triM
        }}><TriLeftBottom sx={pointToN[0]} sy={pointToN[1]}/></div>

    } else if (pointTo[0] <= left && pointTo[1] <= top) {
        const d = dist(left, top, pointTo[0], pointTo[1])
        const pointToN = [(pointTo[0] - left) / d, (pointTo[1] - top) / d]
        return <div style={{
            pointerEvents: 'none',
            position: 'absolute',
            left: 0,
            top: 0,
            width: triWH, height: triWH,
            lineHeight: 0,
            marginLeft: triM, marginTop: triM
        }}><TriLeftTop sx={pointToN[0]} sy={pointToN[1]}/></div>

    } else if (pointTo[0] >= right && pointTo[1] <= top) {
        const d = dist(right, top, pointTo[0], pointTo[1])
        const pointToN = [(pointTo[0] - right) / d, (pointTo[1] - top) / d]
        return <div style={{
            pointerEvents: 'none',
            position: 'absolute',
            right: 0,
            top: 0,
            width: triWH, height: triWH,
            lineHeight: 0,
            marginRight: triM, marginTop: triM
        }}><TriRightTop sx={pointToN[0]} sy={pointToN[1]}/></div>
    }

    return null;
}

type Orientations = 'top' | 'bottom' | 'left' | 'right';
let preference: Orientations[] = ['top', 'right', 'left', 'bottom'];

function findPosition(
    focusRect: AbsoluteRectangle,
    windowRect: AbsoluteRectangle,
    dialogWidth: number,
    dialogHeight: number,
    focusPadding: number,
    windowPadding: number
): number[] {
    let dLeft = focusRect.x1 - windowRect.x1;
    let dRight = windowRect.x2 - focusRect.x2;
    let dTop = focusRect.y1 - windowRect.y1;
    let dBottom = windowRect.y2 - focusRect.y2;

    let orientation;
    for (let pref of preference) {
        if ((pref === 'top' && dTop > dialogHeight + focusPadding + windowPadding) ||
            (pref === 'bottom' && dBottom > dialogHeight + focusPadding + windowPadding) ||
            (pref === 'right' && dRight > dialogWidth + focusPadding + windowPadding) ||
            (pref === 'left' && dLeft > dialogWidth + focusPadding + windowPadding)) {

            orientation = pref;
            break;
        }
    }

    if (!orientation) return [(windowRect.x1 + windowRect.x2) / 2 - dialogWidth / 2, (windowRect.y1 + windowRect.y2) / 2 - dialogHeight / 2];

    let fcx = (focusRect.x1 + focusRect.x2) / 2
    let fcy = (focusRect.y1 + focusRect.y2) / 2

    if (orientation === 'top') {
        let x = fcx - Math.min(144, dialogWidth / 2);
        if (x < windowRect.x1) x = windowRect.x1;
        if (x + dialogWidth > windowRect.x2) x = windowRect.x2 - dialogWidth;
        console.log([x, focusRect.y1 - focusPadding - dialogHeight]);
        return [x, focusRect.y1 - focusPadding - dialogHeight]

    } else if (orientation === 'bottom') {
        let x = fcx - Math.min(144, dialogWidth / 2);
        if (x < windowRect.x1) x = windowRect.x1;
        if (x + dialogWidth > windowRect.x2) x = windowRect.x2 - dialogWidth;
        return [x, focusRect.y2 + focusPadding]

    } else if (orientation === 'right') {
        let y = fcy - dialogHeight / 2;
        if (y < windowRect.y1) y = windowRect.y1;
        if (y + dialogHeight > windowRect.y2) y = windowRect.y2 - dialogHeight;
        return [focusRect.x2 + focusPadding, y]

    } else {//if (orientation === 'left') {
        let y = fcy - dialogHeight / 2;
        if (y < windowRect.y1) y = windowRect.y1;
        if (y + dialogHeight > windowRect.y2) y = windowRect.y2 - dialogHeight;
        return [focusRect.x1 - focusPadding - dialogWidth, y]
    }
}

function getPossiblePositions(
    focusRect: AbsoluteRectangle,
    windowRect: AbsoluteRectangle,
    dialogWidth: number,
    dialogHeight: number,
    focusPadding: number,
    windowPadding: number
): (number[] | null)[] {
    let dLeft = focusRect.x1 - windowRect.x1;
    let dRight = windowRect.x2 - focusRect.x2;
    let dTop = focusRect.y1 - windowRect.y1;
    let dBottom = windowRect.y2 - focusRect.y2;

    type Orientations = 'top' | 'bottom' | 'left' | 'right';
    let preference: Orientations[] = ['top', 'right', 'left', 'bottom'];

    let orientations: (Orientations | null)[] = [];
    for (let pref of preference) {
        if ((pref === 'top' && dTop > dialogHeight + focusPadding + windowPadding) ||
            (pref === 'bottom' && dBottom > dialogHeight + focusPadding + windowPadding) ||
            (pref === 'right' && dRight > dialogWidth + focusPadding + windowPadding) ||
            (pref === 'left' && dLeft > dialogWidth + focusPadding + windowPadding)) {

            orientations.push(pref);
        } else orientations.push(null);
    }

    if (orientations.length === 0) return [[(windowRect.x1 + windowRect.x2) / 2 - dialogWidth / 2, (windowRect.y1 + windowRect.y2) / 2 - dialogHeight / 2]];

    let fcx = (focusRect.x1 + focusRect.x2) / 2
    let fcy = (focusRect.y1 + focusRect.y2) / 2

    return orientations.map(orientation => {
        if (orientation === null) return null;
        if (orientation === 'top') {
            let x = fcx - Math.min(144, dialogWidth / 2);
            if (x < windowRect.x1) x = windowRect.x1;
            if (x + dialogWidth > windowRect.x2) x = windowRect.x2 - dialogWidth;
            console.log([x, focusRect.y1 - focusPadding - dialogHeight]);
            return [x, focusRect.y1 - focusPadding - dialogHeight]

        } else if (orientation === 'bottom') {
            let x = fcx - Math.min(144, dialogWidth / 2);
            if (x < windowRect.x1) x = windowRect.x1;
            if (x + dialogWidth > windowRect.x2) x = windowRect.x2 - dialogWidth;
            return [x, focusRect.y2 + focusPadding]

        } else if (orientation === 'right') {
            let y = fcy - dialogHeight / 2;
            if (y < windowRect.y1) y = windowRect.y1;
            if (y + dialogHeight > windowRect.y2) y = windowRect.y2 - dialogHeight;
            return [focusRect.x2 + focusPadding, y]

        } else {//if (orientation === 'left') {
            let y = fcy - dialogHeight / 2;
            if (y < windowRect.y1) y = windowRect.y1;
            if (y + dialogHeight > windowRect.y2) y = windowRect.y2 - dialogHeight;
            return [focusRect.x1 - focusPadding - dialogWidth, y]
        }
    });
}

function FancyWindow(props: FancyWindowProps) {
    const theme = useWindowStyle();

    const {width: winWidth, height: winHeight} = useWindowDimensionUpdate();

    const swr = /*getSessionStorageOrDefault('dialogrect', */{
        x: props.x ? props.x - winWidth / 2 : 0,
        y: props.y ? props.y - winHeight / 2 : 0,
        width: props.width || theme.window.defaultWidth,
        height: props.height || 0
    }/*)*/

    const [pointTo, setPointTo] = useState<number[] | undefined>(props.pointTo);
    const iconSize = props.iconSize || 36

    const [ssc, setSsc] = useState(0)
    const sio = -theme.shadow.spreadRadius
    const spx = theme.shadow.offsetX
    const spy = theme.shadow.offsetY
    const shadow = props.shadowColor || theme.shadow.color// (dark ? '#0000008c' : 'rgba(0,32,80,0.45)') //dark ? 'rgba(255,0,30,0.5)' : 'rgba(255,0,30,0.75)'

    const base = theme.window.background
    const color = theme.window.color

    const padding = theme.window.padding as number
    const paddingTop = props.paddingTop;
    const bHeight = 40
    const bWidth = 80
    const wcbSize = theme.window.windowControlButtonSize
    const wcbSpacing = theme.window.windowControlButtonSpacing
    const wcbPadding = theme.window.windowControlBarPadding
    const wcbHeight = 2 * wcbPadding + wcbSize

    const [width, setWidth_] = useState<number>(swr.width);
    const minWidth = props.minWidth || theme.window.minWidth;
    const maxWidth = props.maxWidth || theme.window.maxWidth;
    const [height, setHeight] = useState(swr.height)
    const [x, setX] = useState(props.x ? props.x - winWidth / 2 : swr.x)
    const [y, setY] = useState(props.y ? props.y - winHeight / 2 : swr.y)
    //const [startX, setStartX] = useState(0)
    //const [startY, setStartY] = useState(0)

    const ref = useRef<HTMLDivElement>(null)
    //const [bb, setBB] = useState<number[]>([0, 0, 0, 0])
    const [dragging, setDragging] = useState<boolean>(false)

    useLayoutEffect(() => {
        if (ref.current) {
            const h = height || ref.current.clientHeight
            setHeight(h)
            setSsc(Math.min(theme.shadow.blurRadius, (h - sio * 2) / 2))

            if (!props.x && !props.y && (props.pointToRect || pointTo)) {
                let ptr = props.pointToRect ?? (pointTo ? {
                    x1: pointTo[0],
                    y1: pointTo[1],
                    x2: pointTo[0],
                    y2: pointTo[1]
                } : {x1: 0, y1: 0, x2: 0, y2: 0})

                let pos: number[];
                if (!Array.isArray(ptr)) pos = findPosition(
                    ptr,
                    {x1: 24, y1: 24, x2: winWidth - 24, y2: winHeight - 24},
                    width,
                    h,
                    40,
                    0
                ); else {
                    let pp: [AbsoluteRectangle, (number[] | null)[]][] = ptr.map(r => {
                        return [r, getPossiblePositions(
                            r,
                            {x1: 24, y1: 24, x2: winWidth - 24, y2: winHeight - 24},
                            width,
                            h,
                            40,
                            0
                        )]
                    });
                    let ranking: [AbsoluteRectangle, number[], number, number][] = [];
                    pp.forEach(([ar, positions]) => {
                        positions.forEach((p, i) => {
                            if (p === null) return;
                            let resultRect = [
                                p[0],
                                p[1],
                                p[0] + width,
                                p[1] + h
                            ];
                            let aura = 32;
                            let resultRectAura = [
                                p[0] - aura,
                                p[1] - aura,
                                p[0] + width + aura,
                                p[1] + h + aura
                            ];
                            let area = 0;
                            const evaluate = (rectangle: number[], tolerance: number, factor: number) => (ptr as AbsoluteRectangleGroup).forEach(rr => {
                                let interX = intersects([rr.x1, rr.x2], [rectangle[0], rectangle[2]], -tolerance);
                                let interY = intersects([rr.y1, rr.y2], [rectangle[1], rectangle[3]], -tolerance);
                                if (interX && interY) {
                                    let overlapX = getOverlap([rr.x1, rr.x2], [rectangle[0] - tolerance, rectangle[2] + tolerance]);
                                    let overlapY = getOverlap([rr.y1, rr.y2], [rectangle[1] - tolerance, rectangle[3] + tolerance]);
                                    let overlapArea = (overlapX[1] - overlapX[0]) * (overlapY[1] - overlapY[0]);
                                    area += overlapArea * factor;
                                }
                            });
                            /*
                            (ptr as AbsoluteRectangleGroup).forEach(rr => {
                                let interX = intersects([rr.x1, rr.x2], [resultRect[0], resultRect[2]], -8);
                                let interY = intersects([rr.y1, rr.y2], [resultRect[1], resultRect[3]], -8);
                                if (interX && interY) {
                                    let overlapX = getOverlap([rr.x1, rr.x2], [resultRect[0] - 8, resultRect[2] + 8]);
                                    let overlapY = getOverlap([rr.y1, rr.y2], [resultRect[1] - 8, resultRect[3] + 8]);
                                    let overlapArea = (overlapX[1] - overlapX[0]) * (overlapY[1] - overlapY[0]);
                                    area += overlapArea;
                                }
                            });
                            (ptr as AbsoluteRectangleGroup).forEach(rr => {
                                let interX = intersects([rr.x1, rr.x2], [resultRectAura[0], resultRectAura[2]], -8);
                                let interY = intersects([rr.y1, rr.y2], [resultRectAura[1], resultRectAura[3]], -8);
                                if (interX && interY) {
                                    let overlapX = getOverlap([rr.x1, rr.x2], [resultRectAura[0] - 8, resultRectAura[2] + 8]);
                                    let overlapY = getOverlap([rr.y1, rr.y2], [resultRectAura[1] - 8, resultRectAura[3] + 8]);
                                    let overlapArea = (overlapX[1] - overlapX[0]) * (overlapY[1] - overlapY[0]);
                                    area += overlapArea * .25;
                                }
                            });
                             */
                            evaluate(resultRect, 0, 1);
                            evaluate(resultRectAura, 0, .25);

                            ranking.push([ar, p, area, i]);
                            console.log([ar, p, area, i]);
                        });
                    });
                    let best: [AbsoluteRectangle, number[], number, number] | null = null;
                    let bestC: number = 999999;
                    let screenCenter = [winWidth / 2, winHeight / 2];
                    ranking.forEach(r => {
                        let c = dist(
                            screenCenter[0],
                            screenCenter[1],
                            r[1][0] + width / 2,
                            r[1][1] + h / 2
                        );

                        if (best === null) {
                            best = r;
                            bestC = c;
                        } else {
                            //if (r[3] < best[3]) best = r;
                            //else if (r[3] === best[3] && r[2] < best[2]) best = r;

                            if (r[3] === best[3]) {
                                if (r[2] < best[2]) best = r;
                                else if (r[2] === best[2]) {
                                    if (c < bestC) {
                                        best = r;
                                        bestC = c;
                                    }
                                }
                            } else if (r[3] > best[3]) {
                                if (r[2] * 1.1 <= best[2] && c < bestC) {
                                    best = r;
                                    bestC = c;
                                }
                            } else if (r[3] < best[3]) {
                                if (r[2] <= best[2] * 1.1 && c < bestC) {
                                    best = r;
                                    bestC = c;
                                }
                            }
                        }
                    });
                    console.log(best);
                    pos = best ? best[1] : [0, 0];
                    if (best) {
                        let rect = best[0] as AbsoluteRectangle
                        setPointTo([(rect.x1 + rect.x2) / 2, (rect.y1 + rect.y2) / 2]);
                    }
                    console.log(best)
                }
                setX(pos[0] - winWidth / 2);
                setY(pos[1] - winHeight / 2);
            }

            //setBB([domRect.x, domRect.y, domRect.width, domRect.height])
        } else setSsc(theme.shadow.blurRadius)
    }, [ref])

    useEffect(() => {
        const windowRect = {x: x, y: y, width: width, height: height}
        sessionStorage.setItem('dialogrect', JSON.stringify(windowRect));
    }, [x, y, width, height]);

    const setWidth = (w: number) => {
        if (minWidth && w < minWidth) w = minWidth;
        if (maxWidth && w > maxWidth) w = maxWidth;
        setWidth_(w);
        return w;
    }

    const windowControlBarFetch = (s: string) => {
        if (props.callback) props.callback(s);
    }

    const resizer = <DraggableCore
        onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault()
        }}
        onDrag={(event, data) => {
            let deltaX = data.x
            let deltaY = data.y

            const w = setWidth(deltaX);

            if (ref.current && !props.height) {
                const h = ref.current.clientHeight
                setHeight(h)
                setSsc(Math.min(theme.shadow.blurRadius, (h - sio * 2) / 2))
                console.log("HEIGHT: " + h)
            }
        }}
        onStart={(event, data) => {
            document.body.style.cursor = 'nwse-resize'
        }}
        onStop={(event, data) => {
            data.node.style.transform = 'translate(0,0)'
            document.body.style.cursor = 'default'
        }}
    >
        <div style={{
            position: 'absolute',
            right: -2,
            bottom: -2,
            width: 12,
            height: 12,
            cursor: 'nwse-resize'
        }}/>
    </DraggableCore>

    const windowControlBar = <FancyButtonBar
        buttons={props.windowControlButtons || ['x']}
        callback={windowControlBarFetch}
        margin={wcbPadding}
        buttonHeight={wcbSize}
        buttonWidth={wcbSize}
        spacing={wcbSpacing}
        borderRadius={wcbSize / 2}
        position='top'
        simplify={dragging}
        background={base}
        color={color}
        fontFamily={buttonFont}/>

    const answerButtonBar = props.answerButtons ? <FancyButtonBar
        buttons={props.answerButtons}
        buttonProps={props.answerButtonProps}
        buttonHeight={32}
        borderRadius={16}
        callback={props.callback}
        position='bottom'
        simplify={dragging}
        background={base}
        color={color}
        fontFamily={buttonFont}/> : null

    const createBackgroundPane = (children: ReactNode) => {
        if ((paddingTop === undefined || paddingTop === padding) || !props.hollow) {
            return <div style={{
                position: 'relative',
                left: 0,
                top: 0,
                width: '100%',
                background: props.hollow ? 'transparent' : base,
                boxShadow: props.hollow ? "inset 0px 0px 0px " + padding + "px " + base : undefined,
                paddingBottom: props.header ? 0 : -wcbHeight,
                //marginBottom: 2,
                //borderRadius: cr + 'px ' + cr + 'px 0 0'
            }}>{children}</div>
        } else {
            const shift = padding - paddingTop;

            return <div style={{
                position: 'relative',
                left: 0,
                top: 0,
                width: '100%',
                paddingBottom: props.header ? 0 : -wcbHeight
            }}>
                <div style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden'
                }}>
                    <div style={{
                        position: 'absolute',
                        left: 0,
                        top: -shift,
                        width: '100%',
                        height: 'calc(100% + ' + shift + 'px)',
                        background: 'transparent',
                        boxShadow: "inset 0px 0px 0px " + padding + "px " + base
                    }}/>
                </div>
                {children}
            </div>
        }
    }

    return <div style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999,
        pointerEvents: 'none',
        overflow: 'hidden'
    }}>
        {props.onClickAway && <ClickAway disappearOnClick onClick={props.onClickAway}/>}
        <DraggableCore
            onMouseDown={(e) => {
                e.stopPropagation();
                e.preventDefault()
            }}
            onStart={(e, data) => {
                setDragging(true)
                //setStartX(data.x - winWidth / 2)
                //setStartY(data.y - winHeight / 2)
            }}
            onStop={(e, data) => {
                setX(x + data.deltaX)
                setY(y + data.deltaY)
                setDragging(false)
            }}
            onDrag={(e, data) => {
                setX(x + data.deltaX)
                setY(y + data.deltaY)
                /*
                if (ref.current) {
                    const domRect = ref.current.getBoundingClientRect()
                    setBB([domRect.x, domRect.y, domRect.width, domRect.height])
                }
                */
                props.onDrag && props.onDrag(e, data);
            }}>
            <motion.div
                ref={ref}
                key='motion-wrapper'
                style={{
                    position: 'absolute',
                    left: winWidth / 2 + (x ?? 0),
                    top: winHeight / 2 + (y ?? 0),
                    width: width,
                    pointerEvents: 'auto',
                    cursor: 'grab'
                }}
                initial={{
                    scale: 0.8,
                    opacity: 0
                }}
                animate={{
                    scale: 1,
                    opacity: 1,
                    transition: {
                        type: 'spring',
                        bounce: .25,
                        duration: .6
                    }
                }}
                exit={{
                    scale: 0.8,
                    opacity: 0,
                    transition: {
                        ease: (x: number) => Math.pow(x, 3),
                        duration: .4
                    }
                }}>
                <div style={{
                    position: 'absolute',
                    left: ssc + spx + sio,
                    top: ssc + spy + sio,
                    width: 'calc(100% - ' + (ssc * 2 + sio * 2) + 'px)',
                    height: 'calc(100% - ' + (ssc * 2 + sio * 2) + 'px)',
                    background: shadow,
                    boxShadow: '0px 0px ' + ssc + 'px ' + ssc + 'px ' + shadow
                }}/>
                {windowControlBar}
                {createBackgroundPane(<>
                    {props.header && <>
                        {props.icon && ((!Object.keys(iconMap).includes(props.icon as string) && React.isValidElement(props.icon)) ?
                            <div style={{
                                position: 'relative',
                                color: color,
                                textAlign: 'left',
                                margin: '0 16px -16px 16px',
                                top: padding - wcbHeight,
                                width: iconSize,
                                height: iconSize
                            }}>
                                <div style={{position: 'absolute', top: iconSize / 2, left: iconSize / 2}}>
                                    {props.icon}
                                </div>
                            </div> :
                            <div style={{
                                position: 'relative',
                                color: color,
                                textAlign: 'left',
                                margin: '0 16px -16px 16px',
                                top: padding - wcbHeight,//-16,
                                background: props.highlightColor ?? theme.palette.highlight.main,
                                width: iconSize,
                                height: iconSize,
                                borderRadius: '50%'
                            }}>
                                {React.createElement(iconMap[props.icon as keyof typeof iconMap], {
                                    color: '#ffffff',
                                    width: iconSize,
                                    height: iconSize
                                })}
                            </div>)}
                        <div style={{
                            position: 'absolute',
                            left: iconSize + 18, //54,
                            top: iconSize / 2 - 38, //-18,
                            color: color,
                            textAlign: 'left',
                            padding: 12,
                            fontFamily: 'Montserrat',
                            fontSize: 16,
                            fontWeight: 700
                        }}>{props.title}</div>
                    </>}
                    <div style={{
                        position: 'relative',
                        color: color,
                        textAlign: 'left',
                        paddingTop: padding,
                        paddingRight: padding,
                        paddingBottom: 0,
                        paddingLeft: padding,
                        //padding: padding + 'px ' + padding + 'px 0 ' + padding + 'px',
                        //top: props.header ? 0 : - wcbHeight,
                        fontFamily: theme.window.fontFamily,
                        fontSize: theme.window.fontSize,
                        fontWeight: theme.window.fontWeight,
                        whiteSpace: 'pre-wrap',
                        pointerEvents: 'none'
                    }}>
                        <div style={{
                            marginTop: props.header ? 0 : -wcbHeight,
                            marginBottom: 0//props.header ? 0 : - wcbHeight,
                        }}>{props.children}</div>
                    </div>
                </>)}
                {answerButtonBar}
                {pointTo && _DEBUG && <Cross
                    x={pointTo[0] - x - winWidth / 2}
                    y={pointTo[1] - y - winHeight / 2}
                />}
                <BubbleArrow
                    x={x}
                    y={y}
                    winWidth={winWidth}
                    winHeight={winHeight}
                    width={width}
                    height={height}
                    base={base as string}
                    pointTo={pointTo}
                />

                {props.resizable && resizer}
            </motion.div>
        </DraggableCore>
    </div>
}

export default FancyWindow;