import {FancySliderProps} from "../FancySlider";
import {PortControllerSettings} from "../index";
import store, {useTypedSelector} from "../../../store";
import {useStyle} from "../../../hooks";
import React, {ReactNode, useEffect, useLayoutEffect, useState} from "react";
import {InputBase} from "@mui/material";
import {InputBaseProps} from "@mui/material/InputBase/InputBase";
import {loremIpsumShort} from "../../../common/dev";
import {setActiveDialogCallback} from "../../../store/workspace";
import deepEqual from "deep-equal";
import {useResetClose} from "../../../hooks/window/dialogs";
import LabeledComponent from "./LabeledComponent";
import StyledSettingsInput from "./StyledSettingsInput";

const FancySliderSettings = (props: PortControllerSettings<FancySliderProps>) => {
    const id = props.id;
    const controller = useTypedSelector(state => state.nodes.controllers[id]);
    const style = useStyle();

    const [min_, setMin_] = useState<string | undefined>(undefined);
    const [max_, setMax_] = useState<string | undefined>(undefined);
    const [value_, setValue_] = useState<string | undefined>(undefined);
    const [step_, setStep_] = useState<string | undefined>(undefined);

    const [initial] = useResetClose<typeof controller>(controller, props.onChange);

    const getNumberValue = (value: string): number | undefined => {
        if (value === undefined) return;

        let cleanValue = value.replace(",", ".");
        let numberValue = parseFloat(cleanValue);
        if (numberValue && !Number.isNaN(numberValue)) {
            return numberValue;
        }
    }

    const handleChange = (key: string, value: string | undefined, check?: (x: number) => number): number | undefined => {
        if (value === undefined) return;
        let numberValue = getNumberValue(value);
        if (numberValue !== undefined) {
            if (check) numberValue = check(numberValue);
            props.onChange({[key]: numberValue});
        }
        return numberValue;
    }

    const handleMinCommit = () => {
        let min = handleChange("min", min_);
        if (min !== undefined && (min > controller.max)) {
            props.onChange({min: controller.max});
            min = controller.max;
        }
        if (min !== undefined && (min > controller.value)) {
            props.onChange({value: min});
        }
        setMin_(undefined);
        deleteCloseCallback();
    }

    const handleMaxCommit = () => {
        let max = handleChange("max", max_);
        if (max !== undefined && (max < controller.min)) {
            props.onChange({max: controller.min});
            max = controller.min;
        }
        if (max !== undefined && (max < controller.value)) {
            props.onChange({value: max});
        }
        setMax_(undefined);
        deleteCloseCallback();
    }

    const handleValueCommit = () => {
        let check = (value: number) => {
            if (value !== undefined && (value % controller.step !== 0)) {
                return Math.round(value / controller.step) * controller.step;
            }
            return value;
        }
        let value = handleChange("value", value_, check);
        if (value !== undefined && (value < controller.min)) {
            props.onChange({value: controller.min});
        }
        if (value !== undefined && (value > controller.max)) {
            props.onChange({value: controller.max});
        }
        setValue_(undefined);
        deleteCloseCallback();
    }

    const handleStepCommit = () => {
        let step = handleChange("step", step_);
        if (step !== undefined && (controller.value % step !== 0)) {
            props.onChange({value: Math.round(controller.value / step) * step});
        }
        setStep_(undefined);
        deleteCloseCallback();
    }



    const handleMinCloseCommit = (v: string) => {
        let min = getNumberValue(v);
        if (min !== undefined) props.onChange({min});
        if (min !== undefined && (min > controller.max)) {
            props.onChange({min: controller.max});
            min = controller.max;
        }
        if (min !== undefined && (min > controller.value)) {
            props.onChange({value: min});
        }
        setMin_(undefined);
        deleteCloseCallback();
    }

    const handleMaxCloseCommit = (v: string) => {
        let max = getNumberValue(v);
        if (max !== undefined) props.onChange({max});
        if (max !== undefined && (max < controller.min)) {
            props.onChange({max: controller.min});
            max = controller.min;
        }
        if (max !== undefined && (max < controller.value)) {
            props.onChange({value: max});
        }
        setMax_(undefined);
        deleteCloseCallback();
    }

    const handleValueCloseCommit = (v: string) => {
        let value = getNumberValue(v);
        if (value !== undefined && (value % controller.step !== 0)) {
            value = Math.round(value / controller.step) * controller.step;
        }
        if (value !== undefined) props.onChange({value});
        if (value !== undefined && (value < controller.min)) {
            props.onChange({value: controller.min});
        }
        if (value !== undefined && (value > controller.max)) {
            props.onChange({value: controller.max});
        }
        setValue_(undefined);
        deleteCloseCallback();
    }

    const handleStepCloseCommit = (v: string) => {
        let step = getNumberValue(v);
        if (step !== undefined) props.onChange({step});
        if (step !== undefined && (controller.value % step !== 0)) {
            props.onChange({value: Math.round(controller.value / step) * step});
        }
        setStep_(undefined);
        deleteCloseCallback();
    }


    const setCloseCallback = (callback: (() => any) | undefined) => {
        store.dispatch(setActiveDialogCallback("Close", callback));
    }

    const deleteCloseCallback = () => store.dispatch(setActiveDialogCallback("Close", undefined));

    const handleMinChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setMin_(event.target.value);
        setCloseCallback(() => handleMinCloseCommit(event.target.value));
    }

    const handleMaxChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setMax_(event.target.value);
        setCloseCallback(() => handleMaxCloseCommit(event.target.value));
    }

    const handleValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue_(event.target.value);
        setCloseCallback(() => handleValueCloseCommit(event.target.value));
    }

    const handleStepChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setStep_(event.target.value);
        setCloseCallback(() => handleStepCloseCommit(event.target.value));
    }

    return <><div style={{marginBottom: 16}}>{loremIpsumShort()}</div><div style={{display: 'flex', flexWrap: 'wrap', columnGap: 12, rowGap: 6}}>
        <div style={{position: 'relative', width: 200, minWidth: '45%', height: 28, flexGrow: 1}}>
            <LabeledComponent indent={'33%'} label={"value:"}>
                <StyledSettingsInput
                    value={value_ ?? controller.value}
                    onChange={handleValueChange}
                    background={style.components.Input.background}
                    textColor={initial && (initial.value !== controller.value) ? style.palette.highlight.main : style.components.Input.color}
                    onBlur={handleValueCommit}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            handleValueCommit()
                        }
                    }}
                />
            </LabeledComponent>
        </div>
        <div style={{position: 'relative', width: 200, minWidth: '45%', height: 28, flexGrow: 1}}>
            <LabeledComponent indent={'33%'} label={"step:"}>
                <StyledSettingsInput
                    value={step_ ?? controller.step}
                    onChange={handleStepChange}
                    background={style.components.Input.background}
                    textColor={initial && (initial.step !== controller.step) ? style.palette.highlight.main : style.components.Input.color}
                    onBlur={handleStepCommit}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            handleStepCommit()
                        }
                    }}
                />
            </LabeledComponent>
        </div>
        <div style={{position: 'relative', width: 200, minWidth: '45%', height: 28, flexGrow: 1}}>
            <LabeledComponent indent={'33%'} label={"min:"}>
                <StyledSettingsInput
                    value={min_ ?? controller.min}
                    onChange={handleMinChange}
                    background={style.components.Input.background}
                    textColor={initial && (initial.min !== controller.min) ? style.palette.highlight.main : style.components.Input.color}
                    onBlur={handleMinCommit}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            handleMinCommit()
                        }
                    }}
                />
            </LabeledComponent>
        </div>
        <div style={{position: 'relative', width: 200, minWidth: '45%', height: 28, flexGrow: 1}}>
            <LabeledComponent indent={'33%'} label={"max:"}>
                <StyledSettingsInput
                    value={max_ ?? controller.max}
                    onChange={handleMaxChange}
                    background={style.components.Input.background}
                    textColor={initial && (initial.max !== controller.max) ? style.palette.highlight.main : style.components.Input.color}
                    onBlur={handleMaxCommit}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            handleMaxCommit()
                        }
                    }}
                />
            </LabeledComponent>
        </div>
    </div>
        </>
}

export default FancySliderSettings;