import {
    AddControllerAction,
    AddNodeAction,
    ComposeAction,
    ConnectAction,
    DisconnectAction,
    Edge,
    ExecutableNode,
    HoverNodeAction,
    HoverPortAction,
    Node,
    NodeActions,
    NodesActionType,
    NodesState,
    NodesUpdate,
    NodeUpdate,
    NodeUpdateOptions,
    PortPosition,
    Position,
    RemoveControllerAction,
    RemoveNodeAction,
    SetConnectionLineAction,
    SetMachineStateAction,
    SetModeAction,
    StackDirection,
    StateNode,
    UnhoverNodeAction,
    UnhoverPortAction,
    Unique,
    UpdateConnectionLineAction,
    UpdateControllerAction,
    UpdateNodeAction,
    UpdateNodesAction,
    UpdateStateAction, Warnings
} from "./types";
import {default as deepMerge} from "deepmerge-json";
import {
    Data,
    DefinitionState,
    iterateNodes,
    iterateNodesCustom,
    NodeData,
    StateNodeData
} from "../../common/machine/Machine";
import MachineHandler from "../../common/machine/MachineHandler";
import {scaled} from "../../components/nodes";

const initialState: NodesState = {
    nodes: {},
    nodeInfo: {},
    edges: {},
    controllers: {},
    forwardAdjacency: {},
    backwardAdjacency: {},
    mode: 'default',
    machineState: {}
};

export const getEdgeId = (edge: Edge): string => {
    return edge.from.id + "[" + edge.from.index + "]-" + edge.to.id + "[" + edge.to.index + "]"
}

/*
const mergeUpdateIntoNode = (node: Node, update: NodeUpdate, idMustBeCorrect: boolean = false): Node => {
    if (idMustBeCorrect && node.id !== update.id) return node;

    if (update.shape) {
        if (update.shape.x) node.shape.x = update.shape.x;
        if (update.shape.y) node.shape.y = update.shape.y;
        if ("width" in node.shape && update.shape.width)
            node.shape.width = update.shape.width;
        if ("height" in node.shape && update.shape.height)
            node.shape.height = update.shape.height;
        if ("radius" in node.shape && update.shape.radius)
            node.shape.radius = update.shape.radius;
    }

    if ("next" in node && update.next !== undefined) {
        if (update.next === null) delete node.next;
        else node.next = update.next;
    }
    if ("prev" in node && update.prev !== undefined) {
        if (update.prev === null) delete node.prev;
        else node.prev = update.prev;
    }
    if ("__next" in node && update.next !== undefined) {
        if (update.next === null) delete node.next;
        else node.next = update.next;
    }
    if ("__prev" in node && update.prev !== undefined) {
        if (update.prev === null) delete node.prev;
        else node.prev = update.prev;
    }

    return {...node};
}
*/

const getInputPos = (node: ExecutableNode, index: number) => ({x: node.shape.x, y: node.shape.y + 12 * (index + 1)})
const getOutputPos = (node: ExecutableNode, index: number) => ({x: node.shape.x + node.shape.width, y: node.shape.y + 12 * (index + 1)})

export const hasRectangleShape = (node: Node) => {
    return "width" in node.shape && "height" in node.shape;
}

export const hasCircleShape = (node: Node) => {
    return "radius" in node.shape;
}

export const isExecutableNode = (node: Node) => hasRectangleShape(node);
export const isStateNode = (node: Node) => hasCircleShape(node);

//const machine: DelegateMachine = new DelegateMachine();
//export const setMachine = (m: Machine) => machine.set(m);
//export const getMachine = (): Machine => machine;

export const toNodeData = (node: Node): NodeData => {
    if (isExecutableNode(node)) {
        let exn = node as ExecutableNode;
        return {
            input: exn.input ? exn.input.map(i => i.data || null) : [],
            output: exn.output ? exn.output.map(i => i.data || null) : [],
            type: exn.type
        }

    } else {
        let stn = node as StateNode;
        return stn.data || null
    }
}

export const nodes = (
    state: NodesState = initialState,
    action: NodeActions
): NodesState => {
    const fwA = state.forwardAdjacency;
    const bwA = state.backwardAdjacency;

    const connector = MachineHandler.current?.connector;

    const removeEdge = (edge: Edge) => {
        fwA[edge.from.id][edge.from.index ?? 0][edge.to.id].splice(
            fwA[edge.from.id][edge.from.index ?? 0][edge.to.id].indexOf(edge.to.index ?? 0), 1
        );
        fwA[edge.from.id][edge.from.index ?? 0][edge.to.id] = [...fwA[edge.from.id][edge.from.index ?? 0][edge.to.id]];

        if (fwA[edge.from.id][edge.from.index ?? 0][edge.to.id].length === 0)
            delete fwA[edge.from.id][edge.from.index ?? 0][edge.to.id];

        if (Object.values(fwA[edge.from.id][edge.from.index ?? 0]).length === 0)
            delete fwA[edge.from.id][edge.from.index ?? 0];

        if (Object.values(fwA[edge.from.id]).length === 0)
            delete fwA[edge.from.id];

        bwA[edge.to.id][edge.to.index ?? 0][edge.from.id].splice(
            bwA[edge.to.id][edge.to.index ?? 0][edge.from.id].indexOf(edge.from.index ?? 0), 1
        );
        bwA[edge.to.id][edge.to.index ?? 0][edge.from.id] = [...bwA[edge.to.id][edge.to.index ?? 0][edge.from.id]];

        if (bwA[edge.to.id][edge.to.index ?? 0][edge.from.id].length === 0)
            delete bwA[edge.to.id][edge.to.index ?? 0][edge.from.id];

        if (Object.values(bwA[edge.to.id][edge.to.index ?? 0]).length === 0)
            delete bwA[edge.to.id][edge.to.index ?? 0];

        if (Object.values(bwA[edge.to.id]).length === 0)
            delete bwA[edge.to.id];

        delete state.edges[getEdgeId(edge)];
    }

    const updateEdge = (e: Edge) => {
        let edge = state.edges[getEdgeId(e)];
        if (edge) {
            if (!edge.update) {
                edge.update = 0;
            } else {
                edge.update++;
            }
        }
    }

    const moveStackNodes = (state: NodesState, id: string, direction: StackDirection, delta: Partial<Position>): NodesState => {
        const fwA = state.forwardAdjacency;
        const bwA = state.backwardAdjacency;

        const unFwCons = fwA[id];
        const unBwCons = bwA[id];

        if (unFwCons) {
            Object.keys(unFwCons).forEach(i => {
                Object.keys(unFwCons[+i]).forEach(node => {
                    Object.keys(unFwCons[+i][node]).forEach(j => {
                        updateEdge({
                            from: {
                                id: id,
                                index: +i
                            },
                            to: {
                                id: node,
                                index: +j
                            }
                        })
                    })
                })
            })
        }

        if (unBwCons) {
            Object.keys(unBwCons).forEach(i => {
                Object.keys(unBwCons[+i]).forEach(node => {
                    Object.keys(unBwCons[+i][node]).forEach(j => {
                        updateEdge({
                            from: {
                                id: node,
                                index: +j
                            },
                            to: {
                                id: id,
                                index: +i
                            }
                        })
                    })
                })
            })
        }

        let node = state.nodes[id];

        let prev = (node as ExecutableNode).prev;
        let next = (node as ExecutableNode).next;

        let newShape: Partial<Position> = {};
        if (delta.x) newShape.x = node.shape.x + delta.x;
        if (delta.y) newShape.y = node.shape.y + delta.y;

        let result = {...state, nodes: {...state.nodes, [id]: deepMerge(node, {shape: newShape})}};
        if (direction === 'forward' && next) {
            return moveStackNodes(result, next, 'forward', delta);
        } else if (direction === 'backward' && prev) {
            return moveStackNodes(result, prev, 'backward', delta);
        } else {
            return result;
        }
    }

    const updateChildNode = (state: NodesState, id: string, delta: Partial<Position>): NodesState => {
        const fwA = state.forwardAdjacency;
        const bwA = state.backwardAdjacency;

        const unFwCons = fwA[id];
        const unBwCons = bwA[id];

        if (unFwCons) {
            Object.keys(unFwCons).forEach(i => {
                Object.keys(unFwCons[+i]).forEach(node => {
                    Object.keys(unFwCons[+i][node]).forEach(j => {
                        updateEdge({
                            from: {
                                id: id,
                                index: +i
                            },
                            to: {
                                id: node,
                                index: +j
                            }
                        })
                    })
                })
            })
        }

        if (unBwCons) {
            Object.keys(unBwCons).forEach(i => {
                Object.keys(unBwCons[+i]).forEach(node => {
                    Object.keys(unBwCons[+i][node]).forEach(j => {
                        updateEdge({
                            from: {
                                id: node,
                                index: +j
                            },
                            to: {
                                id: id,
                                index: +i
                            }
                        })
                    })
                })
            })
        }

        let node = state.nodes[id];
        let newPos: Partial<Position> = {};
        if (delta.x) newPos.x = node.shape.x + delta.x;
        if (delta.y) newPos.y = node.shape.y + delta.y;

        let result = {...state, nodes: {...state.nodes, [id]: deepMerge(node, {shape: newPos})}};
        if (node.children) {
            node.children.forEach(child => result = updateChildNode(state, child, delta))
        }
        return result
    }

    switch (action.type) {
        case NodesActionType.SETMACHINESTATE:
            const smsap = (action as SetMachineStateAction).payload;
            let machineState = {...state.machineState};

            if (smsap.initialized === false) {
                delete machineState.initialized;
            } else if (smsap.initialized === true) {
                machineState.initialized = true;
            }

            if (smsap.running === false) {
                delete machineState.running;
            } else if (smsap.running === true) {
                machineState.running = true;
            }

            return {...state, machineState}

        case NodesActionType.ADDCONTROLLER:
            const acap = (action as AddControllerAction).payload;
            return {...state, controllers: {...state.controllers, [acap.id]: acap.state}};

        case NodesActionType.UPDATECONTROLLER:
            const ucap = (action as UpdateControllerAction).payload;
            return {...state, controllers: {...state.controllers, [ucap.id]: deepMerge(state.controllers[ucap.id], ucap.state)}};

        case NodesActionType.REMOVECONTROLLER:
            const rcap = (action as RemoveControllerAction).payload;
            delete state.controllers[rcap.id];
            return state;

        case NodesActionType.ADDNODE:
            const anap = (action as AddNodeAction).payload as Node;
            connector.add(anap.id, toNodeData(anap));
            return {...state, nodes: {...state.nodes, [anap.id]: anap}};

        case NodesActionType.REMOVENODE:
            const rnap = (action as RemoveNodeAction).payload as Unique;
            const rnFwCons = fwA[rnap.id];
            const rnBwCons = bwA[rnap.id];

            if (rnFwCons) {
                Object.keys(rnFwCons).forEach(i => {
                    Object.keys(rnFwCons[+i]).forEach(node => {
                        Object.keys(rnFwCons[+i][node]).forEach(j => {
                            removeEdge({
                                from: {
                                    id: rnap.id,
                                    index: +i
                                },
                                to: {
                                    id: node,
                                    index: +j
                                }
                            })
                        })
                    })
                })
            }

            if (rnBwCons) {
                Object.keys(rnBwCons).forEach(i => {
                    Object.keys(rnBwCons[+i]).forEach(node => {
                        Object.keys(rnBwCons[+i][node]).forEach(j => {
                            removeEdge({
                                from: {
                                    id: node,
                                    index: +j
                                },
                                to: {
                                    id: rnap.id,
                                    index: +i
                                }
                            })
                        })
                    })
                })
            }

            delete state.nodes[rnap.id];
            return state;

        case NodesActionType.UPDATENODE:
            const unap = (action as UpdateNodeAction).payload as { update: NodeUpdate, options: NodeUpdateOptions };
            const unapu = unap.update;
            const unapo = unap.options ?? {};

            if (unapu) {
                if (unapu.next) {
                    connector?.stack(unapu.id, {next: unapu.next});
                } else if (unapu.next === null) {
                    let node = state.nodes[unapu.id];
                    if ("next" in node && node.next) {
                        connector?.unstack(unapu.id, {next: node.next});
                    }
                }
                if (unapu.prev) {
                    connector?.stack(unapu.prev, {next: unapu.id});
                } else if (unapu.prev === null) {
                    let node = state.nodes[unapu.id];
                    if ("prev" in node && node.prev) {
                        connector?.unstack(node.prev, {next: unapu.id});
                    }
                }
            }

            const unFwCons = fwA[unapu.id];
            const unBwCons = bwA[unapu.id];

            if (unFwCons && unapu.shape) {
                Object.keys(unFwCons).forEach(i => {
                    Object.keys(unFwCons[+i]).forEach(node => {
                        Object.keys(unFwCons[+i][node]).forEach(j => {
                            updateEdge({
                                from: {
                                    id: unapu.id,
                                    index: +i
                                },
                                to: {
                                    id: node,
                                    index: +j
                                }
                            })
                        })
                    })
                })
            }

            if (unBwCons && unapu.shape) {
                Object.keys(unBwCons).forEach(i => {
                    Object.keys(unBwCons[+i]).forEach(node => {
                        Object.keys(unBwCons[+i][node]).forEach(j => {
                            updateEdge({
                                from: {
                                    id: node,
                                    index: +j
                                },
                                to: {
                                    id: unapu.id,
                                    index: +i
                                }
                            })
                        })
                    })
                })
            }

            let result = {...state, nodes: {...state.nodes, [unapu.id]: deepMerge(state.nodes[unapu.id], unapu)}};

            if (unapo && unapo.updateStack) {
                let node = state.nodes[unapu.id] as ExecutableNode;
                let prev = node.prev;
                let next = node.next;

                if (next && (unapo.updateStack === true || unapo.updateStack === 'forward')) {
                    let delta: Partial<Position> = {};
                    if (unapu.shape?.x) delta.x = unapu.shape.x - node.shape.x;
                    if (unapu.shape?.y || unapu.shape?.height)
                        delta.y = (unapu.shape?.y ? unapu.shape?.y - node.shape.y : 0)
                            + (unapu.shape?.height ? unapu.shape?.height - node.shape.height : 0);

                    if (delta.y && delta.y !== 0) result = moveStackNodes(result, next, 'forward', delta);
                }
                if (prev && (unapo.updateStack === true || unapo.updateStack === 'backward')) {
                    result = moveStackNodes(result, prev, 'backward', {x: unapu.shape?.x, y: unapu.shape?.y});
                }
            }

            if (unapo && unapo.moveStack) {
                let node = state.nodes[unapu.id] as ExecutableNode;
                let prev = node.prev;
                let next = node.next;

                let delta = {
                    x: unapu.shape?.x && (unapu.shape?.x - node.shape.x),
                    y: unapu.shape?.y && (unapu.shape?.y - node.shape.y)
                }

                if (next) {
                    result = moveStackNodes(result, next, 'forward', delta);
                }
                if (prev) {
                    result = moveStackNodes(result, prev, 'backward', delta);
                }
            }

            if ((unapu.shape?.x || unapu.shape?.y) && unapo.updateChildren) {
                let node = state.nodes[unapu.id] as ExecutableNode;
                if (node.children) {
                    let delta: Partial<Position> = {};
                    if (unapu.shape?.x) delta.x = unapu.shape.x - node.shape.x;
                    if (unapu.shape?.y) delta.y = unapu.shape.y - node.shape.y;
                    node.children.forEach(k => {
                        result = updateChildNode(result, k, delta)
                    });
                }
            }

            return result;

        case NodesActionType.UPDATENODES:
            const unsap = (action as UpdateNodesAction).payload as NodesUpdate;
            unsap.delete.forEach(key => delete state.nodes[key])
            return {...state, nodes: {...state.nodes, ...unsap.add, ...unsap.change}};

        case NodesActionType.HOVERNODE:
            const hnap = (action as HoverNodeAction).payload as Node;
            if (state.mode === 'connect' && isStateNode(state.nodes[hnap.id])) {
                let stn = state.nodes[hnap.id] as StateNode;
                let to = stn.shape as Position;

                return {
                    ...state,
                    connectionLine: deepMerge(state.connectionLine, {
                        target: {
                            id: hnap.id,
                            index: undefined
                        },
                        to: scaled(to)
                    })
                };
            } else if (state.mode !== 'default') return state;

            return {...state, hovered: hnap ? hnap.id : undefined, hoveredPort: undefined};

        case NodesActionType.UNHOVERNODE:
            const uhnap = (action as UnhoverNodeAction).payload as Node;
            if (state.mode === 'connect'
                && state.connectionLine?.target?.id === uhnap.id
            ) {
                return {
                    ...state,
                    connectionLine: deepMerge(state.connectionLine, {
                        target: null,
                        to: null
                    })
                };
            } else if (state.mode !== 'default') return state;

            return {
                ...state,
                hovered: state.hovered ? (uhnap.id === state.hovered ? undefined : state.hovered) : undefined,
                hoveredPort: state.hovered ? (uhnap.id === state.hovered ? undefined : state.hoveredPort) : undefined
            };

        case NodesActionType.HOVERPORT:
            const hpap = (action as HoverPortAction).payload as Unique & PortPosition;
            if (state.mode === 'connect') {
                let exn = state.nodes[hpap.id] as ExecutableNode;
                let dir = hpap.direction;
                let to = dir === 'in' ? getInputPos(exn, hpap.index) : getOutputPos(exn, hpap.index);

                let newDirection = {}
                if (state.connectionLine?.direction === 'forward' && dir === 'out')
                    newDirection = {direction: 'out-out'}
                else if (state.connectionLine?.direction === 'backward' && dir === 'in')
                    newDirection = {direction: 'in-in'}

                return {
                    ...state,
                    connectionLine: deepMerge(state.connectionLine, {
                        target: {
                            id: hpap.id,
                            index: hpap.index
                        },
                        to: scaled(to),
                        ...newDirection
                    })
                };
            } else if (state.mode !== 'default') return state;

            return {
                ...state,
                hovered: hpap ? hpap.id : undefined,
                hoveredPort: hpap ? {direction: hpap.direction, index: hpap.index} : undefined
            };

        case NodesActionType.UNHOVERPORT:
            const uhpap = (action as UnhoverPortAction).payload as Unique & PortPosition;
            if (state.mode === 'connect'
                && state.connectionLine?.target?.id === uhpap.id
                && state.connectionLine?.target?.index === uhpap.index
            ) {
                let newDirection = {}
                if (state.connectionLine?.direction === 'out-out')
                    newDirection = {direction: 'forward'}
                else if (state.connectionLine?.direction === 'in-in')
                    newDirection = {direction: 'backward'}

                return {
                    ...state,
                    connectionLine: deepMerge(state.connectionLine, {
                        target: null,
                        to: null,
                        ...newDirection
                    })
                };
            } else if (state.mode !== 'default') return state;

            return {
                ...state,
                hovered: state.hovered ? (uhpap.id === state.hovered ? undefined : state.hovered) : undefined,
                hoveredPort: state.hovered ? (uhpap.id === state.hovered ? undefined : state.hoveredPort) : undefined
            };

        case NodesActionType.CONNECT:
            const cap = (action as ConnectAction).payload as Edge;

            if (cap.to.id === cap.from.id) return state;

            let edgeId = getEdgeId(cap)
            if (state.edges[edgeId]) {
                removeEdge(cap);
                return {...state, edges: {...state.edges}};
            }

            connector?.connect(cap.from.id, {
                from: cap.from.index,
                to: cap.to.index ? [cap.to.id, +cap.to.index] : cap.to.id
            });

            if (!fwA[cap.from.id]) fwA[cap.from.id] = {};
            if (!fwA[cap.from.id][cap.from.index ?? 0]) fwA[cap.from.id][cap.from.index ?? 0] = {};
            if (!fwA[cap.from.id][cap.from.index ?? 0][cap.to.id]) fwA[cap.from.id][cap.from.index ?? 0][cap.to.id] = [];
            if (!fwA[cap.from.id][cap.from.index ?? 0][cap.to.id].includes(cap.to.index ?? 0))
                fwA[cap.from.id][cap.from.index ?? 0][cap.to.id].push(cap.to.index ?? 0);

            if (!bwA[cap.to.id]) bwA[cap.to.id] = {};
            if (!bwA[cap.to.id][cap.to.index ?? 0]) bwA[cap.to.id][cap.to.index ?? 0] = {};
            if (!bwA[cap.to.id][cap.to.index ?? 0][cap.from.id]) bwA[cap.to.id][cap.to.index ?? 0][cap.from.id] = [];
            if (!bwA[cap.to.id][cap.to.index ?? 0][cap.from.id].includes(cap.from.index ?? 0))
                bwA[cap.to.id][cap.to.index ?? 0][cap.from.id].push(cap.from.index ?? 0);

            return {
                ...state,
                edges: {
                    ...state.edges,
                    [edgeId]: cap
                }
            };

        case NodesActionType.COMPOSE:
            const coap = (action as ComposeAction).payload;

            if (state.nodes[coap.child].parent === coap.parent) return state;
            else return {
                ...state,
                nodes: {
                    ...state.nodes,
                    [coap.parent]: {
                        ...state.nodes[coap.parent],
                        children: [...state.nodes[coap.parent].children!, coap.child]
                    },
                    [coap.child]: {
                        ...state.nodes[coap.child],
                        parent: coap.parent
                    }
                }
            }

        case NodesActionType.DISCONNECT:
            const dap = (action as DisconnectAction).payload as Edge;

            removeEdge(dap);

            return {...state};

        case NodesActionType.SETMODE:
            const smap = (action as SetModeAction).payload;

            return {...state, mode: smap};

        case NodesActionType.SETCONNECTIONLINE:
            const sclap = (action as SetConnectionLineAction).payload;
            return {...state, connectionLine: sclap, mode: sclap ? 'connect' : 'default'};

        case NodesActionType.UPDATECONNECTIONLINE:
            const uclap = (action as UpdateConnectionLineAction).payload;
            return {...state, connectionLine: deepMerge(state.connectionLine, uclap)};

        case NodesActionType.MOVECONNECTIONLINETONODESEARCH:
            if (!state.connectionLine) {
                return {...state, connectionLine: undefined, mode: 'default'};
            }
            let {from, start, direction} = state.connectionLine;
            if (!state.nodeSearchConnections) {
                state.nodeSearchConnections = []
            }

            return {
                ...state,
                connectionLine: undefined,
                nodeSearchConnections: [...state.nodeSearchConnections, {from, start, direction}],
                mode: 'default'
            };

        case NodesActionType.CLEARNODESEARCHCONNECTIONS:
            delete state.nodeSearchConnections;
            return {...state}

        case NodesActionType.UPDATESTATE:
            const usap = (action as UpdateStateAction).payload;
            let nodes = {...state.nodes};
            let nodeInfo = {...state.nodeInfo};

            if (usap.nodes) {
                iterateNodesCustom(usap.nodes,
                    (exn, key) => {
                        let node = nodes[key] as ExecutableNode;

                        exn.input.forEach((d, i) => {
                            if (node.input) {
                                if (!d?.data) {
                                    node.input[i].data = null;
                                } else {
                                    node.input[i].data = d.data;
                                }
                                node.input[i].warnings = d.warnings;
                            }
                        });

                        exn.output.forEach((d, i) => {
                            if (node.output) {
                                if (!d?.data) {
                                    node.output[i].data = null;
                                } else {
                                    node.output[i].data = d.data;
                                }
                                node.output[i].warnings = d.warnings;
                            }
                        });

                        if (!exn.state) {
                            delete node.state;
                        } else {
                            node.state = exn.state;
                        }
                    },
                    (stn, key) => {
                        (nodes[key] as StateNode).data = stn?.data ?? undefined
                    },
                    function(node): node is StateNodeData<{data?: Data, warnings?: Warnings}, {info?: any}> {
                        return node === null || "data" in node
                    }
                );

                for (let key in usap.nodes) {
                    let node = usap.nodes[key];
                    if (node?.info) {
                        nodeInfo[key] = node.info;
                    }
                }
            }

            return {...state, nodes, nodeInfo};

        default:
            return state;
    }
};