import {ExecutableNode, Node, PortPosition, StateNode, Unique} from "../../store/nodes";
import {useKeyPressed, useTypedSelector} from "../../store";
import React, {useContext} from "react";
import {useNodeStyle, useStack, useStyle} from "../../hooks";
import {useZoom} from "../workspace/ZoomTranslate";
import {NodeStyleContext} from "../style/FancyNodeStyleProvider";
import {_scale} from "./index";
import {FancyColor} from "../style";
import FancyExecutableNode from "./FancyExecutableNode";
import FancyStateNode from "./FancyStateNode";
import FancyPortLabel from "./FancyPortLabel";
import {motion} from "framer-motion";
import {getNodeTypeFromPath} from "../../common/machine/MachineHandler";
import {objectMap} from "../../common/utils";

const FancyNodeWarningHighlights = ({filter}: { filter?: (n: Node) => boolean }) => {
    let nodes = useTypedSelector(state => {
        if (!filter) return state.nodes.nodes;
        else return Object.fromEntries<Node>(
            Object.entries(state.nodes.nodes).filter(
                ([, n]) => filter(n)
            )
        )
    });

    return <>
        {Object.keys(nodes).map(key => {
            let node = nodes[key];
            if ('width' in node.shape) {
                let exn = node as ExecutableNode
                return <div
                    style={{
                        position: 'absolute',
                        left: node.shape.x * _scale,
                        top: node.shape.y * _scale,
                        width: node.shape.width * _scale,
                        height: node.shape.height * _scale,
                        pointerEvents: 'none'
                    }}
                >
                    {exn.input?.map((port, i) => <AutoColorPortHighlight
                        direction={'in'}
                        index={i}
                        id={node.id}
                        key={node.id + "in" + i + "warning"}
                    />)}
                    {exn.output?.map((port, i) => <AutoColorPortHighlight
                        direction={'out'}
                        index={i}
                        id={node.id}
                        key={node.id + "out" + i + "warning"}
                    />)}
                </div>
            } else {
                return null; //TODO
            }
        })}
    </>
}

const FancyNodeHoverHighlights = ({filter}: { filter?: (n: Node) => boolean }) => {
    let hovered = useTypedSelector(state => state.nodes.hovered);
    let hoveredPort = useTypedSelector(state => state.nodes.hoveredPort);
    let node = useTypedSelector(state => hovered ? state.nodes.nodes[hovered] : undefined);

    let fwA = useTypedSelector(state => {
        if (!hovered || (hoveredPort && hoveredPort.direction === 'in')) return undefined;
        if (!hoveredPort) return state.nodes.forwardAdjacency[hovered]
        return state.nodes.forwardAdjacency[hovered] && {[hoveredPort.index]: state.nodes.forwardAdjacency[hovered][hoveredPort.index]}
    });

    let bwA = useTypedSelector(state => {
        if (!hovered || (hoveredPort && hoveredPort.direction === 'out')) return undefined;
        if (!hoveredPort) return state.nodes.backwardAdjacency[hovered]
        return state.nodes.backwardAdjacency[hovered] && {[hoveredPort.index]: state.nodes.backwardAdjacency[hovered][hoveredPort.index]}
    });

    if (node && hovered && (!filter || filter(node))) {
        return <>
            {'width' in node.shape ?
                <FancyExecutableNodeHighlight id={hovered} port={hoveredPort}/>
                :
                <FancyStateNodeHighlight id={hovered}/>
            }
            {fwA && <>
                {Object.keys(fwA).map(key => {
                    if (!fwA) return null;
                    let port = fwA[+key];
                    let highlights: JSX.Element[] = [];
                    if (port) Object.keys(port).forEach(toNode => port[toNode].forEach(i => {
                        highlights.push(<FancyExecutableNodeHighlight
                            passive
                            id={toNode}
                            port={{direction: 'in', index: +i}}
                        />)
                    }));
                    return highlights;
                })}
            </>}
            {bwA && <>
                {Object.keys(bwA).map(key => {
                    if (!bwA) return null;
                    let port = bwA[+key];
                    let highlights: JSX.Element[] = [];
                    if (port) Object.keys(port).forEach(fromNode => port[fromNode].forEach(i => {
                        highlights.push(<FancyExecutableNodeHighlight
                            passive
                            id={fromNode}
                            port={{direction: 'out', index: +i}}
                        />)
                    }));
                    return highlights;
                })}
            </>}
        </>
    } else return null;
}

const FancyNodeHighlights = ({filter}: { filter?: (n: Node) => boolean }) => {
    return <>
        <FancyNodeWarningHighlights filter={filter}/>
        <FancyNodeHoverHighlights filter={filter}/>
    </>
}

export default FancyNodeHighlights;

export interface HighlightColor {
    color?: string,
    autoColor?: boolean
}

export const useWarningState = (id: string, direction: 'in' | 'out', index: number) => useTypedSelector(state => {
    let ports = direction === 'in' ? (state.nodes.nodes[id] as ExecutableNode).input : (state.nodes.nodes[id] as ExecutableNode).output;
    if (!ports) return undefined;
    let port = ports[index];
    if (!port || !port.warnings) return undefined;
    let levels = Object.keys(port.warnings).map(k => +k);
    return Math.max(...levels);
});

export const useWarningColor = (id: string, direction: 'in' | 'out', index: number): FancyColor => {
    const style = useStyle();
    const warningState = useWarningState(id, direction, index);
    const highlightColorBaseGhost = style.palette.highlight;
    return warningState === undefined ? highlightColorBaseGhost : style.palette.warning[warningState];
}

export const useWarningColorMainOr = (id: string, direction: 'in' | 'out', index: number, or: string): string => {
    const style = useStyle();
    const warningState = useWarningState(id, direction, index);
    return warningState === undefined ? or : style.palette.warning[warningState].main;
}

export const FancyPortHighlight = ({
                                       id,
                                       direction,
                                       index,
                                       aura,
                                       color,
                                       autoColor
                                   }: PortPosition & Unique & HighlightColor & { aura: number }) => {
    let style = useStyle();

    if (autoColor) {
        let props = {direction, index, id, color, aura}
        return <AutoColorPortHighlight {...props}/>
    }

    const portSize = 6 * _scale;
    const portDist = 12 * _scale;

    let highlightColorBaseGhost = style.palette.highlight.ghost;
    let background = color ?? highlightColorBaseGhost;

    const inputPosition = {
        left: -portSize / 2 - aura,
        top: (index + 1) * portDist - portSize / 2 - aura
    }

    const outputPosition = {
        right: -portSize / 2 - aura,
        top: (index + 1) * portDist - portSize / 2 - aura
    }

    const position = direction === 'in' ? inputPosition : outputPosition

    return <div
        style={{
            ...position,
            position: 'absolute',
            width: portSize + aura * 2,
            height: portSize + aura * 2,
            background,
            borderRadius: portSize / 2 + aura
        }}
    />
}

export const AutoColorPortHighlight = ({
                                           id,
                                           direction,
                                           index,
                                           animate
                                       }: PortPosition & Unique & HighlightColor & { animate?: boolean }) => {
    const style = useContext(NodeStyleContext);
    let isHovered = useTypedSelector(state => {
        if (!state.nodes.hovered || state.nodes.hovered !== id) return false;
        if (!state.nodes.hoveredPort) return false;
        return !(state.nodes.hoveredPort.index !== index || state.nodes.hoveredPort.direction !== direction);

    });

    const portSize = style.components.Port.size;
    const portDist = style.components.Port.spacing;
    const zoom = useZoom();

    const warningState = useWarningState(id, direction, index);
    const highlightColorBaseGhost = style.palette.highlight.ghost;
    const background = warningState === undefined ? highlightColorBaseGhost : style.palette.warning[warningState].light;

    if (!isHovered && warningState === undefined) return null;

    let scale = isHovered ? portSize + getHighlightAura(8, _scale, zoom) * 2 : portSize + getHighlightAura(3, _scale, zoom) * 2;

    const inputPosition = {
        left: 0,
        top: (index + 1) * portDist
    }

    const outputPosition = {
        right: 0,
        top: (index + 1) * portDist
    }

    const position = direction === 'in' ? inputPosition : outputPosition

    return <motion.div
        style={{
            ...position,
            position: 'absolute',
            width: 0,
            height: 0,
            background,
            borderRadius: '50%'
        }}
        animate={{scale: scale / 10, transition: {duration: animate ? undefined : 0}}}
    >
        <div style={{
            position: 'absolute',
            width: 10,
            height: 10,
            left: -5,
            top: -5,
            background,
            borderRadius: '50%'
        }}/>
    </motion.div>

    /*return <div
        style={{
            ...position,
            position: 'absolute',
            width: portSize + aura * 2,
            height: portSize + aura * 2,
            background,
            borderRadius: portSize / 2 + aura
        }}
    />*/
}

export const WarningColorPortHighlight = ({
                                              id,
                                              direction,
                                              index,
                                              aura
                                          }: PortPosition & Unique & HighlightColor & { aura: number }) => {
    const style = useStyle();
    const warningState = useWarningState(id, direction, index);
    if (warningState === undefined) return null;
    const portSize = 6 * _scale;
    const portDist = 12 * _scale;

    const background = style.palette.warning[warningState].ghost;

    const inputPosition = {
        left: -portSize / 2 - aura,
        top: (index + 1) * portDist - portSize / 2 - aura
    }

    const outputPosition = {
        right: -portSize / 2 - aura,
        top: (index + 1) * portDist - portSize / 2 - aura
    }

    const position = direction === 'in' ? inputPosition : outputPosition

    return <div
        style={{
            ...position,
            position: 'absolute',
            width: portSize + aura * 2,
            height: portSize + aura * 2,
            background,
            borderRadius: portSize / 2 + aura
        }}
    />
}

const _glowRadius = 11, _glowSpread = 2;

export const FancyPortGlow = ({
                                  id,
                                  direction,
                                  index,
                                  color,
                                  autoColor,
                                  skipOnWarning
                              }: PortPosition & Unique & HighlightColor & { skipOnWarning?: boolean }) => {
    let style = useStyle();

    if (autoColor) {
        let props = {direction, index, id, color, skipOnWarning}
        return <AutoColorPortGlow {...props}/>
    }

    const portSize = 6 * _scale - 2 * _scale;
    const portDist = 12 * _scale;
    let highlightColorBaseGhost = style.palette.highlight.main;
    let glow = color ?? highlightColorBaseGhost;

    const inputPosition = {
        left: -portSize / 2,
        top: (index + 1) * portDist - portSize / 2
    }

    const outputPosition = {
        right: -portSize / 2,
        top: (index + 1) * portDist - portSize / 2
    }

    const position = direction === 'in' ? inputPosition : outputPosition

    return <div
        style={{
            ...position,
            position: 'absolute',
            width: portSize,
            height: portSize,
            borderRadius: portSize / 2,
            boxShadow: '0px 0px ' + _glowRadius * _scale + 'px ' + _glowSpread * _scale + 'px ' + glow
        }}
    />
}

export const AutoColorPortGlow = ({
                                      id,
                                      direction,
                                      index,
                                      skipOnWarning
                                  }: PortPosition & Unique & HighlightColor & { skipOnWarning?: boolean }) => {
    const portSize = 6 * _scale - 2 * _scale;
    const portDist = 12 * _scale;

    const style = useStyle();
    const warningState = useWarningState(id, direction, index);
    const hasWarning = warningState !== undefined;
    if (skipOnWarning && hasWarning) return null;

    const highlightColorBaseGhost = style.palette.highlight;
    const glow = (!hasWarning ? highlightColorBaseGhost : style.palette.warning[warningState]).main;

    const inputPosition = {
        left: -portSize / 2,
        top: (index + 1) * portDist - portSize / 2
    }

    const outputPosition = {
        right: -portSize / 2,
        top: (index + 1) * portDist - portSize / 2
    }

    const position = direction === 'in' ? inputPosition : outputPosition

    return <div
        style={{
            ...position,
            position: 'absolute',
            width: portSize,
            height: portSize,
            borderRadius: portSize / 2,
            boxShadow: '0px 0px ' + _glowRadius * _scale + 'px ' + _glowSpread * _scale + 'px ' + glow
        }}
    />
}

export const WarningColorPortGlow = ({id, direction, index}: PortPosition & Unique & HighlightColor) => {
    const style = useStyle();
    const warningState = useWarningState(id, direction, index);
    if (warningState === undefined) return null;

    const glow = style.palette.warning[warningState].main;

    const portSize = 6 * _scale - 2 * _scale;
    const portDist = 12 * _scale;

    const inputPosition = {
        left: -portSize / 2,
        top: (index + 1) * portDist - portSize / 2
    }

    const outputPosition = {
        right: -portSize / 2,
        top: (index + 1) * portDist - portSize / 2
    }

    const position = direction === 'in' ? inputPosition : outputPosition

    return <div
        style={{
            ...position,
            position: 'absolute',
            width: portSize,
            height: portSize,
            borderRadius: portSize / 2,
            boxShadow: '0px 0px ' + _glowRadius * _scale + 'px ' + _glowSpread * _scale + 'px ' + glow
        }}
    />
}

export const getHighlightAura = (value: number, scale: number, zoom: number,) => value * scale * (0.8 + 0.2 / zoom);
export const FancyStackNodesHighlight = ({id, aura, border, radius, color, opacity}: {
    id: string,
    aura: number,
    border: number,
    radius: number,
    color?: string,
    opacity?: number
}) => {
    let style = useStyle();
    let stack = useStack(id);
    color = color ?? style.palette.highlight.main;

    return <div style={{opacity: opacity || style.palette.highlight.ghostAlpha}}>
        {stack.map((node, i) => {
            const prev = i > 0 ? stack[i - 1] : undefined;
            const next = i < stack.length - 1 ? stack[i + 1] : undefined;
            const rad = radius + aura + border / 2;

            const right = (node: ExecutableNode) => node.shape.x + node.shape.width;
            const prevR = prev && right(prev);
            const nodeR = right(node);
            const nextR = next && right(next);

            const prevL = prev && prev.shape.x;
            const nodeL = node.shape.x;
            const nextL = next && next.shape.x;

            const trr = prevR !== undefined && prevR >= nodeR ? rad / 2 : rad
            const tlr = prevL !== undefined && prevL <= nodeL ? rad / 2 : rad
            const brr = nextR !== undefined && nextR >= nodeR ? rad / 2 : rad
            const blr = nextL !== undefined && nextL <= nodeL ? rad / 2 : rad

            return <div style={{
                position: 'absolute',
                left: node.shape.x * _scale - aura - border / 2,
                top: node.shape.y * _scale - aura - border / 2,
                width: node.shape.width * _scale + aura * 2 + border,
                height: node.shape.height * _scale + aura * 2 + border,
                background: color,
                //borderRadius: radius + aura + border / 2
                borderTopRightRadius: trr,
                borderTopLeftRadius: tlr,
                borderBottomRightRadius: brr,
                borderBottomLeftRadius: blr
            }}/>
        })}
    </div>
}

export const FancyExecutableNodeHighlight = ({id, port, passive}: {
    id: string,
    port?: PortPosition,
    passive?: boolean
}) => {
    let node = useTypedSelector(state => state.nodes.nodes[id]) as ExecutableNode;
    let zoom = useZoom();
    let ctrl = useKeyPressed('Control');

    let style = useStyle();
    let nodeStyle = useNodeStyle();
    let appearance = getNodeTypeFromPath(node.type)?.appearance || {type: 'flat'};

    let highlightColorBaseGhost = (appearance && appearance.type === 'solid') ? (nodeStyle.custom?.solid?.color ?? "#00cc00") : style.palette.highlight.main;

    let aura = getHighlightAura(passive ? 6 : 8, _scale, zoom); //passive ? 6 * _scale / zoom : 8 * _scale / zoom;
    let border = 2 * _scale;
    let borderRadius = 8 * _scale;

    if (!port) {
        return <>
            {ctrl ?
                <FancyStackNodesHighlight
                    id={id}
                    aura={aura}
                    border={border}
                    radius={borderRadius}
                    color={highlightColorBaseGhost}
                    opacity={style.palette.highlight.ghostAlpha}
                /> : <div style={{
                    position: 'absolute',
                    left: node.shape.x * _scale - aura - border / 2,
                    top: node.shape.y * _scale - aura - border / 2,
                    width: node.shape.width * _scale + aura * 2 + border,
                    height: node.shape.height * _scale + aura * 2 + border,
                    background: highlightColorBaseGhost,
                    borderRadius: borderRadius + aura + border / 2,
                    opacity: style.palette.highlight.ghostAlpha
                }}/>}
            <div style={{
                position: 'absolute',
                left: node.shape.x * _scale,
                top: node.shape.y * _scale,
                width: node.shape.width * _scale,
                height: node.shape.height * _scale
            }}>
                {node.input?.map((p, i) => <FancyPortGlow
                    skipOnWarning
                    autoColor
                    id={id}
                    direction={'in'}
                    index={i}
                />)}
                {node.output?.map((p, i) => <FancyPortGlow
                    skipOnWarning
                    autoColor id={id}
                    direction={'out'}
                    index={i}
                />)}
            </div>
        </>;
    } else {
        return <div style={{
            position: 'absolute',
            left: node.shape.x * _scale,
            top: node.shape.y * _scale,
            width: node.shape.width * _scale,
            height: node.shape.height * _scale
        }}>
            {passive ? <FancyPortGlow /*skipOnWarning*/ autoColor id={id} {...port}/> : <></>
                /*<FancyPortHighlight autoColor id={id} {...port} aura={aura}/>*/}
        </div>
    }
}

export const FancyStateNodeHighlight = ({id, passive}: {
    id: string,
    passive?: boolean
}) => {
    let node = useTypedSelector(state => state.nodes.nodes[id]) as StateNode;
    let zoom = useZoom();

    let style = useStyle();
    let highlightColorBaseGhost = style.palette.highlight.ghost;

    return <NodeStyleContext.Consumer>{(theme) => {
        let aura = getHighlightAura(passive ? 6 : 8, _scale, zoom); //passive ? 6 * _scale / zoom : 8 * _scale / zoom;
        let border = 2 * _scale;

        return <div style={{
            position: 'absolute',
            left: (node.shape.x - node.shape.radius) * _scale - aura - border / 2,
            top: (node.shape.y - node.shape.radius) * _scale - aura - border / 2,
            width: node.shape.radius * 2 * _scale + aura * 2 + border,
            height: node.shape.radius * 2 * _scale + aura * 2 + border,
            background: highlightColorBaseGhost,
            borderRadius: '50%'
        }}/>
    }}</NodeStyleContext.Consumer>;
}