import {useEffect, useState} from "react";

/*
export function useKeyPressed(target: string) {
    const [keyPressed, setKeyPressed] = useState<boolean>(false);

    const handleDown = (event: KeyboardEvent) => {
        if (event.key === target) setKeyPressed(true)
    };
    const handleUp = (event: KeyboardEvent) => {
        if (event.key === target) setKeyPressed(false)
    };

    useEffect(() => {
        window.addEventListener('keydown', handleDown);
        window.addEventListener('keyup', handleUp);

        return () => {
            window.removeEventListener('keydown', handleDown);
            window.removeEventListener('keyup', handleUp);
        };
    }, []);
    return keyPressed;
}
*/

export function useMousePosition(...types: ("mousemove" | "touchmove" | "drag")[]) {
    const [mousePosition, setMousePosition] = useState<{ x: number | null, y: number | null }>({x: null, y: null});

    if (types.length === 0) {
        types = ['mousemove']
    }

    useEffect(() => {
        const updateTouchPosition = (e: any) => {
            let x, y;
            if (e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel'){
                let touch = e.touches[0] || e.changedTouches[0];
                x = touch.pageX;
                y = touch.pageY;
            } else if (e.type == 'mousedown' || e.type == 'mouseup' || e.type == 'mousemove' || e.type == 'mouseover'|| e.type=='mouseout' || e.type=='mouseenter' || e.type=='mouseleave') {
                x = e.clientX;
                y = e.clientY;
            }
            setMousePosition({x, y});
        };

        types.forEach(type => window.addEventListener(type, updateTouchPosition));
        return () => {
            types.forEach(type => window.removeEventListener(type, updateTouchPosition));
        };
    }, []);

    return mousePosition;
}